import "./text-block.scss";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

export default class TextBlock extends React.Component {

    render () {

        const {Text} = fieldsArrayToKeyValueHash(this.props.fields);

        return (
            <div className="text-block">
                <div className="text-block__inner"
                    dangerouslySetInnerHTML={{__html: sanitise(Text)}} />
            </div>
        );

    }

}
