import "./recipe-and-filter-list.scss";
import React, {Component} from "react";
import {CtaComponent} from "../../../components/atoms/cta";
import FilterAccordian from "./filter-accordian";
import FilterButtons from "./filters";
import RecipeGrid from "./recipe-grid";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import filterRecipes from "./helpers/filter";

const makeTagFilterGroups = (fields) => {

    const {FilterAndSort} = fieldsArrayToKeyValueHash(fields);
    const {Filters} = fieldsArrayToKeyValueHash(FilterAndSort);

    const filterGroups = Filters.map((filter) => {

        const {TagProperty} = fieldsArrayToKeyValueHash(filter.fields);

        return TagProperty;

    });

    const tagGroups = {};

    filterGroups.forEach((filterGroup) => {

        tagGroups[filterGroup] = [];

    });

    return tagGroups;

};

const compareRecipeListingsByCardTitle = (propHashA, propHashB) => {

    const aProps = fieldsArrayToKeyValueHash(propHashA);
    const bProps = fieldsArrayToKeyValueHash(propHashB);
    const equal = 0,
        less = -1,
        more = 1;

    if (aProps.CardTitle < bProps.CardTitle) {

        return less;

    }

    if (aProps.CardTitle > bProps.CardTitle) {

        return more;

    }

    return equal;

};

const sortRecipes = (fields, selectedTags, sortDirection = "asc") => {

    const {RecipeListing} = fieldsArrayToKeyValueHash(fields);
    const filteredRecipes = filterRecipes(RecipeListing, selectedTags);

    let sortedRecipes = filteredRecipes.sort((aRecipes, bRecipes) => compareRecipeListingsByCardTitle(
        aRecipes.fields,
        bRecipes.fields
    ));

    if (sortDirection === "desc") {

        sortedRecipes = sortedRecipes.reverse();

    }

    return sortedRecipes;

};

export default class RecipeAndFilterList extends Component {

  state = {
      filteredRecipes: sortRecipes(this.props.fields, makeTagFilterGroups(this.props.fields)),
      selectedTags: makeTagFilterGroups(this.props.fields),
      showingFilters: false,
      sortDirection: "asc",
  };

  componentDidMount () {

      this.applyFiltersAndSorting(this.state.selectedTags);

  }

  applyFiltersAndSorting = (selectedTags) => {

      this.setState({
          filteredRecipes: sortRecipes(
              this.props.fields,
              selectedTags,
              this.state.sortDirection
          ),
      });

  };

  resetFilters = () => {

      const selectedTags = makeTagFilterGroups(this.props.fields);

      this.setState({selectedTags});
      this.applyFiltersAndSorting(selectedTags);
      this.toggleFilters();

  };

  render () {

      const {FilterAndSort, RecipeListing} = fieldsArrayToKeyValueHash(this.props.fields);

      const {
          // Apply filter button text
          SeeRecipesText,
          // Reset button text
          ShowAllText,
          Filters,
          FilterTitle,
      } = fieldsArrayToKeyValueHash(FilterAndSort);

      return (
          <section className={"recipe-and-filter-list"}>
              <FilterButtons
                  filterProperties={FilterAndSort}
                  total={this.state.filteredRecipes.length}
                  toggleFilters={this.toggleFilters}
                  sortDirection={this.state.sortDirection}
                  toggleSortDirection={this.toggleSortDirection}
                  showingFilters={this.state.showingFilters}
              />
              <nav
                  aria-labelledby="recipe-and-filter-list__header-button--filter"
                  aria-modal={`${this.state.showingFilters && "true"}`}
                  id="recipe-filters"
                  className={`recipe-filters ${this.state.showingFilters && "recipe-filters--expanded"}`}
              >
                  <div className="recipe-filters__header">
                      <div className="recipe-filters__header-inner">
                          <h3 className="recipe-filters__header-title">
                              {FilterTitle}
                          </h3>
                          <button
                              className="recipe-filters__header-close hamburger hamburger--spin is-active"
                              onClick={this.toggleFilters}
                              type="button"
                          >
                              <span className="hamburger-box">
                                  <span className="hamburger-inner" />
                                  <span className="recipe-filters__header-close-alt">
                                      {SeeRecipesText}
                                  </span>
                              </span>
                          </button>
                      </div>
                  </div>
                  <FilterAccordian
                      isExpanded={this.state.showingFilters}
                      filters={Filters}
                      toggleTag={this.toggleTag}
                      selectedTags={this.state.selectedTags}
                      recipes={RecipeListing}
                  />
                  <div className="recipe-filters__ctas">
                      <div className="recipe-filters__ctas-inner">
                          <CtaComponent
                              Caption={ShowAllText}
                              onClick={this.resetFilters}
                              version="tertiary"
                              varient="ghost"
                          />
                          <CtaComponent
                              Caption={`${SeeRecipesText} (${this.state.filteredRecipes.length})`}
                              onClick={this.toggleFilters}
                          />
                      </div>
                  </div>
              </nav>
              <RecipeGrid recipes={this.state.filteredRecipes} />
          </section>
      );

  }

  toggleTag = (accordionName, tag) => {

      const {selectedTags} = this.state;
      const accordionTags = selectedTags[accordionName];

      if (accordionTags.includes(tag)) {

          selectedTags[accordionName] = accordionTags.filter((selectedTag) => selectedTag !== tag);

      } else {

          accordionTags.push(tag);
          selectedTags[accordionName] = accordionTags;

      }

      this.setState({selectedTags});
      this.applyFiltersAndSorting(selectedTags);

  };

  toggleInert = (elements, isInert) => {

      elements.forEach((element) => {

          if (document.querySelector(element)) {

              if (isInert) {

                  document.querySelector(element).setAttribute("inert", isInert);

              } else {

                  // Have to manage this way as just setting to false has no effect and element remains inert
                  document.querySelector(element).removeAttribute("inert");

              }

          }

      });

  };

  toggleFilters = () => {

      this.setState({showingFilters: !this.state.showingFilters});
      this.toggleInert([
          ".navigation",
          ".hero",
          ".recipe-and-filter-list__header",
          ".recipe-list",
          ".page__footer",
      ], !this.state.showingFilters);

  };

  toggleSortDirection = () => {

      if (this.state.sortDirection === "desc") {

          this.setState({sortDirection: "asc"});

      } else {

          this.setState({sortDirection: "desc"});

      }

      this.applyFiltersAndSorting(this.state.selectedTags);

  }

}
