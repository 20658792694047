/* eslint-disable require-jsdoc */
import ConfigContext from "context/config";
import React from "react";

export default function withConfig (WrappedComponent) {

    return (props) => <ConfigContext.Consumer>
        {(config) => <WrappedComponent {...props} config={config} />}
    </ConfigContext.Consumer>;

}
