/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import $ from 'jquery'
import React, {Component} from "react";
import AppError from "../../organisms/app-error";
import sanitise from "global/utils/sanitise";
import buildPage from "../page-builder";
import heroImage from 'global/images/de_de_comp_game/dein-spice-header.jpg'
import kellenkunstler from 'global/images/de_de_comp_game/kellenkunstler.png'
import berlinBallroomCommunity from 'global/images/de_de_comp_game/berlin-ballroom-community.jpg'
import berlinBoardGamers from 'global/images/de_de_comp_game/berlin-board-gamers.jpg'
import pingPongBerlin from 'global/images/de_de_comp_game/ping-pong-berlin.jpg'
import ballroomGIF from 'global/images/de_de_comp_game/ballroom.gif'
import watermark from 'global/images/de_de_comp_game/cm-watermark.png'
import redBackground from 'global/images/backgrounds/german-red-background.jpg'
import spiceOn from 'global/images/de_de_comp_game/spice-on.png'
import ballroomVideo from 'global/images/de_de_comp_game/ballroom-video.mp4'
import boardgamerVideo from 'global/images/de_de_comp_game/boardgamer-video.mp4'
import pingpongVideo from 'global/images/de_de_comp_game/pingpong-video.mp4'
import playButton from 'global/images/de_de_comp_game/playButton.png'
export default class PageContainer extends Component {

    state = {
        "isLoaded": false,
        "setRoutes": false,
        "video":""
    };

    constructor (props) {

        super(props);
        this.fetchData();

    }

    getPageName () {

        const path = this.props.location.pathname
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'>/g, "&#x27;");

        if (path.endsWith("/")) {

            return path;

        }

        return `${path}/`;

    }

    fetchData () {

        const pageName = this.getPageName();

        if (!this.props.data[pageName]) {

            // No! Lots wrong here:
            // - whatabout fetching data?
            // - Transition should be blocked before
            //   We have the data to avoid rendering noop
            this.props.fetchData(
                {name: pageName},
                false
            );

        }

    }

    openVideoModal (partner) {
        $("#buyNowModal").addClass("active");
        state.video = partner
        console.log(state.video)
    }

    componentDidUpdate (prevProps) {

        const pageName = this.getPageName();
        const newLocation = this.props.location.pathname;
        const {data} = this.props;

        if (prevProps.location.pathname !== newLocation && !data[pageName]) {

            this.props.fetchData(
                {name: newLocation},
                false
            );

        }

    }
      
    render () {

        const {data} = this.props;

        const pageName = this.getPageName();
        let video = ballroomVideo


        if (!data[pageName] || data[pageName].isFetching) {

            // In future, avoid this!
            return null;

        }

        const page = buildPage(data, pageName);

        // IF the API is unreachable
        // OR the page response is not as expected
        // BuildPage will return null
        // Without this the App will error server side and bring down the node server
        if (page === null) {

            return (
                <div className="page">
                    <AppError
                        message="This site is currently unavailable - Come back soon."
                    />
                </div>
            );


        }
     
        const playVideo = (e) =>{
            console.log(e.target.parentNode)
            let video = e.target.parentNode.querySelector('.video-thumbnail')
            let poster = e.target.parentNode.querySelector('.video-poster')
            let play = e.target.parentNode.querySelector('.play-button')
            console.log(video.paused)
            if(video.paused){
                video.play()
                poster.classList.add("active")
                play.classList.add("active")
            } 
           
        }
        page.headerComponents[0].props.fields[0].value = "Was Ist Dein Spice? | Captain Morgan";
        page.headerComponents[0].props.fields[4].value = "200";
         
        return (
            <div className="page-custom">
                 {page.headerComponents}
                <header className="hero">
              
                <div className="hero__background" style={{backgroundImage: `url(${heroImage})`}} />
                <div className="hero__content">
                    <h1 className="hero__title gold_font">WAS IST DEIN SPICE?</h1>
                            <div
                                className="hero__summary"
                                dangerouslySetInnerHTML={{__html: sanitise("Berlin hat jede Menge wunderbar weirde Vereine. </br> Captain Morgan und Canel Ataman haben </br> drei davon dazu eingeladen, einen Tag lang einen </br> Späti zu ihrem Clubhaus zu machen.")}}
                            />
                    </div>
                </header>
                <img src={kellenkunstler} className="splash" aria-hidden="true" />

                <main className="page__content" id="main" role="main">
                   <section style={{backgroundImage: `url(${redBackground})`}} className="video-section" >
                        <div className="video-row">
                            <a className="partner">
                                <h2>Berlin <br/> Ballroom <br/> Community</h2>
                                    <div className="video-wrapper" onClick={(e)=>{playVideo(e)}}>
                                    <video className="video video-thumbnail"   controls controlsList="nodownload" onClick="this.paused? this.play:this.pause">
                                        <source src={ballroomVideo} type="video/mp4"/>
                                    </video>
                                    <div className="video-poster" style={{backgroundImage:`url(${berlinBallroomCommunity})` }}>
                                        
                                    </div>
                                    <img className="play-button" src={playButton} role="button" alt="Berlin Ballroom Community Video Play Button" />
                                    </div>
                                                            
                            </a>
                             <a  className="partner">
                                <h2>Berlin <br/> Board <br/> Gamers</h2>
                                    <div className="video-wrapper" onClick={(e)=>playVideo(e)}>
                                    <video className="video-thumbnail"   src={boardgamerVideo} controls controlsList="nodownload" onClick="this.paused? this.play:this.pause"/>
                                    <div className="video-poster" style={{backgroundImage:`url(${berlinBoardGamers})` }}>
                                    </div>
                                    <img className="play-button" src={playButton} role="button" alt="Berlin Board Gamers Video Play Button" />
                                    </div>
                            </a>
                             <a   className="partner">
                                <h2>Ping <br/> Pong <br/> Berlin</h2>
                                 <div className="video-wrapper" onClick={(e)=>playVideo(e)} >
                                    <video className="video-thumbnail"   src={pingpongVideo}  controls controlsList="nodownload" onClick="this.paused? this.play:this.pause"/>
                                    <div className="video-poster" style={{backgroundImage:`url(${pingPongBerlin})` }}>
                                         
                                    </div>
                                     <img className="play-button" src={playButton} role="button" alt="Ping Pong Berlin Video Play Button" />
                                    </div>
                            </a>
                            
                        </div>
                        <img src={watermark} className="watermark" aria-hidden="true" />
                   </section>
                  
                   <section >
                        <div className="gift-row">
                            <div className="left">
                                <img className="gift-gif" src={ballroomGIF} alt="Berlin Ballroom Community animation" />
                            </div>
                            <div className="right">
                                <p>Es ist an der Zeit ein paar wunderbar weirde Vereine zu feiern, indem wir ein paar ausgewählte Spätis eine Nacht lang zu ihren Clubhäusern machen. Vor Ort warten kleine Überraschungen und exklusiver Merch auf unsere Vereine, um den Abend für sie unvergesslich zu machen. Unser Host Canel Ataman nimmt euch mit auf die exklusiven Clubnächte der Vereine und zeigt euch den Spice, der sie ausmacht. Freut euch auf eine außergewöhnliche Erfahrung – exklusiv auf dem Captain Morgan Instagram Channel!</p>
                                <img src={spiceOn} className="spice-on" alt="Spice On Logo Image" />
                            </div>
                            
                        </div>
                   </section>
                    {/* <aside class="buy-banner">
                        <div class="buy-banner__inner">
                            <h2 class="buy-banner__heading"> SPICE IST DAS WAS DICH AUS MACHT</h2>
                            <div class="buy-banner__actions">
                                <a href="https://brands-de.shortlyst.com/captainmorgan/8001?&amp;utm_source=Youtube&amp;utm_medium=Social&amp;utm_campaign=Captain_Morgan_Youtube_September_2019_DE" rel="noopener noreferrer" target="_blank" to="https://brands-de.shortlyst.com/captainmorgan/8001?&amp;utm_source=Youtube&amp;utm_medium=Social&amp;utm_campaign=Captain_Morgan_Youtube_September_2019_DE" class="cta cta-primary" config="[object Object]">
                                    <span class="cta__content">GET YOUR SPICE!</span>
                                </a>
                            </div>
                        </div>
                    </aside> */}
                </main>

              <footer className="page__footer" role="contentinfo">
                <aside className="follow-us">
                    <div className="follow-us__inner">
                        <h2 className="follow-us__title">Folge Captain Morgan</h2>
                        <div className="follow-us__strapline">Erfahre als erstes von Events, Aktionen und Wettbewerben</div>
                        <ul className="follow-us__options">
                            <li className="follow-us__option">
                            <a className="follow-us__option-link" href="https://www.instagram.com/captainmorgande/?hl=en" target="_blank" rel="noopener noreferrer">
                                <div className="styled-icon">
                                    <div className="styled-icon__icon">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-inline--fa fa-instagram fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <span className="follow-us__option-link-alt">Instagram</span>
                            </a>
                            </li>
                        </ul>
                    </div>
                </aside>
                
                </footer>
            </div>
        );

    }

}
