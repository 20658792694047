import {BlockingNavLink} from "global/utils/blocking-link";
import React from "react";

export default ({fields, LogoUrl}) => {

    const {Alt, Url} = fields.reduce((map, field) => {

        map[field.title] = field.value;
       
        return map;

    }, {});
    let altText = ''

    if(!Alt) {
      altText = 'Captain Morgan Logo'
    }else{
      altText = Alt
    }
    return (
        <BlockingNavLink
            className="navigation__brand-link"
            to={LogoUrl}
            title={"Captain Morgan Home Page"}
        >
            <img
                src={Url}
                alt={altText}
                className="navigation__brand-logo"
            />
        </BlockingNavLink>
    );

};
