import mapPin from 'global/images/maps/map_pin.svg'
import pinShip from 'global/images/ftc2024/pin_ship.png'
import pinEpic from 'global/images/ftc2024/pin_epic.png'
import btnBack from 'global/images/ftc2024/btn__backtomap.png'
import pinCaptainShip from 'global/images/ftc2024/pin_captain_ship.png'
import btnPlay from 'global/images/ftc2024/btn__play.png'
import btnRsvp from 'global/images/ftc2024/btn__rsvpnow.png'

import imgDefault from 'global/images/ftc2024/image-default.jpg'
import imgLocation1 from 'global/images/ftc2024/image-location-1.png'
import imgLocation2 from 'global/images/ftc2024/image-location-2.png'

import shipStopSample from 'global/images/ftc2024/ship_stop_sample.png'
import $ from 'jquery'
import * as moment from 'moment'

const token =
  'pk.eyJ1Ijoid3Zpc29rYXkiLCJhIjoiY2p2ZjlyYW9lMnJ0ajN5bzV3emt5NDJhNiJ9.toVpPHFPwQCCF5Q9T6I8Lw'

let map

let markers = []

let filteredLocations = []

let locationsOutput = ''

// Delete markers
function clearMarkers() {
  markers.forEach((marker) => {
    marker.remove()
  })
  markers = []
}

function getLocationImage(imageName) {
  let sendImage = ''

  switch (imageName) {
    case 'ship_stop_sample':
      sendImage = shipStopSample
      break;
    case 'image-location-1':
      sendImage = imgLocation1
      break;
    case 'image-location-2':
      sendImage = imgLocation2
      break;
    default:
      sendImage = ''
      break
  }

  return sendImage
}

function lockElements() {
  const htmlElem = document.getElementsByTagName('html')[0]
  const bodyElem = document.getElementsByTagName('body')[0]
  const navElem = document.getElementById('mainNav')

  htmlElem.classList.add('locked')
  bodyElem.classList.add('locked')
  navElem.classList.add('locked')
}

function unLockElements() {
  const htmlElem = document.getElementsByTagName('html')[0]
  const bodyElem = document.getElementsByTagName('body')[0]
  const navElem = document.getElementById('mainNav')

  htmlElem.classList.remove('locked')
  bodyElem.classList.remove('locked')
  navElem.classList.remove('locked')
}

function createLocationsOutput() {
  locationsOutput += '<div class="locevent__wrap">'

  filteredLocations.forEach((loc) => {
    locationsOutput += '<div class="locevent__item">'

    locationsOutput += `<div class="locevent__date">${moment(loc.datestart).format('MMMM D')}</div>`
    locationsOutput += `<div class="locevent__place">${loc.locaddress}, ${loc.locstate}</div>`
    //LocationsOutput += `<div class="locevent__name">${loc.locname}</div>`

    locationsOutput += '</div>'
  })

  locationsOutput += '</div>'
}

export const setLocations = (loclist) => {
  if (typeof loclist === 'object' && loclist.length > 0) {
    // Set the filtered list
    filteredLocations = loclist
    createLocationsOutput()
  }
}

export const init = () => {
  const windowWidth = document.documentElement.clientWidth
  const center = windowWidth < 768 ? [-70.5797656, 37.3125119] : [-96, 37.8]

  mapboxgl.accessToken = token
  map = new mapboxgl.Map({
    container: 'map', // Container ID
    style: 'mapbox://styles/wvisokay/clz2wx9pd00m801pob1cpdixv',
    center,
    zoom: 1,
    maxZoom: 7,
    minZoom: 1,
    pitch: 10,
    maxBounds: [
      [-126.791110603, 24.91619],
      [-65.96466, 49.3577635769]
    ],
    attributionControl: false
    // FitBoundsOptions: { padding: 100}
    // CooperativeGestures: true,
  })
  if (map) {
    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()
    map.fitBounds(
      [
        [-126.791110603, 24.91619],
        [-65.96466, 49.3577635769]
      ],
      { padding: { top: 0, bottom: 150, left: 150, right: 150 } }
    )
  }

  if (windowWidth < 768) {
    flyToMarker(37.3125119, -70.5797656, 4)
  }
  console.log('INIT MAP')
}

export const flyToMarker = (lat, lon, zoom) => {
  if (lat && lon) {
    const zoomLevel = zoom ? zoom : 4

    map.flyTo({
      zoom: zoomLevel,
      speed: 2,
      pitch: 10,
      center: [Number(lon), Number(lat)]
    })
  }
}

function createShipMarker(lat, lon, popupcontent, callback, that) {
  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [75, 75]
      },
      geometry: {
        type: 'Point',
        coordinates: [-70.5797656, 37.3125119]
      }
    }
  }
  // Add markers to map

  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${pinCaptainShip}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  popupcontent += '<div class="locCta">'
  popupcontent +=
    '<button class="btn__locCta" id="buttonCta" type="button" aria-label="Play the game">'
  popupcontent += `<img src="${btnPlay}" aria-hidden="true" />`
  popupcontent += '</button>'
  popupcontent += '</div>'

  const popup = new mapboxgl.Popup().setHTML(popupcontent)

  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName('event-captain')
    .addClassName('event__pin')
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)

  markers.push(marker)

  // Sets the slide in animation for the popup
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById('buttonCta')

    buttonCta.addEventListener('click', () => {
      callback(true, that, 'bottle')
      $('.mapboxgl-popup-close-button').trigger('click')
    })
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup')[0]

      popup.addClassName('slideInOut')

      if (popupDiv) {
        popupDiv.scrollTop = 0
      }
      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')
        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')

        flyToMarker(
          geojson.features.geometry.coordinates[1],
          geojson.features.geometry.coordinates[0],
          4
        )
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })

  const routeLayer = {
    id: 'route',
    type: 'line',
    paint: {
      'line-width': 3,
      'line-color': '#fff',
      'line-dasharray': [2, 2]
    },
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            [-70.5797656, 37.3125119],
            [lon, lat]
          ]
        }
      }
    }
  }

  map.addLayer(routeLayer)
}

export const createMarkers = (loc, numbers, callback, that) => {
  const iconType = loc.loctype || 'ship'

  let useMapPin = pinShip

  if (loc.loctype === 'epic') {
    useMapPin = pinEpic
  }

  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [32, 32]
      },
      geometry: {
        type: 'Point',
        coordinates: [Number(loc.loclon), Number(loc.loclat)]
      }
    }
  }

  // Variables for marker popups
  const locName = loc.locname ? loc.locname : ''
  const locAddress = loc.locaddress ? loc.locaddress : ''
  const useImage = loc.locimage && loc.locimage !== '' ? getLocationImage(loc.locimage) : ''
  const locDate = loc.datestart ? loc.datestart : ''
  const useDate = locDate !== '' ? moment(locDate).format('MMMM D') : ''
  const useLink = loc.loclink ? loc.loclink : ''

  const useCopy = loc.loccopy ? loc.loccopy : ''

  const locState = loc.locstate ? loc.locstate : ''

  let dateLine = ''

  let locTitle = loc.locname ? loc.locname : locName


  if(iconType === 'epic') {
    dateLine = locAddress + ', ' + locState + ' | ' + useDate
  } else {
    locTitle = `${locAddress}, ${locState}`
    dateLine = useDate
  }

  // Creates inner html for marker popup
  let infoWindowTag = ''

  infoWindowTag += `<div class="backtomap"><img src=${btnBack} alt="Back to map" aria-label="Back to map" /><span class="sr-only">< BACK TO MAP</span></div>`
  infoWindowTag += `<div id="location_${numbers}" class="locationItem" style="cursor: pointer;">`
  infoWindowTag += `<div class="locationAddress">${locTitle}</div>`
  infoWindowTag += `<div class="locationDate">${dateLine}</div>`

  infoWindowTag += '<div class="locDescription">'
  if (useCopy === '') {
    infoWindowTag += `Legendary adventure awaits when we drop anchor for the ${locTitle} game. <br />`
    infoWindowTag += 'Come out and join the crew.'
  } else {
    infoWindowTag += useCopy
  }

  infoWindowTag += '</div>'

  if (iconType === 'epic') {
    createShipMarker(
      geojson.features.geometry.coordinates[1],
      geojson.features.geometry.coordinates[0],
      infoWindowTag,
      callback,
      that
    )
  }

  if (iconType === 'epic') {
    infoWindowTag += '<div class="locCta">'
    infoWindowTag += `<button class="btn__locCta" id="buttonCta_${loc.id}" type="button" aria-label="Play the game">`
    infoWindowTag += `<img src="${btnPlay}" aria-hidden="true" />`
    infoWindowTag += '</button>'
    infoWindowTag += '</div>'
  } else {
    // if(useLink !== '') {
      infoWindowTag += '<div class="locLink">'
      // infoWindowTag += `<a class="btn btn--reset" href="${useLink}" target="_blank" rel="noreferrer" title="RSVP NOW">`
      infoWindowTag += `<a class="btn btn--reset" href="/followthecaptain/rsvp" target="_blank" rel="noreferrer" title="RSVP NOW">`
      infoWindowTag += `<img src=${btnRsvp} aria-label="RSVP now" />`
      infoWindowTag += '<span class="sr-only">RSVP NOW</span></a>'
      infoWindowTag += '</div>'
    // }

    if (useImage !== '') {
      infoWindowTag += '<div class="locSubCopy padbot">Come out and join the crew</div>'
      infoWindowTag += `<div class="image__wrap"><img src=${useImage} alt="Come out and join the crew" /></div>`
    }
    infoWindowTag += '<div class="locSubCopy">Everywhere we\'re headed</div>'
    infoWindowTag += locationsOutput
  }
  infoWindowTag += '</div>'

  // Add markers to map
  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${useMapPin}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  const popup = new mapboxgl.Popup().setHTML(infoWindowTag)

  // Push each marker into a global array
  // So that they can can be deleted later on
  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName(`event-${iconType}`)
    .addClassName('event__pin')
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)

  // This prevents the popup from showing on a disabled pin
  marker.getElement().addEventListener(
    'click',
    (e) => {
      const currentPin = e.target

      if (currentPin.classList.contains('event__pin--disabled')) {
        e.stopPropagation()
        // E.preventDefault()
      }
    },
    false
  )
  markers.push(marker)

  // Sets the slide in animation for the popup
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById(`buttonCta_${loc.id}`)

    lockElements()

    $('.mapboxgl-popup-close-button,.mapboxgl-marker.mapboxgl-marker-anchor-center.event__pin, .mapboxgl-canvas').on('click', function () {
      $('.mapboxgl-canvas-container .event__pin').each( function () {
        if($(this).attr('aria-expanded') === true || $(this).attr('aria-expanded') === 'true') {
          $(this).attr('aria-expanded', 'false')
        }
      })
    })

    if (buttonCta) {
      buttonCta.addEventListener('click', () => {
        callback(true, that, 'bottle')
        $('.mapboxgl-popup-close-button').trigger('click')
      })
    }
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup')[0]

      popup.addClassName('slideInOut')
      if (popupDiv) {
        popupDiv.scrollTop = 0
      }

      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')

        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')       

        if (loc.loctype === 'epic') $('.event-captain').addClass('flag-triggered')

        flyToMarker(geojson.features.geometry.coordinates[1], geojson.features.geometry.coordinates[0], loc.loctype === 'epic' ? 4 : 6)
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')

    unLockElements()

    if (loc.loctype === 'epic') $('.event-captain').removeClass('flag-triggered')

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })
}

