/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import "./modal-custom.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import buildPage from "../page-builder";
import {load} from "recaptcha-v3";

import NavCustom from "../../organisms/nav-custom";
import ModalNfl from "../../molecules/modal-nfl";
import CocktailTiles from "../../organisms/cocktail-tiles";
import FormSimple from "../../organisms/form-simple";

const cn = require('classnames')

import heroImage from 'global/images/comp2023/black-background.jpg'
import logoDeco from 'global/images/maps/captain_logo_large.png'
import redbg from 'global/images/comp2023/red-background.jpg'
import closedBg1 from 'global/images/comp2023/closed_bg.jpg'
import animatedTagline from 'global/images/comp2023/animated_tagline.gif'
import taglineClosed from 'global/images/comp2023/tagline_closed.png'
import sponsor from 'global/images/comp2023/nfl-sponsor.png'
import eventImage2 from 'global/images/comp2023/events_5_1-2.jpg'
import eventImage1 from 'global/images/comp2023/events_5_2.jpg'
import eventImage3 from 'global/images/comp2023/events_8_1.jpg'
import eventImage4 from 'global/images/comp2023/events_5_3.jpg'
import eventImage5 from 'global/images/comp2023/events_9_1.jpg'
import eventImage6 from 'global/images/comp2023/events_10_1.jpg'
import footerFBLogo from 'global/images/comp2023/facebook.png'
import footerIGLogo from 'global/images/comp2023/IG.png'
import footerTWLogo from 'global/images/comp2023/TW.png'
import footerYTLogo from 'global/images/comp2023/YT.png'

export default class PageContainer extends Component {

  state = {
    "isLoaded": false,
    "setRoutes": false,
    "modalOpen": false,
    "modalType": '',
    "successUrl": '/api/nflcomp/claim',
    "modalData": {},
    "recaptcha":"",
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();
    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  showModal (type, data) {
    const modalData = data ? data : {}
    if(type === 'correct') {
      $('#claimThanks').addClass('hidden')
      $('#claimForm').removeClass('hidden')
      $('#claimLose').addClass('hidden')
      $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
      $('#errorMessage').html('')
      // window.grecaptcha.reset();
      // eslint-disable-next-line func-names
      $(".error").each(function () {
          // eslint-disable-next-line babel/no-invalid-this
          $(this).removeClass("error");
      });
      $('.claimInput').each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        if($(this).hasClass('cliamInput--checkbox')) {
          $(this).prop('checked', false)
        }else{
          $(this).val('');
        }
      });
    }
    this.setState({
      "modalOpen": true,
      "modalType": type,
      "modalData": modalData
    })
  }

  closeModal () {
    this.setState({
      modalOpen: false
    })
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {

      this.props.fetchData(
          {name: newLocation},
          false
      );

    }
  }


  componentDidMount () {
    $(function () {
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }
    // this.captchaLoad()
     
    })
  }

   render () {
    const {data} = this.props;
    const pageName = this.getPageName();   
   
    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }
    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          url: '/en-us',
        },
        {
          title: 'Sign Up',
          url: '#signup',
        },
        {
          title: 'Events',
          url: '#events',
        },
        {
          title: 'Cocktails',
          url: '#cocktails',
        },
      ]
    }

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      centerPadding: "20px",
      centerMode: true,
      className: "center",
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: true,
            className: "center",
            centerPadding: "40px"
          }
        }
      ]
    };

    return (

      <div className="page comp23" style={{backgroundImage: `url(${heroImage})`}}>
        <Helmet>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content="Follow the Captain | Captain Morgan" />
          <meta property="og:description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
          <title>Follow the Captain | Captain Morgan</title>

          <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js"></script>
        </Helmet>

        <div className={cn(this.state.modalOpen === true ? 'modalcustom open' : 'modalcustom')} id="modalcustom">
            <div className="modalcustom__container">
              <div className={cn("modalcustom__wrap", this.state.modalType === 'incorrect' ? 'modalcustom__wrap--red' : '', `modalcustom__wrap--${this.state.modalType}`)} id="modalContent">
                <button className="btn btn--close" aria-label="close modal" onClick={() => this.closeModal()}>
                  <span className={cn("line line--1", this.state.modalType === 'incorrect' ? 'line--white' : '')}></span>
                  <span className={cn("line line--2", this.state.modalType === 'incorrect' ? 'line--white' : '')}></span>
                </button>
                <ModalNfl type={this.state.modalType} data={this.state.modalData} closemodal={() => this.closeModal()} url={this.state.successUrl} />
              </div>
            </div>
        </div>

        <NavCustom navdata={navData} isNFL={true}/>

        <main className="page__content" id="main" role="main">
          {/*<section className="comp2__section hero" id="hero" >
            <div className="tagline__wrap">
              <div className="tagline__left">
                <img src={animatedTagline} className="hero__tagline tagline__image hidden" alt="Captain Morgan" aria-hidden="true" />
                <img src={animatedTagline} className="hero__tagline tagline__image" alt="Captain Morgan" aria-hidden="true" />
              </div>
              <div className="tagline__right">
                <img src={taglineClosed} className="" alt="Come back soon for our next adventure. Thanks for a great season." aria-hidden="true" />
              </div>
            </div>
          </section>*/}
          <FormSimple listid='RAiVzr' entrySource='followthecaptain_post_superbowl' />
          <section className="comp2__section video">
            <div className="video__container container container--1600">
              <div className="video__wrap flex">
                {/*<iframe width="100%" height="auto" src="https://www.youtube.com/embed/QXoqZAjAJe8?si=VpQu-xAtfoGUyWeK" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>*/}
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/908699285?h=f4d9df835b" frameBorder="0" title="Vimeo video player" width="100%" height="auto" allowFullScreen></iframe>
              </div>
            </div>
          </section>

          <section className="comp2__section events">
            <div className="container container--1440 container--sevenpad">
              <div className="events__wrap flex">
                <h2 className="events__title gold__font">
                  Have you seen the captain?
                </h2>
                <div className="events__copy">
                  Captain Morgan has been throwing down events all season.<br />
                  Check out some of the highlights.
                </div>
              </div>
            </div>
          </section>

          {/*<section className="comp2__section events">
            <div className="container container--1440 container--sevenpad">
              <div className="events__wrap flex">
                <h2 className="events__title gold__font">
                  See you in August<br />
                  For our next adventure.
                </h2>
                <div className="events__copy">
                  The Captain will be back for NFL Season 2024/2025.
                </div>
                <div className="hero__sponsor-wrapper">
                  <img src={sponsor} className="hero__sponsor" alt="Captain Morgan NFL Logo" />
                </div>
              </div>
            </div>
          </section>*/}

          <section className="comp2__section events eventsimages" id="events">
            <div className="events__content content">
              <div className="events__title events__title--upcoming">
                PREVIOUS EVENTS
              </div>
              <div className="events__items">
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage2} alt="The ultimate away game" />
                    </div>
                    <div className="eitem__title">
                      <span>The ultimate away game</span>
                      <button className="btn btn--textwhite btn--bgtransparent" onClick={() => this.showModal('gallery', {type: 'awaygame'})}>
                        VIEW HIGHLIGHTS
                      </button>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage1} alt="The captain's section" />
                    </div>
                    <div className="eitem__title">
                      <span>The captain's section - Arizona</span>
                      <button className="btn btn--textwhite btn--bgtransparent" onClick={() => this.showModal('gallery', {type: 'capsection'})}>
                        VIEW HIGHLIGHTS
                      </button>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage3} alt="Vic's House Party Highlights" />
                    </div>
                    <div className="eitem__title">
                      <span>Vic’s House Party</span>
                      <button className="btn btn--textwhite btn--bgtransparent" onClick={() => this.showModal('gallery', {type: 'houseparty'})}>
                        VIEW HIGHLIGHTS
                      </button>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage4} alt="The Captain's Section - Sofi" />
                    </div>
                    <div className="eitem__title">
                      <span>The captain's section - Los Angeles</span>
                      <button className="btn btn--textwhite btn--bgtransparent" onClick={() => this.showModal('gallery', {type: 'sofi'})}>
                        VIEW HIGHLIGHTS
                      </button>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage5} alt="Battle of the Captains Hightlights" />
                    </div>
                    <div className="eitem__title">
                      <span>Battle of the Captains</span>
                      <button className="btn btn--textwhite btn--bgtransparent" onClick={() => this.showModal('gallery', {type: 'battle'})}>
                        VIEW HIGHLIGHTS
                      </button>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage6} alt="SI THE PARTY – SUPER BOWL LVIII Highlights" />
                    </div>
                    <div className="eitem__title">
                      <span>SI THE PARTY – SUPER BOWL LVIII</span>
                      <button className="btn btn--textwhite btn--bgtransparent" onClick={() => this.showModal('gallery', {type: 'siparty'})}>
                        VIEW HIGHLIGHTS
                      </button>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="comp2__section rsvp" style={{backgroundImage: `url(${redbg})`}} id="cocktails">
            <div className="container container--1600 container--sevenpad">
              <div className="rsvp__wrap flex">
                <h2 className="gold__font small text--center">Here are some Captain Morgan cocktails for you to enjoy with family and friends.
                </h2>
                <div className="rsvp__copy rsvp__copy text--white text--center">
                  Please Drink Responsibly.
                </div>
                <CocktailTiles />
              </div>
            </div>
          </section>
          <section className="comp2__section footer">
            <div className="footer__reg reg">
              <label className="reg__label" htmlFor="rsvpInfo">
                Keep me informed:
              </label>
              <a href="/en-us/newsletter/?source=followthecaptain" className="btn btn--decorated btn--bgwhite btn--textred" id="rsvpInfo">
                Sign up
              </a>
            </div>
            <div className="footer__logo">
              <img src={animatedTagline} alt="Captain Morgan Logo" />
            </div>
           
            <footer className="page__footer" role="contentinfo">
             <aside className="follow-us">
              <div className="follow-us__inner">
                  <h2 className="follow-us__title">Follow Captain Morgan</h2>
                  <div className="follow-us__strapline">Be the first to know about events, promotions and competitions</div>
                  <ul className="follow-us__options">
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.facebook.com/CaptainMorganUSA/?brand_redir=138940200780" target="_blank" rel="noopener noreferrer">
                             <div className="styled-icon">
                            <img src={footerFBLogo} aria-hidden />
                            </div>
                          <span className="follow-us__option-link-alt">Facebook</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.instagram.com/captainmorganusa/" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">                         
                              <img src={footerIGLogo} aria-hidden/>
                          </div>
                          <span className="follow-us__option-link-alt">Instagram</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://twitter.com/CaptainMorganUS" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">
                              
                                <img src={footerTWLogo} aria-hidden/>
                              
                          </div>
                          <span className="follow-us__option-link-alt">Twitter</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.youtube.com/user/CaptainMorgan" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">
                                <img src={footerYTLogo} aria-hidden/>
                          </div>
                          <span className="follow-us__option-link-alt">YouTube</span>
                        </a>
                    </li>
                    
                  </ul>
              </div>
            </aside>
              
               <div className="footer__disclaimer">
               <div className="footer__rules">
                        <a  href="/captain-morgan-nfl-rules.pdf">Official Rules</a>
                    </div>
                <div className="copy">
                  <strong>
                    CAPTAIN MORGAN ORIGINAL SPICED RUM. Caribbean Rum Made with Vanilla, Spice, and Other Natural Flavors. 35% Alc./Vol. Captain Morgan Rum Co., New York, NY. Please Drink Responsibly. Do not forward to anyone under the age of 21.
                  </strong>
                </div>
              </div>
            </footer>
          </section>
        </main>        
      </div>
    );
  }
}
