import "./social-sharing.scss";
import {
    EmailShareButton,
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import React, {Component} from "react";
import StyledIcon from "../../../components/atoms/styled-icon";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const getComponent = (network) => {

    const url = global.window
        ? global.window.location.href
        : "";

    const registerAnalyticsEvent = (channel, socialUrl) => new Promise((resolve, reject) => {

        if (global.window) {

            global.window.dataLayer = global.window.dataLayer || [];
            global.window.dataLayer.push({
                "event": "socialEvent",
                "social Action": "click",
                "socialNetwork": channel,
                socialUrl,
            });

        }

        resolve("registered");

    }).then();

    switch (network) {

    case "Facebook":
        return <FacebookShareButton
            url={url}
            className="social-sharing__share-button"
            beforeOnClick={() => registerAnalyticsEvent("facebook", url)}
        >
            <StyledIcon icon="facebook-f"/>
            <span className="social-sharing__share-button-alt">{network}</span>
        </FacebookShareButton>;

    case "Twitter":
        return <TwitterShareButton
            url={url}
            className="social-sharing__share-button"
            beforeOnClick={() => registerAnalyticsEvent("twitter", url)}
        >
            <StyledIcon icon="twitter"/>
            <span className="social-sharing__share-button-alt">{network}</span>
        </TwitterShareButton>;

    case "Pinterest":
        return <PinterestShareButton
            url={url}
            className="social-sharing__share-button"
            beforeOnClick={() => registerAnalyticsEvent("pinterest", url)}
        >
            <StyledIcon icon="pinterest-p"/>
            <span className="social-sharing__share-button-alt">{network}</span>
        </PinterestShareButton>;

    case "Email":
        return <EmailShareButton
            url={url}
            className="social-sharing__share-button"
            beforeOnClick={() => registerAnalyticsEvent("email", url)}
        >
            <StyledIcon icon="envelope"/>
            <span className="social-sharing__share-button-alt">{network}</span>
        </EmailShareButton>;

    case "Whatsapp":
        return <WhatsappShareButton
            url={url}
            className="social-sharing__share-button"
            beforeOnClick={() => registerAnalyticsEvent("whatsapp", url)}
        >
            <StyledIcon icon="whatsapp"/>
            <span className="social-sharing__share-button-alt">{network}</span>
        </WhatsappShareButton>;
    default:
        return null;

    }

};

export default class SocialSharing extends Component {

    render () {

        const {
            Title,
            Networks,
        } = fieldsArrayToKeyValueHash(this.props.fields);
        const useTitle = Title ? Title : "Share"
        return (
            <aside className="social-sharing">
                <div className="social-sharing__inner">
                    <h2 className="social-sharing__title">{useTitle}</h2>
                    <ul className="social-sharing__share">
                        {Networks.map((socialLink, index) => <li
                            className="social-sharing__share-item"
                            key={`social-${index}`}
                        >
                            {getComponent(socialLink.value)}
                        </li>)}
                    </ul>
                </div>
            </aside>
        );

    }

}
