/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import buildPage from "../page-builder";
import {load} from "recaptcha-v3";

import NavCustom from "../../organisms/nav-custom";
import MapItems from "../../organisms/map-items";

import * as maps from 'global/utils/map'
const cn = require('classnames')

import heroImage from 'global/images/comp2023/black-background.jpg'
import logoDeco from 'global/images/maps/captain_logo_large.png'
import animatedTagline from 'global/images/comp2023/animated_tagline.gif'

import footerLogo from 'global/images/comp2023/footer_logo.png'
import footerFBLogo from 'global/images/comp2023/facebook.png'
import footerIGLogo from 'global/images/comp2023/IG.png'
import footerTWLogo from 'global/images/comp2023/TW.png'
import footerYTLogo from 'global/images/comp2023/YT.png'
import locationPin from 'global/images/comp2023/pizza_pin.png'

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": []
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  async getLocations () {    
    try {
      const json = JSON.stringify({data: {}});
      const res = await fetch("/api/map/find", {
          body: json,
          headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",

          },
          method: "POST",
      // eslint-disable-next-line no-shadow
      }).then((res) => res.json());
      console.log("GET LOCATIONS > ", res)

      if (res && res.success && res.success === true) {
        console.log('GET LOCATIONS', res.data)
        if(res.data && res.data.length > 0) {
          this.setState({
            "locationData": res.data
          })
          this.state.locationData.forEach( function (loc, index) {
            maps.createMarkers(loc, index)
          })
        }
      }else{
        console.log('GET LOCATIONS ERROR 1')
      }
    } catch (error) {
      console.log("GET LOCATIONS ERROR ERROR ANS", error);
    }
  }

  waitForElem () {
    if(typeof mapboxgl !== "undefined"){
      maps.init()
    }else{
      setTimeout( () => { this.waitForElem(), 1500})
    }
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {

        this.props.fetchData(
            {name: newLocation},
            false
        );

    }
  }


  componentDidMount () {
    //  window.addEventListener("load", this.captchaLoad());
    window.addEventListener("load", this.getLocations());
    $(function () {
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }
      
    })
    setTimeout( () => { this.waitForElem(), 1500})
  }

   render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "Gasparilla | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: []
    }

    return (

      <div className="page mappage">
        <Helmet>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          
          <title>{pageTitle}</title>

          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
          <script src='https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.js' fetchpriority="high" id="mapjs" async />
          <link href='https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.css' rel='stylesheet' fetchpriority="high" />

          {/*<script src="/public/assets/vendor-js/map.js" defer async fetchpriority="low"></script>*/}
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content gspr" id="main" role="main">
          {/* DRIZLY SECTION */}
          <section className="flex gspr" style={{backgroundImage: `url(${heroImage})`}} id="promos">
            <div className="logoDeco">
              <img src={logoDeco} aria-hidden="true" />
            </div>
            <div className="container container--1600">
              <div className="gspr__wrap">
                <h1 className="gspr__title text--gold">
                  Oh no! Follow the captain at gasparilla 2024 has come to an end!
                </h1>
                <div className="gspr__copy gspr__copy--md text--white">
                  <strong>But Worry Not! More Adventrues Await...</strong>
                </div>
                <div class="gspr__cta ">
                  <a href="/followthecaptain/" className="btn btn--white btn--decorated text-red">
                    Stay Tuned!
                  </a>
                </div> 
              </div>
            </div>
          </section>
          <section className="comp2__section footer" style={{backgroundImage: `url(${heroImage})`}}>
            <div className="footer__logo">
              <img src={animatedTagline} alt="Captain Morgan Logo" />
            </div>
           
            <footer className="page__footer" role="contentinfo">
             <aside className="follow-us">
              <div className="follow-us__inner">
                  <h2 className="follow-us__title">Follow Captain Morgan</h2>
                  <div className="follow-us__strapline">Be the first to know about events, promotions and competitions</div>
                  <ul className="follow-us__options">
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.facebook.com/CaptainMorganUSA/?brand_redir=138940200780" target="_blank" rel="noopener noreferrer">
                             <div className="styled-icon">
                            <img src={footerFBLogo} aria-hidden />
                            </div>
                          <span className="follow-us__option-link-alt">Facebook</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.instagram.com/captainmorganusa/" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">                         
                              <img src={footerIGLogo} aria-hidden/>
                          </div>
                          <span className="follow-us__option-link-alt">Instagram</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://twitter.com/CaptainMorganUS" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">
                              
                                <img src={footerTWLogo} aria-hidden/>
                              
                          </div>
                          <span className="follow-us__option-link-alt">Twitter</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.youtube.com/user/CaptainMorgan" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">
                                <img src={footerYTLogo} aria-hidden/>
                          </div>
                          <span className="follow-us__option-link-alt">YouTube</span>
                        </a>
                    </li>
                    
                  </ul>
              </div>
            </aside>
              
               <div className="footer__disclaimer">
               <div className="footer__rules">
                        <a  href="/captain-morgan-nfl-rules.pdf">Official Rules</a>
                    </div>
                <div className="copy">
                  <strong>
                    CAPTAIN MORGAN ORIGINAL SPICED RUM. Caribbean Rum Made with Vanilla, Spice, and Other Natural Flavors. 35% Alc./Vol. Captain Morgan Rum Co., New York, NY. Please Drink Responsibly. Do not forward to anyone under the age of 21.
                  </strong>
                </div>
              </div>
            </footer>
          </section>
        </main>
      </div>
    );
  }
}
