import "./three-product-promo-product.scss";
import {BlockingLink} from "global/utils/blocking-link";
import Lazy from "global/utils/lazy";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

class ThreeProductPromoProduct extends React.Component {

    render () {

        const {
            CardImage,
            CardTitle,
            Url,
            ...otherProps
        } = fieldsArrayToKeyValueHash(this.props.productData.fields);
        const cardImageProps = fieldsArrayToKeyValueHash(CardImage);
        const imageSrc = cardImageProps.Url;

        const imageSrcLarge = encodeURI(`${imageSrc}&width=2028`);
        const imageSrcSmall = encodeURI(`${imageSrc}&width=768`);
        const imageSrcExtraSmall = encodeURI(`${imageSrc}&width=375`);
        const imageAlt = cardImageProps && cardImageProps.Alt ? cardImageProps.Alt : 'Image of ' + CardTitle
        
        return (

            <div className="three-product-promo__product">
                <BlockingLink
                    to={Url}
                    className="three-product-promo__product-link"
                    onMouseOver={
                        () => {

                            this.props.onMouseOver(otherProps.BackgroundColour);

                        }
                    }
                >
                    <Lazy
                        Tag="div"
                        containerClassName="three-product-promo__product-visual"
                    >
                        <div
                            className="three-product-promo__product-visual-inner"
                        >
                            <img
                                className="three-product-promo__product-image"
                                src={`${imageSrc}`}
                                srcSet={`
                                    ${imageSrcLarge} 1000w,
                                    ${imageSrcSmall} 768w,
                                    ${imageSrcExtraSmall} 375w`
                                }
                                alt={imageAlt}
                            />
                        </div>
                    </Lazy>
                    <div className="three-product-promo__product-content">
                        <h2 className="three-product-promo__product-heading">
                            {CardTitle}
                        </h2>
                    </div>
                </BlockingLink>
            </div>

        );

    }

}

export default ThreeProductPromoProduct;
