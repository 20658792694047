/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/*eslint valid-jsdoc: 2*/
/* eslint-disable */

import "./style.scss";
import React from "react";
import fetch from 'isomorphic-fetch'
import {load} from "recaptcha-v3"
import * as storage from 'global/utils/storage'
import * as routeList from "global/utils/routing"
import $ from 'jquery'
import playButton from 'global/images/de_de_comp_game/play_button.png'
import RulesBg from 'global/images/de_de_comp_game/enter_middle_bg_desktop.jpg'
import RulesBuyHere from 'global/images/de_de_comp_game/enter_middle_buy_it_here.png'
import RulesLeftDesktop from 'global/images/de_de_comp_game/middle_section_left_desktop.png'
import RulesRight from 'global/images/de_de_comp_game/middle_section_right.png'
import RulesLeftMobile from 'global/images/de_de_comp_game/middle_section_left_mobile.png'
import GameVideo from 'global/images/de_de_comp_game/enter_bottom_bg.jpg'
import GameVideoOverlay from 'global/images/de_de_comp_game/video_overlay_full.png'
import GameVideoLeft1 from 'global/images/de_de_comp_game/bottom_section_foreground_1.png'
import GameVideoLeft2 from 'global/images/de_de_comp_game/bottom_section_foreground_2.png'
import StyledIcon from "../../../components/atoms/styled-icon";
import SocialIconTrailer from 'global/images/de_de_comp_game/enter_middle_view_trailer.png'
import ahoyImage from 'global/images/de_de_comp_game/welcome-aboard.png'
import BuyHand from 'global/images/de_de_comp_game/middle_hand.png'


class GameEnterCode extends React.Component {

    constructor (props) {
      super(props);
      this.findLink = "";
      const locSplit = props.location.pathname.split('/')

      this.state = {
        errorMessage: '',
        formError: false,
        gameAssets: routeList.getSectionAssets(this.props.location.pathname),
        locale: locSplit[1]
      }

      console.log("LSDKFJSD > ", this.state.locale)

    }



    updateErrorMessage (message) {
      const errorDiv = document.getElementById('errorMessage')
      errorDiv.innerHTML = message
      this.state.errorMessage = message
    }

    captchaLoad = () => {
      load('6Lf5-30cAAAAAAhYAb4XHcLZFh-8sj_OHetPhcvt', {
        explicitRenderParameters: {
          container: 'recaptcha'
        }
      })
    }

    renderListItem (rule, index) {
      const listImage = rule && rule.image !== '' ? rule.image : ''
      const copy = rule && rule.copy ? rule.copy : ''

      return (         
        <li className='ruleslist__item' key={`ruleslistitem_${index}`}>
          <label className='ruleslist__count'>
            {index+1}
          </label>
          {listImage && listImage !== '' &&
            <div className='ruleslist__image'>
              <img src={listImage} alt={`Rule ${index+1} Icon`} />
            </div>
          }
          <div className='ruleslist__copy'>
            {copy}
          </div>
        </li>
      )
    }

    checkStorage = () => {
      storage.initClaimed()
    }

    componentDidMount () {
      //JS goes here
      window.addEventListener('load', this.captchaLoad())
      window.addEventListener('load', this.checkStorage())

      $(function() {
        // $(window).on('load', function () {
        //   var footerBg = RulesBg
        //   $('#footer').css({backgroundImage: `url(${footerBg}`})
        // })
        $('.scrollTo').on('click', function (e) {
          e.preventDefault()

          var target = $(this).attr('href')

          if ($(target).length > 0) {
            var t = $(target)
            var zoom = $('body').css('zoom')
            var scrollOffset = t.offset().top
            var tSplit = target.split('refs')

            if ((zoom) < 1) {
                if ((tSplit[1]) >= 20) {
                    scrollOffset = (scrollOffset * (.89))
                } else if ((tSplit[1]) <= 10) {
                    scrollOffset = (scrollOffset * (.85))
                } else {
                    scrollOffset = (scrollOffset * (.88))
                }
            } else {
                scrollOffset = scrollOffset - 100
            }

            $('html, body').animate({
                scrollTop: scrollOffset
            }, 500)
          }
        })

        var myVideo = document.getElementById("videoSectionMp4");
       
        $('.gamevideo__wrap button').on('click', function() {
          if (myVideo.paused) {
              if (myVideo.requestFullscreen) {
                  myVideo.requestFullscreen();
              }
              else if (myVideo.msRequestFullscreen) {
                  myVideo.msRequestFullscreen();
              }
              else if (myVideo.mozRequestFullScreen) {
                  myVideo.mozRequestFullScreen();
              }
              else if (myVideo.webkitRequestFullScreen) {
                  myVideo.webkitRequestFullScreen();
              }
              myVideo.play();
              $('#videoSectionMp4').addClass('active')
               window.dataLayer.push({
              'event':'customEvent',
              'eventCategory': 'VideoModal',
              'eventAction': 'Open',
              'eventLabel': 'Video Play',
              'eventInteraction': 'true'
            });
          }
          else {
              myVideo.pause();
          }

          myVideo.addEventListener('fullscreenchange', (event) => {
            if (document.fullscreenElement) {
              console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
            } else {
              console.log('Leaving fullscreen mode.');
              myVideo.pause()
              $('#videoSectionMp4').removeClass('active')
            }
          });

        })

      })
    }

  render () {
    const gameAssets = this.state.gameAssets
    const rulesBgImage = RulesBg
    const rulesLeftImageDesktop = RulesLeftDesktop
    const rulesLeftImageMobile = RulesLeftMobile
    const rulesHeadingImage = gameAssets && gameAssets.gamecode && gameAssets.gamecode.headingimage && gameAssets.gamecode.headingimage !== '' ? gameAssets.gamecode.headingimage : ahoyImage
    const rulesTitle = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulestitle && gameAssets.gamecode.rulestitle !== '' ? gameAssets.gamecode.rulestitle : 'Ahoy, captain'
    const rulesSubTitle = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulessubtitle && gameAssets.gamecode.rulessubtitle !== '' ? gameAssets.gamecode.rulessubtitle : "Haven't got a code yet?"
    const rulesList = gameAssets && gameAssets.gamecode && gameAssets.gamecode.ruleslist ? gameAssets.gamecode.ruleslist : []
    const rulesBuyHereUrl = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulesbuyhereurl && gameAssets.gamecode.rulesbuyhereurl !== '' ? gameAssets.gamecode.rulesbuyhereurl : '#'
    const rulesBuyHereModal = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulesbuyheremodal && gameAssets.gamecode.rulesbuyheremodal !== '' ? gameAssets.gamecode.rulesbuyheremodal : []
    const rulesBuyHereImage = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulesbuyhereimg && gameAssets.gamecode.rulesbuyhereimg !== '' ? gameAssets.gamecode.rulesbuyhereimg : RulesBuyHere
    const rulesFollowText = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulesfollowtext && gameAssets.gamecode.rulesfollowtext !== '' ? gameAssets.gamecode.rulesfollowtext : 'Follow Us: '
    const rulesFollowUrl = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulesfollowurl && gameAssets.gamecode.rulesfollowurl !== '' ? gameAssets.gamecode.rulesfollowurl : ''
    const rulesFollowUrlFacebook = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulesFollowUrlFacebook && gameAssets.gamecode.rulesFollowUrlFacebook !== '' ? gameAssets.gamecode.rulesFollowUrlFacebook : ''
    const rulesFollowUrlTwitter = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulesFollowUrlTwitter && gameAssets.gamecode.rulesFollowUrlTwitter !== '' ? gameAssets.gamecode.rulesFollowUrlTwitter : ''
    const rulesTrailerUrl = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulestrailerurl && gameAssets.gamecode.rulestrailerurl !== '' ? gameAssets.gamecode.rulestrailerurl : '#'
    const rulesTncText = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulestnctext && gameAssets.gamecode.rulestnctext !== '' ? gameAssets.gamecode.rulestnctext : 'faq and t&cs'
    const rulesTncUrl = gameAssets && gameAssets.gamecode && gameAssets.gamecode.rulestncurl && gameAssets.gamecode.rulestncurl !== '' ? gameAssets.gamecode.rulestncurl : '#'
    const videoUrl = gameAssets && gameAssets.gamecode && gameAssets.gamecode.videourl && gameAssets.gamecode.videourl !== '' ? gameAssets.gamecode.videourl : '#'
    const gameVideoSection = gameAssets && gameAssets.gamecode && gameAssets.gamecode.videomp4 && gameAssets.gamecode.videomp4 !== '' ? gameAssets.gamecode.videomp4 : ''
    const umbrellaGif = gameAssets && gameAssets.gamecode && gameAssets.gamecode.umbrellagif && gameAssets.gamecode.umbrellagif !== '' ? gameAssets.gamecode.umbrellagif : ''
    const computerGraphic = gameAssets && gameAssets.gamecode && gameAssets.gamecode.computer && gameAssets.gamecode.computer !== '' ? gameAssets.gamecode.computer : GameVideoOverlay
    return (
    	<>
        {this.state.locale !== 'escapegameslovakia' ?    
          <section className="GameEnterCode gamevideo" style={{backgroundImage: `url(${GameVideo}`}}>
            {gameVideoSection && gameVideoSection !== '' &&            
              <div className='gamevideo__wrap'>
                <div className='gamevideo__overlay'>
                  <video className="gamevideo__video" id='videoSectionMp4' controlsList="nodownload">
                      <source src={gameVideoSection}
                              type="video/mp4"/>
                      Sorry, your browser doesn't support embedded videos.
                  </video>
                  <img src={computerGraphic} className='gamevideo__overlay--img' alt='Watch the video'/>
                  <button className="active">
                    <img src={playButton} alt='Press Play.'/>
                  </button>
                </div>
              </div>
            }
            <div className='gamerules__images images images__left images__left--2 images--desktop' id='videoImage1'>
              <img src={GameVideoLeft2} alt='Captain Morgan Bottle Image Desktop' />
            </div>
            <div className='gamerules__images images images__left images__left--1 images--desktop' id='videoImage2'>
            <div className='images__wrap'>
              {umbrellaGif && umbrellaGif !== '' &&
                <a href={videoUrl} className='gamevideo__link' target='_blank'>
                  <img src={umbrellaGif} className='gamevideo__gif' alt='Captain Morgan Mint Cooler Link Image' />
                </a>
              }
                <img src={GameVideoLeft1} alt='Captain Morgan Mint Cooler Image' />
              </div>
            </div>
          </section>
          :
          null
        }
    		<section className="GameEnterCode gamerules" style={{backgroundImage: `url(${RulesBg}`}} id='gameRules'>
          <div className='gamerules__images images images__left images--desktop'>
            <img src={RulesLeftDesktop} alt='Captain Morgan Bottle Image Desktop' />
          </div>
          <div className='gamerules__images images images__left images--mobile'>
            <img src={RulesLeftMobile} alt='Captain Morgan Bottle Image Mobile' />
          </div>
          <div className='gamerules__images images images__right images--desktop'>
            <img src={RulesRight} alt='Captain Morgan Cocktail Image Mobile' />
          </div>
    	    <div className="container">
            <div className="inner">
              <div className='gamerules__wrap'>
                <div className='gamerules__header'>
                  <img className='gamerules__wrap-header-image' src={rulesHeadingImage} alt={rulesTitle} />
                  <h2 className='gamerules__title gold_font' id='rulesTitle'>
                    {rulesTitle}
                  </h2>
                </div>
                <div className='gamerules__content'>
                  {rulesBuyHereModal.length <= 0 &&
                  <a href={rulesBuyHereUrl} target='_blank' className='gamerules__buy'>
                    <img src={rulesBuyHereImage} alt='Buy it here.' />
                    <img src={BuyHand} className='gamerules__hand animateLeftRight' alt='Buy it here.' />
                  </a>}
                   {rulesBuyHereModal.length > 0 &&
                  <a onClick={()=>$("#buyNowModal").addClass("active")} target='_blank' className='gamerules__buy'>
                    <img src={rulesBuyHereImage} alt='Buy it here.' />
                    <img src={BuyHand} className='gamerules__hand animateLeftRight' alt='Buy it here.' />
                  </a>}
                  <h3 className='gamerules__subtitle'>
                    {rulesSubTitle}
                  </h3>
                  {rulesList && rulesList.length > 0 &&
                    <div className='gamerules__list ruleslist'>
                      <ol className='ruleslist__ol'>
                        {rulesList.map((rule, i) =>
                          this.renderListItem(rule,i)
                        )}
                      </ol>
                    </div>
                  }
                  <div className='gamerules__social social'>
                    <div className='social__wrap'>
                      <div className='social__follow'>
                        <span>{rulesFollowText}</span>
                        <a href={rulesFollowUrl}  target="_blank">
                          <StyledIcon icon={"Instagram"} />
                            <span className="follow-us__option-link-alt">
                                Instagram
                            </span>
                        </a>
                        {rulesFollowUrlFacebook &&
                        <a href={rulesFollowUrlFacebook} target="_blank">
                          <StyledIcon icon={"Facebook"} />
                            <span className="follow-us__option-link-alt">
                                Facebook
                            </span>
                        </a>}
                         {rulesFollowUrlTwitter &&
                        <a href={rulesFollowUrlTwitter}  target="_blank">
                            <StyledIcon icon={"Twitter"} />
                            <span className="follow-us__option-link-alt">
                                Twitter
                            </span>
                        </a>
                        }
                      </div>
                      <div className='social__trailer'>
                        <a href={rulesTrailerUrl}>
                          <img src={SocialIconTrailer} alt='Watch the trailer' />
                        </a>
                      </div>
                      <div className='social__tnc'>
                        <a href={rulesTncUrl}>
                          {rulesTncText}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    	    </div>
          <div id="buyNowModal" className="buyNowModal">
              <div className="modalOverlay modalClose"></div>

              <div className="modalContent">
                  <button onClick={()=>$("#buyNowModal").removeClass("active")} className="modalClose">&#10006;</button>
                  <div className="scroll">
                    <div className="modalLinkList">
                    {rulesBuyHereModal.length > 0 && rulesBuyHereModal.map((item) =>{
                      return (
                      <a href={item.link} target='_blank' className='buy'>
                        <img src={item.image} alt='Buy it here.' />
                      </a>
                      )
                    })
                   }
                   </div>
                  </div>
              </div>
          </div>
    		</section>
    	</>
    );
  }
}

export default GameEnterCode;
