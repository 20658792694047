import "./back-to-top-banner.scss";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import smoothScroll from "global/utils/smooth-scroller";

export default class BackToTopBanner extends React.Component {

    render () {

        const {Title} = fieldsArrayToKeyValueHash(this.props.fields);
        const scrollLabel = Title ? Title : "Scroll to top"
        return (
            <div className="back-to-top-banner">
                <div className="back-to-top-banner__inner">
                    <button
                        className="back-to-top-banner__action"
                        onClick={() => smoothScroll("body")}
                        aria-label={scrollLabel}
                    >
                        {
                            Title &&
                                <span className="back-to-top-banner__action-text">
                                    {Title}
                                </span>
                        }
                        <span className="back-to-top-banner__action-icon" />
                    </button>
                </div>
            </div>
        );

    }

}
