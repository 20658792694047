/* eslint-disable no-confusing-arrow */
import {Lazy} from "react-lazy";
import React from "react";

const LazyLoader = ({
    Tag,
    containerClassName,
    containerStyle,
    children,
}) => global.window
    ? <Lazy component={Tag} className={containerClassName} style={containerStyle}>
        {children}
    </Lazy>
    : <Tag className={containerClassName} style={containerStyle}>
        {children}
    </Tag>;

export default LazyLoader;
