import "./navigation.scss";
import React, {Component} from "react";
import {BlockingNavLink} from "global/utils/blocking-link";
import Logo from "./logo";
import NavigationNode from "./navigation-node";
import {connect} from "react-redux";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

class Navigation extends Component {

    state = {
        "isLoaded": false,
        "openMenu": false,
    };

    constructor (props) {
        super(props);
    }

    componentDidMount () {

        window.addEventListener("resize", this.handleWindowResize);

    }

    toggleInert = (elements, isInert) => {

        elements.forEach((element) => {

            if (document.querySelector(element)) {

                if (isInert) {

                    document.querySelector(element).setAttribute("inert", isInert);

                } else {

                    // Have to manage this way as just setting to false has no effect and element remains inert
                    document.querySelector(element).removeAttribute("inert");

                }

            }

        });

    }

    manageInertElements = (isInert) => {

        this.toggleInert([
            ".page__content",
            ".page__footer",
            ".navigation__brand",
        ], isInert);

    }

    handleWindowResize = () => {

        this.viewportWith = window.innerWidth;
        this.largeBreakpoint = 1200;

        if (this.viewportWith >= this.largeBreakpoint) {

            this.closeMenu();

        }

    }

    toggleMenu = () => {

        this.setState({openMenu: !this.state.openMenu});
        this.manageInertElements(!this.state.openMenu);

    }

    closeMenu = () => {

        this.setState({openMenu: false});
        this.manageInertElements(false);

    }

    render () {

        const logoData = this.props.fields.find((element) => element.title === "Logo");
        const navigationLinkData = this.props.fields.filter((element) => element.title === "NavigationNode");
        const {
            LogoUrl,
            PurchaseIntegration,
        } = fieldsArrayToKeyValueHash(this.props.fields);

        const {
            IntegrationType,
            PurchaseIntegrationBuyCtaText,
            WhereToBuyDefaultPageUrl,
        } = fieldsArrayToKeyValueHash(PurchaseIntegration);

        const isOpenClass = this.state.openMenu && "navigation--toggled";
        const {isShopalystActive} = this.props;
        
        return (
            <nav className={`navigation ${isOpenClass || ""}`}
                role="navigation"
            >
                <div className="navigation__brand">
                    {
                        logoData &&
                        <Logo fields={logoData.fields} LogoUrl={LogoUrl}/>
                    }
                </div>
                <div className="navigation__menu"
                    aria-modal={`${this.state.openMenu && "true"}`}>
                    <div className="navigation__toggles">
                        <button
                            id="main-nav__toggle"
                            className={`navigation__toggle navigation__toggle--options
                            hamburger hamburger--spin
                            ${this.state.openMenu && "is-active"}`}
                            aria-expanded={`${this.state.openMenu && "true"}`}
                            onClick={() => this.toggleMenu()}
                            type="button"
                            aria-haspopup="true"
                            aria-controls="main-nav__options">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                            <span className="navigation__toggle-text">Toggle navigation</span>
                        </button>
                    </div>
                    <div
                        id="main-nav__options"
                        className="navigation__options"
                        aria-labelledby="main-nav__toggle">
                        <ul
                            className="navigation__nav"
                            role="menu">
                            {
                                navigationLinkData.map((navigationLink, index) => <NavigationNode
                                    fields={navigationLink.fields}
                                    index={index}
                                    key={`nav-${index}`}
                                    onClick={this.closeMenu.bind(this)}
                                />)
                            }
                        </ul>
                        {
                            PurchaseIntegration && PurchaseIntegrationBuyCtaText && (isShopalystActive || WhereToBuyDefaultPageUrl) &&
                                <ul
                                    className="navigation__actions"
                                    role="menu">
                                    {
                                        IntegrationType === "Shopalyst" && isShopalystActive &&
                                            <li role="none" className="navigation__actions-item">
                                                <button
                                                    className="navigation__actions--buy"
                                                    role="menuitem"
                                                    onClick={() => {

                                                        // eslint-disable-next-line no-underscore-dangle
                                                        window._shopalyst.openBrandstore({}, {
                                                            "closeButton": {
                                                                "color": "white",
                                                                "position": "left",
                                                                "type": "back",
                                                            },
                                                            "closeOverlayOnClick": true,
                                                            "height": "100%",
                                                            "hideLoader": true,
                                                            "maxWidth": "600px",
                                                            "position": "right",
                                                            "type": "lightbox",
                                                            "width": "100%",
                                                        });

                                                    }}
                                                >
                                                    {PurchaseIntegrationBuyCtaText}
                                                </button>
                                            </li>
                                    }
                                    {
                                        IntegrationType === "Where To Buy" && WhereToBuyDefaultPageUrl &&
                                            <li role="none" className="navigation__actions-item">
                                                <BlockingNavLink
                                                    to={WhereToBuyDefaultPageUrl}
                                                    prefetch
                                                    className="navigation__actions--buy"
                                                    role="menuitem"
                                                    onClick={this.closeMenu.bind(this)}
                                                >
                                                    {PurchaseIntegrationBuyCtaText}
                                                </BlockingNavLink>
                                            </li>
                                    }
                                </ul>
                        }
                    </div>
                </div>
            </nav>
        );

    }

}

const mapStateToProps = (state) => ({isShopalystActive: state.content.isShopalystActive});

export default connect(mapStateToProps)(Navigation);
