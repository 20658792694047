import "./facebook-feed.scss";

import {CtaComponent} from "../../../components/atoms/cta";
import React from "react";
import SocialCard from "../../../components/molecules/social-card";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

export default class FacebookFeed extends React.Component {

    state = {
        showFacebookFeed: 4,
    }

    render () {

        const {
            FacebookComponentHeading,
            FacebookComponentDescription,
            FacebookFeedShowMore,
            FacebookFeedShowMoreCtaUrl,
        } = fieldsArrayToKeyValueHash(this.props.fields);

        const Cards = this.props.fields.filter((field) => field.title === "ImageData");

        if (!Cards) {

            return null;

        }

        return <aside className="facebook-feed">
            <div className="facebook-feed__inner">

                <header className="facebook-feed__header">
                    {
                        FacebookComponentHeading &&
                            <h2 className="facebook-feed__title">
                                {FacebookComponentHeading}
                            </h2>
                    }
                    {
                        FacebookComponentDescription &&
                            <div
                                className="facebook-feed__summary"
                                dangerouslySetInnerHTML={{__html: sanitise(FacebookComponentDescription)}}>
                            </div>
                    }
                </header>

                <nav className="facebook-feed__list">
                    <ul className="facebook-feed__list-items">
                        {
                            Cards.slice(0, this.state.showFacebookFeed).map((card, index) => <li
                                key={`fb-item-${index}`}
                                className="facebook-feed__list-item"
                            >
                                <SocialCard {...card}/>
                            </li>)
                        }
                    </ul>
                </nav>

                {
                    FacebookFeedShowMore && FacebookFeedShowMoreCtaUrl &&
                        <div className="facebook-feed__ctas">
                            <CtaComponent
                                Link={FacebookFeedShowMoreCtaUrl}
                                Caption={FacebookFeedShowMore}
                                NewWindow={true}
                            />
                        </div>
                }

            </div>
        </aside>;

    }

}
