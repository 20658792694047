import "./page-preview.scss";
import React, {Component} from "react";
import buildPage from "../page-builder";
import queryString from "query-string";

export default class PageContainer extends Component {

    state = {
        "isLoaded": false,
        "setRoutes": false,
    };

    constructor (props) {

        super(props);
        if (!props.data.fetchedData) {

            const pageName = this.getPageName();

            props.fetchData({
                isPreview: true,
                name: pageName,
            });

        }

    }

    componentDidUpdate (prevProps) {

        const pageName = this.getPageName();
        const {data} = this.props;

        if (prevProps.location.search !== this.props.location.search && !data[pageName]) {

            this.props.fetchData(
                {name: pageName},
                false
            );

        }

    }

    getPageName () {

        return queryString.parse(this.props.location.search).path;

    }

    render () {

        const {data} = this.props;
        const pageName = this.getPageName();

        if (!data[pageName]) {

            // In future, avoid this!
            return null;

        }

        const page = buildPage(data, pageName);

        return (
            <div className="page">
                {page.headerComponents}

                <main className="page__content" id="main" role="main">
                    {page.bodyComponents}
                </main>

                <footer className="page__footer" role="contentinfo">
                    {page.footerComponents}
                </footer>
            </div>
        );

    }

}
