import React from "react";

export default class CheckBox extends React.Component {

    render () {

        return (
            <input
                id={`recipe-filters__accordion-item-checkbox-${this.props.id}`}
                type="checkbox"
                checked={this.props.isChecked}
                disabled={this.props.disableOption}
                onChange={this.props.onToggle}
                className="recipe-filters__accordion-item-checkbox"
            />
        );

    }

}
