import "./content-drinksworks.scss";
import React from "react";

import * as utils from 'global/utils/utils'

import logoWhite from 'global/images/halloween2023/logo_white.png'

const DWContentSplash = (props) => {
  const pageLocale = props && props.pagelocale ? props.pagelocale : ""
  const contentData = getData(pageLocale)
  
  function goToNextPage (e) {
    const target = e.target
    let label
    let answer
    if(target && (target.dataset && target.dataset.title)) {
      answer = target.dataset.title
    }
    if(target && (target.dataset && target.dataset.question)) {
      label = target.dataset.question
    }
    const ga = {
      event: 'in_page_button',
      click_text: label,
      detail: answer
    }
    utils.gaSubmit(ga)
    props.nextpagecallback()
  }
  function getData (pageLocale) {
    const data = [
      {
        locale: 'en-us',
        data: {
          headertitle: '<span class="text--white dwtitle--small dwtitle--span">Welcome to the</span>',
          questiontitle: 'unsure where to start?',
          buttontext: 'discover tasty recipes',
        }
      },
      {
        locale: 'de-de',
        data: {
          headertitle: '<span class="text--white dwtitle--small dwtitle--span">WILKOMMEN BEI</span>',
          questiontitle: 'UNSICHER WO DU STARTEN SOLLST?',
          buttontext: 'ENTDECKE LECKERE REZEPTE',
        }
      }
    ]

    if(pageLocale) {
      const contentItems = data.filter(x => x.locale === pageLocale)[0]
      if(contentItems) {
        return contentItems.data
      }else{
        return null
      }
    }else{
      return null
    }
  }

  return (
    <div className="dwcontent">
      <h1 className="dwcontent__title dwtitle" dangerouslySetInnerHTML={{
        __html: `${contentData.headertitle}
          <img src=${logoWhite} class="dwtitle__image" alt="Captain Morgan white logo" />
          <div class="align--right dwtitle--main gold_font">Drinks</div>
          <div class="align--right dwtitle--main dwtitle--main--small gold_font">Works</div>
        `
        }}
      />
      <div className="dwcontent__introcta introcta">
        <h2 className="introcta__title">
          {contentData.questiontitle}
        </h2>
        <button className="btn btn--goldbggradient" data-title={contentData.buttontext} data-question={contentData.questiontitle} onClick={(e) => goToNextPage(e)}>
          {contentData.buttontext}
        </button>
      </div>
    </div>
  );
};

export default DWContentSplash;
