import "./map-items.scss";
import React from "react";

import * as maps from 'global/utils/map'

const MapItems = (props) => {
  const locations = props && props.data ? props.data : []

  const goToMap = (lat, lon) => {
    console.log('GO TO MAP LAT > ', lat)
    console.log('GO TO MAP LONG > ', lon)
    maps.flyToMarker(lat, lon)
  }
  return (
    <div className="maplocations">
      {locations.map( (loc,key) => {
        return(
          <div key={`locations_${key}`} className="maplocations__item" onClick={() => goToMap(loc.loclat, loc.loclon)}>
            {loc.locname}<br />
            {loc.locaddress}, {loc.loczipcode}<br />
            {loc.loctimestart} - {loc.loctimeend}<br />
          </div>
        )
      })}
    </div>
  );

};

export default MapItems;
