import React, { useState } from 'react'

const FormPicklist = (props) => {
  const { errorMsg,
  register,
  labelStyle,
  half,
  setValue,
  id,
  registeredId,
  label,
  options, } = props

  const [focus, setFocus] = useState(false)
  const [search, setSearch] = useState('')

  const [focusDropdown, setFocusDropdown] = useState(false)
  const [filteredPicklist, setFilteredPicklist] = useState(options)

  const [selectedList, setSelectedList] = useState([])

  const selectedListItems = selectedList.map((val) => {
    return (
      <span
        key={val}
        tabIndex={0}
        className="inline-flex cursor-pointer items-center rounded-md bg-zinc-400 px-2 py-1 uppercase text-black/80"
        onClick={() => {
          const filtered = selectedList.filter((item) => item !== val)
          setSelectedList(filtered)
          setValue(registeredId, filtered.join(','))
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const filtered = selectedList.filter((item) => item !== val)
            setSelectedList(filtered)
            setValue(registeredId, filtered.join(','))
          }
        }}
      >
        {val}
        <span className="bg-gray ml-2 inline-flex h-5 w-5 items-center justify-center rounded-full p-1 lowercase text-[#fbf8ed]">
          x
        </span>
      </span>
    )
  })

  const pickList = filteredPicklist.map(({ value, disabled }) => {
  const selected = selectedList.find((val) => val === value) ? true : false

    return (
      <li
        key={value}
        className={`my-2 py-1 px-2 uppercase ${disabled ? 'pointer-events-none text-black/40' : 'cursor-pointer'}
        ${selected ? 'rounded-md bg-zinc-400 text-black' : ''}`}
        tabIndex={!disabled && focusDropdown ? 0 : -1}
        onClick={() => {
          if (selected) setSelectedList([...selectedList.filter((val) => val !== value)])
          else setSelectedList([...selectedList, value])
          setValue(registeredId, [...selectedList, value].join(','))
        }}
        onKeyDown={(e) => {
          if (selected) return
          if (e.key === 'Enter') {
            setSelectedList([...selectedList, value])
            setValue(registeredId, [...selectedList, value].join(','))
          }
        }}
      >
        {value}
      </li>
    )
  })
  
  return (
    <>
      <div className="inputWrapper labeled relative w-full lg:mx-2">
        <label
          htmlFor={id}
          className={`pointer-events-none inputlabel inputlabel--empty absolute ml-3 mt-4 cursor-text uppercase transition-all duration-500 md:mt-0 ${
            focus
              ? `${
                  labelStyle === 'inside'
                    ? '-left-1 -top-3 md:left-0 md:top-0'
                    : 'bg-form -top-5 left-1 md:-top-1 md:left-3'
                } text-[.656rem] text-black md:text-[.7878rem]`
              : `${labelStyle === 'inside' ? 'top-0 md:top-3' : 'top-0 md:top-5'} left-2 text-2xl  text-black/40  ${
                  search !== '' && 'opacity-0'
                }`
          }`}
        >
          {label}
        </label>
        <input {...register} hidden />
        <input
          id={id}
          aria-describedby={`${id}Error`}
          className={`formInput input font-futura w-full border-2 border-black py-3 px-5 text-2xl uppercase text-black outline-[#F7941D] ${
            labelStyle === 'inside' ? 'bg-formInput py-3 md:py-4' : 'bg-form py-2 md:py-3'
          }`}
          onFocus={() => {
            setFocus(true)
            setFocusDropdown(true)
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          onChange={(e) => {
            setSearch(e.target.value)
            setFilteredPicklist(
              options.filter(({ value }) => value.toLowerCase().includes(e.target.value.toLowerCase())),
            )
          }}
          type='text'
        />
      </div>

      <div className="inputWrapper w-full">        
        {selectedListItems.length > 0 && (
          <div className="picklist picklist--items flex w-full flex-wrap gap-2 px-3 py-3">{selectedListItems}</div>
        )}
        <ul
          className={`options-dropdown absolute top-[105%] left-0 z-10 max-h-96 w-full overflow-y-auto bg-[#fbf8ed] px-3 transition-opacity duration-700 ${
            focusDropdown ? 'opacity-100' : 'pointer-events-none opacity-0'
          }`}
          onFocus={() => {
            setFocusDropdown(true)
          }}
          onBlur={() => {
            setFocusDropdown(false)
          }}
        >
          {pickList}
        </ul>
      </div>
      
      {
        <span id={`${id}Error`} role="alert" className="w-full text-center text-sm text-red-600">
          {errorMsg}
        </span>
      }
    </>
  )
}

export default FormPicklist
