import React, {Component} from "react";
import PropTypes from "prop-types";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class FilterHeader extends Component {

    render () {

        const {
            FilterTitle,
            RecipeText,
        } = fieldsArrayToKeyValueHash(this.props.filterProperties);
        
        return (
           
            <div className={"recipe-and-filter-list__header"}>
                <div className={"recipe-and-filter-list__header-inner"}>
                    <div className="recipe-and-filter-list__header-inputs">
                        <button
                            id="recipe-and-filter-list__header-button--filter"
                            className="recipe-and-filter-list__header-button recipe-and-filter-list__header-button--filter" // eslint-disable-line max-len
                            aria-haspopup="true"
                            aria-controls="recipe-filters"
                            aria-expanded={this.props.showingFilters}
                            onClick={this.props.toggleFilters}
                        >
                            {FilterTitle}
                            <span className="recipe-and-filter-list__header-button-icon" />
                        </button>
                    </div>
                    <div className="recipe-and-filter-list__header-total">
                        <h2 className="recipe-and-filter-list__header-title">
                            <span>{this.props.total}</span> {RecipeText}
                        </h2>
                    </div>
                </div>
            </div>
            
        );

    }

}

FilterHeader.propTypes = {
    filterProperties: PropTypes.array.isRequired,
    toggleFilters: PropTypes.func.isRequired,
    toggleSortDirection: PropTypes.func.isRequired,
};
