/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../../organisms/app-error";
import NflProgressiveModal from "../../../organisms/NflProgressiveModal";
import ModalNfl from "../../../molecules/modal-nfl";
import Heading from "../heading";
import Footer from "../footer";
import CloudLayer from "../cloud-layer/";
import GameLayer from "../game-layer/";
import HeadingWithCopy from "../heading-with-copy/";
import buildPage from "../../page-builder";
import {load} from "recaptcha-v3";

import NavCustom from "../nav-custom";

import * as maps from 'global/utils/map'
import * as scrollLock from 'global/utils/scrollLock'
const cn = require('classnames')

import heroImage from 'global/images/ftc2024/bg-red.jpg'
import ftcGlitch from 'global/images/ftc2024/ftc-glitch.png'
import ftcGlitchMobile from 'global/images/ftc2024/ftc-glitch--mobile.png'
import treasure from 'global/images/ftc2024/treasure.png'
import locationPin from 'global/images/comp2023/pizza_pin.png'

import eventImage1 from 'global/images/ftc2024/event_cocktails.png'
import eventImage2 from 'global/images/ftc2024/event_adventures.png'

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "eyebrow": "Legends start when you",
      "image": ftcGlitch,
      "imageSize": 'medium',
      "imageMobile": ftcGlitchMobile,
      "imagealt": "Follow the captain",
      "title": "",
      "srText": "follow the captain",
      "copy": "Click the anchors to unlock legendary adventures, the flags for epic experiences with your crew and the treasure chest to win prizes dropping every week.",
    },
    "inGame": true,
    "currentGame": "",
     "modalOpen": false,
    "modalType": '',
    "modalData": {},
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  showModal (type, data) {
    const modalData = data ? data : {}

    this.setState({
      "modalOpen": true,
      "modalType": type,
      "modalData": modalData
    })
  }

  closeModal () {
    this.setState({
      modalOpen: false
    })
  }
  async getLocations (callback, that) {
    try {
      const json = JSON.stringify({data: {}});
      const res = await fetch("/api/map/find", {
          body: json,
          headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",

          },
          method: "POST",
      // eslint-disable-next-line no-shadow
      }).then((res) => res.json());

      if (res && res.success && res.success === true) {
        console.log('GET LOCATIONS', res.data)
        if(res.data && res.data.length > 0) {
          const locsFiltered = []
          res.data.forEach(loc => {
            if(loc.active) {
              const locItem = {
                locname: loc.locname,
                locaddress: loc.locaddress,
                locstate:  loc.locstate,
                datestart: loc.datestart,
              }
              locsFiltered.push(locItem)
            }
          })
          this.setState({
            "locationData": res.data.filter( loc => loc.active),
            "locationsFiltered": locsFiltered
          })
          maps.setLocations(this.state.locationsFiltered)
          this.state.locationData.forEach( function (loc, index) {
            maps.createMarkers(loc, index, callback, that)
          })
        }
      }else{
        console.log('GET LOCATIONS ERROR 1')
        // not npm run start
      }
    } catch (error) {
      console.log("GET LOCATIONS ERROR ERROR ANS", error);
    }
  }

  clickHandler (clicked, that, game) {
    if(game !== '') {
      that.setState({
        "inGame": true,
        "currentGame": game,
      })
      setTimeout(() => {
        const gameLayer = document.getElementById('gamelayer')
        const nflPModal = document.getElementById('nflPModal')
        that.lockElements()
        if (gameLayer) {
          gameLayer.classList.remove('slideOut')
          gameLayer.classList.add('slideIn')
          nflPModal.classList.add('z-top')
        }
      }, 1000)
    }
  }

  closeOverlayCallback (that) {
    const gameLayer = document.getElementById('gamelayer')
    const nflPModal = document.getElementById('nflPModal')
    const recaptcha = document.getElementsByClassName('grecaptcha-badge')[0]
    this.unLockElements()
    if (gameLayer) {
      gameLayer.classList.add('slideOut')
      gameLayer.classList.remove('slideIn')
      nflPModal.classList.remove('z-top')
      if(recaptcha) {
        recaptcha.classList.remove('z-top')
      }
    }
    
    setTimeout(() => {
      this.setState({
        "inGame": false,
        "currentGame": "",
      })
    }, 1000)
  }

  lockElements () {
    const htmlElem = document.getElementsByTagName('html')[0]
    const bodyElem = document.getElementsByTagName('body')[0]
    const navElem = document.getElementById('mainNav')
    htmlElem.classList.add('locked')
    bodyElem.classList.add('locked')
    navElem.classList.add('locked')
  }

  unLockElements () {
    const htmlElem = document.getElementsByTagName('html')[0]
    const bodyElem = document.getElementsByTagName('body')[0]
    const navElem = document.getElementById('mainNav')
    htmlElem.classList.remove('locked')
    bodyElem.classList.remove('locked')
    navElem.classList.remove('locked')
  }

  waitForElem () {
    if(typeof mapboxgl !== "undefined"){
      maps.init()
      setTimeout( () => { this.getLocations(this.clickHandler, this), 1000})
    }else{
      setTimeout( () => { this.waitForElem(), 1500})
    }
  }

  handleFilter (event, filter) {
    const btn = event.target
    
    if(btn && filter) {
      const epicBtn = document.getElementById('filter-epic')
      const shipBtn = document.getElementById('filter-ship')
      const hasFirstClicked = this.state.firstClicked
      
      if(filter === 'epic') {
        if(epicBtn.classList.contains(`btn__mapfilter--epic-disabled`)) {
          epicBtn.classList.remove(`btn__mapfilter--epic-disabled`)
          if(!shipBtn.classList.contains(`btn__mapfilter--ship-disabled`)) {
            shipBtn.classList.add(`btn__mapfilter--ship-disabled`)
          }
          this.setHeadingProps('epic')
        }else if(!hasFirstClicked){
          epicBtn.classList.add(`btn__mapfilter--epic-disabled`)
          this.setHeadingProps('ship')
        } else {
          epicBtn.classList.add(`btn__mapfilter--epic-disabled`)
          shipBtn.classList.remove(`btn__mapfilter--ship-disabled`)
          this.setHeadingProps('ship')
        }
        
      }

      if(filter === 'ship') {
        if(shipBtn.classList.contains(`btn__mapfilter--ship-disabled`)) {
          shipBtn.classList.remove(`btn__mapfilter--ship-disabled`)
          if(!epicBtn.classList.contains(`btn__mapfilter--epic-disabled`)) {
            epicBtn.classList.add(`btn__mapfilter--epic-disabled`)
          }
          this.setHeadingProps('ship')
        }else if(!hasFirstClicked){
          shipBtn.classList.add(`btn__mapfilter--ship-disabled`)
          this.setHeadingProps('epic')
        } else {
          shipBtn.classList.add(`btn__mapfilter--ship-disabled`)
          epicBtn.classList.remove(`btn__mapfilter--epic-disabled`)
          this.setHeadingProps('epic')
        }
      }
      this.state.firstClicked = true
    }
  }

  setHeadingProps (filter) {
    if(filter !== '') {
      if(filter === 'epic') {
        this.setState({
          "headingProps": {
            "subtext": "",
            "image": "",
            "imagealt": "",
            "title": "EPIC EXPERIENCES",
             "text": "text-center m-w-550",
            "copy": "Follow the Captain on our next adventure. Click on a location for your chance to join the crew and be part of the legend.",
            "padBottom":true
          }
        })
      }
      if(filter === 'ship') {
        this.setState({
          "headingProps": {
            "subtext": "",
            "image": "",
            "imagealt": "",
            "title": "SHIP STOPS",
            "text": "text-center m-w-650",
            "copy": "Captain is dropping anchor across the nation. See where we're sailing next, and come aboard for legendary celebrations all season long.",
            "padBottom":true
          }
        })
      }
      this.updateMapPins(filter)
    }
  }

  updateMapPins (filter) {
    if(filter !== '') {
      const filterPins = document.getElementsByClassName(`event__pin`)

      for (let i = 0; i < filterPins.length; i++) {
        if(filterPins[i].classList.contains('event__pin--disabled')) {
          filterPins[i].classList.remove('event__pin--disabled')
        }
        if(!filterPins[i].classList.contains(`event-${filter}`)){
          filterPins[i].classList.add(`event__pin--disabled`)
        }
      }
    }
  }
  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {

        this.props.fetchData(
            {name: newLocation},
            false
        );

    }
  }

  componentDidMount () {
    //  window.addEventListener("load", this.captchaLoad());
    // window.addEventListener("load", this.getLocations());
    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }
      scrollLock.init('ftcFooter', 'filter')
    })
    setTimeout( () => { this.waitForElem(), 1500})
    // this.clickHandler (true, this)
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          url: '/followthecaptain',
        },
        {
          title: 'About',
          url: '/followthecaptain/about',
          inactive: true,
        },
        {
          title: 'NFL Fan of the Year',
          url: '/followthecaptain/nfl-fan-of-the-year',
          inactive: true,
        },
        {
          title: 'Buy Captain',
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          inactive: true,
        },
      ]
    }
    return (

      <div className="page pageftc">
        <NflProgressiveModal />
        <Helmet>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          
          <title>{pageTitle}</title>

          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
          <script src='https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.js' fetchpriority="high" id="mapjs" async />
          <script src="https://app.anyroad.com/assets/integration-v1.1.js" id="anyroad" />
          <script
            id="ddt-poly"
            src="https://drag-drop-touch-js.github.io/dragdroptouch/dist/drag-drop-touch.esm.min.js"
            type="module"
          ></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js"></script>
          
          <link href='https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.css' rel='stylesheet' fetchpriority="high" />

          {/*<script src="/public/assets/vendor-js/map.js" defer async fetchpriority="low"></script>*/}
        </Helmet>
        <div className={cn(this.state.modalOpen === true ? 'modalcustom open' : 'modalcustom')} id="modalcustom">
            <div className="modalcustom__container">
              <div className={cn("modalcustom__wrap", this.state.modalType === 'incorrect' ? 'modalcustom__wrap--red' : '', `modalcustom__wrap--${this.state.modalType}`)} id="modalContent">
                <button className="btn btn--close" aria-label="close modal" onClick={() => this.closeModal()}>
                  <span className={cn("line line--1", this.state.modalType === 'incorrect' ? 'line--white' : '')}></span>
                  <span className={cn("line line--2", this.state.modalType === 'incorrect' ? 'line--white' : '')}></span>
                </button>
                <ModalNfl type={this.state.modalType} data={this.state.modalData} closemodal={() => this.closeModal()} url={this.state.successUrl} />
              </div>
            </div>
        </div>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24" id="main" role="main">
          
          {(this.state.inGame) ? (
            <GameLayer game={this.state.currentGame} callback={() => this.closeOverlayCallback(this)} that={this} />
          ) : null }
          {/* DRIZLY SECTION */}
          <section className="flex mapsection">            
            <div className="container container--1600 z-10">
              <Heading {...this.state.headingProps} />
            </div>
            <div className="container container--1600 z-10 mobile-nopad" id="mapContainer">
              <div className="map__wrap flex">                
                <div className="mapdiv flex">
                  <div className="mapdiv__map" id="map">
                  </div>
                </div>
              </div>
            </div>
            <div id="filter" className="filter flex align-center justify-center fixed z-10">
              <div className="filter__main flex align-center justify-center">
                <button
                  aria-label="Epic Experiences"
                  className="btn__mapfilter btn__mapfilter--epic"
                  id="filter-epic"
                  onClick={(e) => this.handleFilter(e, 'ship')}
                  type="button"
                />
                <button
                  aria-label="Ship Stops"
                  className="btn__mapfilter btn__mapfilter--ship"
                  id="filter-ship"
                  onClick={(e) => this.handleFilter(e, 'epic')}
                  type="button"
                />
              </div>
              <div className="filter__chest">
                <button
                  aria-label="Play game" 
                  className="btn__mapfilter btn__mapfilter--chest"
                  onClick={() => this.clickHandler(true, this, 'card')}
                  type="button"
                >
                  <img src={treasure} aria-label="A golden treasure chest" width="200px" height="200px" loading="lazy" />
                </button>
              </div>
            </div>
            <CloudLayer background={heroImage} />
          </section>
          <section className="section flex otherinfo">
            <div className="container container--1440">
              <HeadingWithCopy
                contentCopy="Trace where the Captain has been."
                title="HAVE YOU FOLLOWED THE CAPTAIN?"
              />
              <div className="events__items">
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage1}  className="left" alt="Captain Cocktails" />
                    </div>
                    <div className="eitem__title">
                      <a href="/en-us/cocktails" className="btn btn--reset btn--textwhite btn--bgtransparent" title="Captain cocktails">
                        <span className="sr-only">CAPTAIN COCKTAILS</span>
                      </a>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
                <div className="events__item eitem">
                  <div className="eitem__wrap">
                    <div className="eitem__image">
                      <img src={eventImage2} className="right" alt="Past Adventures" />
                    </div>
                    <div className="eitem__title">
                      <button className="btn btn--reset btn--textwhite btn--bgtransparent" onClick={() => this.showModal('gallery', {type: 'past2023'})} aria-label="View Images">
                        <span className="sr-only">PAST ADVENTURES</span>
                      </button>
                    </div>
                    <div className="eitem__overlay"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
