/* eslint-disable max-lines */
/* eslint-disable file */
/* eslint-disable max-lines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-inline-comments */
/* eslint-disable no-negated-condition */
/* eslint-disable consistent-this */
/* eslint-disable no-shadow */
/* eslint-disable sort-keys */
/* eslint-disable id-length */
/* eslint-disable multiline-ternary */
/* eslint-disable sort-imports */
/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import React from "react";
import {toJS, observable} from "mobx";
import moment from "moment";
// eslint-disable-next-line id-length
import $ from "jquery";

class Newsletter extends React.Component {

	@observable submitState

	constructor (props) {


	    super(props);
		 this.formData = new Map();

	    this.termsMissing = true;

	    this.phoneRequired = false;

	    //This.dobInvalid = false;

	    this.submitState = "open";

	}


	dobInvalid () {

	    if (!this.formData.has("CP_DOB")) {

	        return false;

	    }

	    const b = moment(this.formData.get("CP_DOB"));
	    const n = moment();


	    return n.diff(b, "years") < 21;

	}

	onChange = (e) => {


	    if (e.target.name === "CP_DOB") {

	        this.formData.set(
	            e.target.name,
	            moment(e.target.value).format("YYYY-MM-DD")
	        );

	    } else {

	        this.formData.set(
	            e.target.name,
	            e.target.type === "checkbox" ? e.target.checked : e.target.value
	        );

	    }

	}

	onSubmit = (e) => {

	    e.preventDefault();

	    if (this.dobInvalid()) {


	        document.getElementById("DOB_Error").style.display = "block";

	        return;


	    }

	    this.termsMissing = false;
	    if (
	        !this.formData.has("newsletter_optin_terms") ||
			!this.formData.get("newsletter_optin_terms")
	    ) {

	        this.termsMissing = true;

	        return;

	    }
		if(window) {
			const urlParams = new URLSearchParams(window.location.search);
			const myParam = urlParams.get('source');
			
			if(myParam) {
				this.formData.set("source", myParam);
			}
		
		}
		

	    this.formData.set("HD_EmailType", "1");
	    this.formData.set("PR_4_92_1", "222");
	    this.formData.set("HD_PromoCode", this.props.modal ? "DNA107CPT0322WA0012" : "DNA107CPT0322WA0013");
	    this.formData.set("HD_IsDefaultFlag", "1");
	    // This.formData.set("HD_PR_1_64_2_Comm", "4")
	    // This.formData.set("HD_PR_1_64_2_Brand", "26")
	    this.formData.set("HD_PR_1_64_1_Comm", "1");
	    this.formData.set("HD_PR_1_64_1_Brand", "4");
	    this.formData.set("HD_PR_1_64_2_Comm", "1");
	    this.formData.set("HD_PR_1_64_2_Brand", "16");
      this.formData.set("PR_1_64_1", true);
      this.formData.set("PR_1_64_2", true);
		 this.formData.set("CP_WebsiteUrl", window.location.origin);
	    // This.formData.set("PR_1_64_1", "1")
	    // This.formData.set("HD_PhoneType", "3")
	    this.fetchPPVersionOnLoad();
	    this.callCidb(toJS(this.formData));

	}

	fetchPPVersionOnLoad = () => {

	    try {

	        const ppVer = window.dg_footer_configurations.hg_PP_Version;

	        if (ppVer) {

	            this.formData.set("ppVersion", ppVer);

	        } else {

	            throw new Error("Cannot get Privacy Policy version");

	        }

	    } catch (err) {

	        console.warn(err);

	    }

	}

	handleBack = () => {

	    document.getElementById("success").style.display = "none";
	    document.getElementById("error").style.display = "none";
	    document.getElementById("cidbForm").style.display = "block";

	}

	async callCidb (data) {


	    try {

	        const res = await fetch("/api/newsletter", {
	            method: "POST",
	            headers: {
	                "Content-Type": "application/json",
	                Accept: "application/json",
	            },
	            body: JSON.stringify(Object.fromEntries(data)),
	        }).then((res) => res.json());
         
	        if (res === 200) {
				
	            document.getElementById("success").style.display = "block";
				document.getElementById("error").style.display = "none";
				document.getElementById("cidbForm").style.display = "none";
				 
	            this.successfulSubmissionAnalytics(data.get("PR_1_64_2"));

	        } else {

	            document.getElementById("error").style.display = "block";
	            document.getElementById("cidbForm").style.display = "none";
				document.getElementById("success").style.display = "none";

	        }

	    } catch (error) {
			

	       document.getElementById("error").style.display = "block";
		   document.getElementById("cidbForm").style.display = "none";

	    }

	}

	successfulSubmissionAnalytics = (theBarOptIn) => {
		
	

	    if (!this.props.modal) {
			const urlParams = new URLSearchParams(window.location.search);
			const myParam = urlParams.get('source');
			
	        window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'sign_up',
				step: 'success',
				sign_up_type: 'newsletter',
				form_name: 'Static Newsletter Form',
				detail: 'newsletter opt-in',
				//"inline", "modal", "fly-out modal" or "footer"
				form_location: myParam === "followthecaptain" ? 'footer':'inline',
				optin_brands: theBarOptIn ?'Captain Morgan | TheBar' :'Captain Morgan',
			})

	    } else {
	       window.dataLayer = window.dataLayer || [];
	       window.dataLayer.push({
				event: 'sign_up',
				step: 'success',
				sign_up_type: 'newsletter',
				form_name: 'Newsletter Sign Up Modal',
				detail: 'newsletter opt-in',
				//"inline", "modal", "fly-out modal" or "footer"
				form_location:'modal',
				optin_brands: theBarOptIn ?'Captain Morgan | TheBar' :'Captain Morgan',
			})

	    }

	}

	componentDidMount () {

	    // eslint-disable-next-line init-declarations
	    let CP_DOB;

	    const _this = this;

	    $("body").on("click", "#age_confirm_btn", () => {


	        if ($("#age_select_year").val() !== "-1") {

	            // Desktop
	            let yearFormat = `${$("#age_select_year").val()}-`;

	            yearFormat +=
				$("#age_select_month").val().length > 1
				    ? $("#age_select_month").val()
				    : `0${$("#age_select_month").val()}`;
	            yearFormat += "-";
	            yearFormat +=
				$("#age_select_day").val().length > 1
				    ? $("#age_select_day").val()
				    : `0${$("#age_select_day").val()}`;
	            CP_DOB = yearFormat;

	        } else {

	            // Mobile
	            CP_DOB = $("#age_select_date_picker").val();

	        }
	        if ($("#CP_DOB").length) {

	            const dobParts = CP_DOB.split("-");

	            $("#CP_DOB").val(`${dobParts[0]}-${dobParts[1]}-${dobParts[2]}`);
	            _this.formData.set("CP_DOB", CP_DOB);

	        }

	    });

	}

	render () {

	    return (
			<>
				<div className="newsletter">
				    <section id="newsletter">
				        <div className={"col-md-12 col-md-offset-0 text-container"}>
				            <section className={"filter container-fluid"}>
				                <div className={"heading"}>
									
				                   {!this.props.modal && <h1 className={"text-center"}>Connect with Captain</h1> }
								     {this.props.modal && <p className={"text-center  large-text"}>Connect with Captain</p> }
				                    <p style={{textAlign: "center"}}>
									Sign up today for updates on promotions, events and recipes.
				                    </p>
				                </div>
				            </section>
				        </div>

				        <div className={"container"}>
				            <div className={"inner"}>
				                {(this.submitState === "open" || !this.submitState) &&
							<form
							    className="formWrapper"
							    id="cidbForm"
							    onSubmit={this.onSubmit}
							>
							    <div className="formMeta">
							        <input
							            id="PR_4_367_1_freetext"
							            name="PR_4_367_1_freetext"
							            type="hidden"
							            placeholder=""
							            value="10"
							        />
							    </div>
							    <div className="formRow">
							        <div className="formCell half">
                          <label htmlFor="CP_Firstname">
                            First Name *
                          </label>
							            <input
							                type="text"
							                name="CP_FirstName"
							                id="CP_FirstName"
							                className="input w-input"
							                placeholder="First Name*"
							                required
							                onChange={this.onChange}
							            />
							        </div>

							        <div className="formCell half">
                          <label htmlFor="CP_LastName">
                            Last Name *
                          </label>
							            <input
							                type="text"
							                name="CP_LastName"
							                id="CP_LastName"
							                className="input w-input"
							                placeholder="Last Name*"
							                required
							                onChange={this.onChange}
							            />
							        </div>
							    </div>

							    <div className="formRow">
							        <div className="formCell half">
                          <label htmlFor="CP_DOB">
                            Date of Birth *
                          </label>
							            <input
							                type="date"
							                id="CP_DOB"
							                className="input w-input datePicker"
							                name="CP_DOB"
							                required
							                autoComplete="off"
							                placeholder="mm/dd/yyyy"
							                //Placeholder="date"
							                onChange={this.onChange}
							            />
							        </div>

							        <div className="formCell half">
                          <label htmlFor="CP_EmailId">
                            Email *
                          </label>
										      <input
							                type="email"
							                name="CP_EmailId"
							                id="CP_EmailId"
							                className="input w-input"
							                placeholder="Email*"
							                required
							                onChange={this.onChange}
							            />

							        </div>
							    </div>
							    <div className="formRow">
							        <div className="formCell half">
                          <label htmlFor="PR_4_324_1_freetext">
                            Zip Code *
                          </label>
							            <input
							                type="text"
							                name="PR_4_324_1_freetext"
							                id="PR_4_324_1_freetext"
							                className="input w-input"
							                placeholder="Zip Code*"
							                required
							                maxLength={5}
							                onChange={this.onChange}
							            />
							        </div>
									 <div className="formCell half">
                          <label htmlFor="PR_4_466_1_freetext">
                            State *
                          </label>
							            <select

							                name="PR_4_466_1_freetext"
							                id="PR_4_466_1_freetext"
							                className="input w-input"
							                required
							                onChange={this.onChange}
							            >
							                <option selected disabled hidden value="">State*</option>
							                <option value="AL">Alabama</option>
							                <option value="AK">Alaska</option>
							                <option value="AZ">Arizona</option>
							                <option value="AR">Arkansas</option>
							                <option value="CA">California</option>
							                <option value="CO">Colorado</option>
							                <option value="CT">Connecticut</option>
							                <option value="DE">Delaware</option>
							                <option value="DC">District Of Columbia</option>
							                <option value="FL">Florida</option>
							                <option value="GA">Georgia</option>
							                <option value="HI">Hawaii</option>
							                <option value="ID">Idaho</option>
							                <option value="IL">Illinois</option>
							                <option value="IN">Indiana</option>
							                <option value="IA">Iowa</option>
							                <option value="KS">Kansas</option>
							                <option value="KY">Kentucky</option>
							                <option value="LA">Louisiana</option>
							                <option value="ME">Maine</option>
							                <option value="MD">Maryland</option>
							                <option value="MA">Massachusetts</option>
							                <option value="MI">Michigan</option>
							                <option value="MN">Minnesota</option>
							                <option value="MS">Mississippi</option>
							                <option value="MO">Missouri</option>
							                <option value="MT">Montana</option>
							                <option value="NE">Nebraska</option>
							                <option value="NV">Nevada</option>
							                <option value="NH">New Hampshire</option>
							                <option value="NJ">New Jersey</option>
							                <option value="NM">New Mexico</option>
							                <option value="NY">New York</option>
							                <option value="NC">North Carolina</option>
							                <option value="ND">North Dakota</option>
							                <option value="OH">Ohio</option>
							                <option value="OK">Oklahoma</option>
							                <option value="OR">Oregon</option>
							                <option value="PA">Pennsylvania</option>
							                <option value="RI">Rhode Island</option>
							                <option value="SC">South Carolina</option>
							                <option value="SD">South Dakota</option>
							                <option value="TN">Tennessee</option>
							                <option value="TX">Texas</option>
							                <option value="UT">Utah</option>
							                <option value="VT">Vermont</option>
							                <option value="VA">Virginia</option>
							                <option value="WA">Washington</option>
							                <option value="WV">West Virginia</option>
							                <option value="WI">Wisconsin</option>
							                <option value="WY">Wyoming</option>
							            </select>


							        </div>
							    </div>

							    <div className="formRow">
							        {/* <div className="formCell half">
								<input
								type="text"
								name="CP_PhoneNumber"
								id="CP_PhoneNumber"
								className="input w-input"
								placeholder="Phone Number"
								maxLength={10}
								onChange={this.onChange}
								/>
								<input
								id="HD_PhoneType"
								name="HD_PhoneType"
								type="hidden"
								value="3"
								/>
							</div> */}
							    </div>

							    {/*<div className="formCell row checkboxCell">
							        <input
							            type="checkbox"
							            id="PR_1_64_1"
							            name="PR_1_64_1"
							            value="1"
							            onChange={this.onChange}
										required
							        />
							        <label htmlFor="PR_1_64_1" className="checkboxLabel">
								Yes, I would like to receive special offers and promotions
								from Captain Morgan by email. *
							        </label>
							    </div>
							    <div className="formCell row checkboxCell">
							        <input
							            type="checkbox"
							            id="PR_1_64_2"
							            name="PR_1_64_2"
							            value="1"
							            onChange={this.onChange}
							        />
							        <label htmlFor="PR_1_64_2" className="checkboxLabel">
										Yes, I would like to receive promotions and special offers for gifting sprits, cocktail kits and barware from TheBar.com by email
							        </label>
							    </div>
							    {/* {!this.props.modal && (
							<div className="formCell row checkboxCell">
								<input
								type="checkbox"
								id="PR_1_64_2"
								type="checkbox"
								name="PR_1_64_2"
								value="1"
								onChange={this.onChange}
								/>
								<label htmlFor="PR_1_64_2" className="checkboxLabel">
								Yes, I would like to receive special offers and promotions
								from Captain Morgan by text message (SMS).
								</label>
							</div>
							)} */}

							    <div className="formCell row checkboxCell">
							        <input
							            type="checkbox"
							            id="newsletter_optin_terms"
							            name="newsletter_optin_terms"
							            required
							            onChange={this.onChange}
							        />

							        <label
							            htmlFor="newsletter_optin_terms"
							            className="checkboxLabel"
							        >
                      Tick here if you would like us to use your email to keep you informed about products, services and events from Captain Morgan & other Diageo brands including TheBar.com. You can unsubscribe at any time.<br />
                      By signing up, you accept Diageo&apos;s{" "}
                          <a
                              href="https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us"
                              target="_blank"
                              style={{textDecoration: "underline"}}
                          >Conditions of Use</a>{" "}and acknowledge the{" "}<a
                              href="https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us"
                              target="_blank"
                              style={{textDecoration: "underline"}}
                          >Privacy & Cookie Notice</a>. *
							        </label>
							    </div>

							    {/* <div className="formCell"></div> */}

							    <div className="formCell">

							        <div id="DOB_Error" className="DOB_Error" style={{display: "none"}}>
									You must be at least 21 years old to enter.
							        </div>

							        <input
							            className="button w-button"
							            type="submit"
							            name="submit"
							            value="SUBMIT"
							        />
									
							    </div>
							    <p>
								Captain Morgan values and respects your privacy. You can
								unsubscribe at any time.
							    </p>
							</form>
				                }

				                <div className="success text-center" id="success" style={{display: "none"}}>
							    <p className="secondary-header">Success!</p>
							    <p>Thank you for signing up for our newsletter.</p>
				                </div>


				                <div className="error" id="error" style={{display: "none"}}>
							    <p className="secondary-header">Error!</p>
							    <p>
								There was an error in your submission. Please try again.
							    </p>
							    <div className="button-container">
							        <a
							            onClick={() => this.handleBack()}
							            className="button w-button"
							        >
								Back
							        </a>
							    </div>
				                </div>

				            </div>
				        </div>
				    </section>
				</div>
			</>
	    );

	}

}

export default Newsletter;
