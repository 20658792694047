/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import DWContentSplash from "../../organisms/content-drinksworks";
import DWContentQuestions from "../../organisms/content-drinksworks/content-questions";
import DWContentRecipe from "../../organisms/content-drinksworks/content-recipe";
import DWContentSteps from "../../organisms/content-drinksworks/content-steps";
import buildPage from "../page-builder";

import * as utils from 'global/utils/utils'

import footerFBLogo from 'global/images/comp2023/facebook.png'
import footerIGLogo from 'global/images/comp2023/IG.png'
import footerTWLogo from 'global/images/comp2023/TW.png'
import footerYTLogo from 'global/images/comp2023/YT.png'
import logoWhite from 'global/images/halloween2023/logo_white.png'
import arrowBack from 'global/images/drinksworks/arrow-back.svg'

export default class PageContainer extends Component {

  state = {
    "isLoaded": false,
    "setRoutes": false,
    "locale": '',
    "currentpage": 1,
    "isSubmitted": false,
    "currentRecipe": ''
  };

  constructor (props) {
    super(props);
    this.fetchData();
    const locSplit = props.location.pathname.split('/')
    this.state.locale = locSplit[1]
  }

  getPageName () {
      const path = this.props.location.pathname
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'>/g, "&#x27;");

      if (path.endsWith("/")) {

          return path;

      }

      return `${path}/`;

  }

  fetchData () {

    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

      // No! Lots wrong here:
      // - whatabout fetching data?
      // - Transition should be blocked before
      //   We have the data to avoid rendering noop
      this.props.fetchData(
          {name: pageName},
          false
      );
    }
  } 

  handleNextPage = (item) => {
    this.setState({
      "currentpage": this.state.currentpage + 1,
      "currentRecipe": item && item !== '' ? item : ''
    })
  }

  handleGoBack () {
    this.setState({
      "currentpage": this.state.currentpage !== 1 ? this.state.currentpage - 1 : 1
    })
    const ga = {
      event: 'in_page_button',
      click_text: 'Back',
      detail: `Drinksworks_${this.state.locale}-page-${this.state.currentpage}`
    }
    utils.gaSubmit(ga)
  }

  handleReset () {
    this.setState({
      "currentpage": 1,
      "currentRecipe": ''
    })
  }

  componentDidUpdate (prevProps) {

    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {
      this.props.fetchData(
          {name: newLocation},
          false
      );
    }
    setTimeout(() => {
      $('.dwcontent').addClass('loaded')
    }, 200);
  }

  componentDidMount () {
    document.documentElement.lang = this.state.locale
    setTimeout(() => {
      $('.dwcontent').addClass('loaded')
    }, 200);
  }

  render () {

    const {data} = this.props;

    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {

        // In future, avoid this!
        return null;

    }
    // const page = buildPage(data, pageName);
    const pageLocale = this.state.locale
    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    // if (page === null) {

    //     return (
    //         <div className="page">
    //             <AppError
    //                 message="This site is currently unavailable - Come back soon."
    //             />
    //         </div>
    //     );


    // }

    // page.headerComponents[0].props.fields[0].value = "Newsletter | Captain Morgan";
    // page.headerComponents[0].props.fields[4].value = "200";


    return (
      <div className="page drinksworks">
        <Helmet>
          <script dangerouslySetInnerHTML={{
            __html: `var dg_locale=${pageLocale}`
            }}
          />
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="Tasty Drinks Works" />

          <meta property="og:title" content="Drink Works | Captain Morgan" />
          <meta property="og:description" content="Tasty Drinks Works" />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com${this.props.location.pathname}`} />
          <meta property="og:type" content="website" />
          
          <title>Drink Works | Captain Morgan</title>

        </Helmet>
        <main className="page__content" id="main" role="main">
          <nav className={`navigation`} role="navigation">
            {this.state && this.state.currentpage > 1 &&
              <button className="btn btn--back btn--bgclear btn--textwhite" aria-label="back" onClick={() => this.handleGoBack()}>
                <img src={arrowBack} alt="Arrow pointing back" aria-hidden="true" />
                <span>back</span>
              </button>
            }
            <div className="navigation__brand">
              <a aria-current="page" className="navigation__brand-link active" title="Captain Morgan Home Page"  href="https://www.amazon.de/-/en/stores/CaptainMorgan/page/43E9B7E5-ED24-41AC-9045-D47A98012BD7?ref_=ast_bln" target="_blank">
                <img src={logoWhite} alt="Captain Morgan Logo" className="navigation__brand-logo" />
              </a>
            </div>
          </nav>
          <section className="content">
            <div className="container container--600">
              {this.state && this.state.currentpage === 1 &&
                <DWContentSplash pagelocale={pageLocale} nextpagecallback={(e) => this.handleNextPage(e)} />
              }
              {this.state && this.state.currentpage === 2 &&
                <DWContentQuestions pagelocale={pageLocale} nextpagecallback={(e) => this.handleNextPage(e)} />
              }
              {this.state && this.state.currentpage === 3 &&
                <DWContentRecipe pagelocale={pageLocale} recipe={this.state.currentRecipe} nextpagecallback={(e) => this.handleNextPage(e)} />
              }
              {this.state && this.state.currentpage === 4 &&
                <DWContentSteps pagelocale={pageLocale} recipe={this.state.currentRecipe} resetcallback={(e) => this.handleReset(e)} />
              }
            </div>
          </section>
        </main>

        <footer className="page__footer" role="contentinfo">
          <aside className="follow-us">
            <div className="follow-us__inner">
                <h2 className="follow-us__title">FOLGE CAPTAIN MORGAN</h2>
                <div className="follow-us__strapline">Erfahre als erstes von Events, Aktionen und Wettbewerben</div>
                <ul className="follow-us__options">
                  <li className="follow-us__option">
                      <a className="follow-us__option-link" href="https://www.facebook.com/CaptainMorganGB/" target="_blank" rel="noopener noreferrer">
                           <div className="styled-icon">
                          <img src={footerFBLogo} aria-hidden />
                          </div>
                        <span className="follow-us__option-link-alt">Facebook</span>
                      </a>
                  </li>
                  <li className="follow-us__option">
                      <a className="follow-us__option-link" href="https://twitter.com/captainmorgan?lang=en" target="_blank" rel="noopener noreferrer">
                        <div className="styled-icon">
                            
                              <img src={footerTWLogo} aria-hidden/>
                            
                        </div>
                        <span className="follow-us__option-link-alt">Twitter</span>
                      </a>
                  </li>
                  <li className="follow-us__option">
                      <a className="follow-us__option-link" href="https://www.youtube.com/user/captainmorganeuro" target="_blank" rel="noopener noreferrer">
                        <div className="styled-icon">
                              <img src={footerYTLogo} aria-hidden/>
                        </div>
                        <span className="follow-us__option-link-alt">YouTube</span>
                      </a>
                  </li>
                  
                </ul>
            </div>
          </aside>
        </footer>
      </div>
    );
  }
}
