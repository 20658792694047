import "./product-card.scss";
import {BlockingLink} from "global/utils/blocking-link";
import {CtaComponent} from "../../atoms/cta";
import Lazy from "global/utils/lazy";
import React from "react";
import sanitise from "global/utils/sanitise";

const ProductCard = (props) => {

    const {
        background,
        copy,
        ctaText,
        heading,
        image,
        newWindow,
        url,
    } = props;

    if (!heading || !url) {

        return null;

    }
    const altText = props && props.alt ? props.alt : 'Image of ' + props.heading
    return (
        <div className="product-card">
            <BlockingLink
                target={newWindow
                    ? "_blank"
                    : "_self"
                }
                to={url}
                className={"product-card__link"}
            >
                <Lazy
                    Tag="div"
                    containerClassName="product-card__visual"
                    containerStyle={{backgroundColor: `#${background}`}}

                >
                    <div
                        className="product-card__image"
                        style={{backgroundImage: `url(${image})`}}
                        role="img"
                        aria-label={altText}
                    />
                </Lazy>
                <div className="product-card__content">
                    { heading &&
                        <p className="product-card__heading">
                            {heading}
                        </p>
                    }
                    { copy &&
                        <div
                            className="product-card__copy"
                            dangerouslySetInnerHTML={{__html: sanitise(copy)}}
                        />
                    }
                </div>
                { ctaText &&
                    <div className="product-card__ctas">
                        <CtaComponent Caption={ctaText} />
                    </div>
                }
            </BlockingLink>
        </div>

    );

};

export default ProductCard;
