import {
    calculateElementOffsets,
    elementFocusWithoutScroll,
    getScrollTop,
} from "global/utils/scroll";

import React from "react";
import {withRouter} from "react-router";


const debounce = (func, wait) => {

    const defaultWaitTime = 100;

    let timeout = null;


    return () => {

        const context = this; // eslint-disable-line
        const args = arguments; // eslint-disable-line
        const later = () => {

            timeout = null;

            func.apply(context, args);

        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait || defaultWaitTime);

    };

};

const getElementOffset = (element) => {

    const scrollTop = getScrollTop();

    if (element) {

        const {top, bottom} = element.getBoundingClientRect();

        return {
            bottom: Math.floor(bottom + scrollTop),
            top: Math.floor(top + scrollTop),
        };

    }

    return {
        bottom: 0,
        top: 0,
    };

};

const doesElementContainScrollTop = (element, extraOffset = 0) => {

    const scrollTop = getScrollTop();
    const offsetTop = getElementOffset(element).top + extraOffset;

    const offsetHeight = element
        ? element.offsetHeight
        : 0;

    return scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight;

};

const checkLocationRelevance = (el1, el2) => {

    const {top: top1} = getElementOffset(el1);
    const {top: top2} = getElementOffset(el2);

    return top2 > top1;

};

const checkElementRelevance = (el1, el2) => {

    if (el1.contains(el2)) {

        // El2 is child, so it gains relevance priority
        return true;

    } else if (!el2.contains(el1) && checkLocationRelevance(el1, el2)) {

        // El1 and el2 are unrelated, but el2 has a better location,
        // So it gains relevance priority
        return true;

    }

    return false;

};

const getBestAnchorGivenScrollLocation = (anchors, offset) => {

    let bestElement = null,
        bestId = null;

    anchors.forEach((anchor) => {

        const element = document.querySelector(anchor);

        if (doesElementContainScrollTop(element, offset)) {

            if (!bestElement || checkElementRelevance(bestElement, element)) {

                bestElement = element;
                bestId = anchor;

            }

        }

    });

    return bestId;

};

const getHash = () => window // eslint-disable-line no-confusing-arrow
    ? decodeURI(window.location.hash.slice(1))
    : "";


class AnchorObserver extends React.Component {

    constructor () {

        super();

        this.scrollHandler = debounce(this.scrollHandler.bind(this));

    }

    componentDidMount () {

        window.addEventListener("scroll", this.scrollHandler, false);

    }

    updateHash (hash) {

        this.props.history.replace(hash);

    }

    scrollHandler () {

        const offset = calculateElementOffsets(this.props.stickyElementList || []);
        const bestAnchorId = getBestAnchorGivenScrollLocation(this.props.anchors, -offset);


        if (bestAnchorId && getHash() !== bestAnchorId) {

            this.updateHash(bestAnchorId, false);

        }

    }

    render () {

        return <div>
            {this.props.children}
        </div>;

    }

}

export default withRouter(AnchorObserver);
