import {calculateElementOffsets} from "./scroll";

const scrollBack = (element, topElementSelectors = []) => {

    const domElement = document.querySelector(element);

    if (!domElement) {

        return;

    }

    const offset = calculateElementOffsets(topElementSelectors);
    const top = domElement.offsetTop - offset;

    if ("scrollBehavior" in document.documentElement.style) {

        window.scrollTo({
            behavior: "smooth",
            left: 0,
            top,
        });

    } else {

        window.scrollTo(0, top);

    }

};

export default scrollBack;
