import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default (recipeListing, selectedTags) => {

    const filterGroups = Object.keys(selectedTags);

    return recipeListing.filter((listing) => {

        const recipeProperties = fieldsArrayToKeyValueHash(listing.fields);

        return filterGroups.every((group) => {

            const groupTags = selectedTags[group];

            if (!groupTags.length) {

                // No tags selected means don't filter
                return true;

            }

            const groupTagsForRecipe = recipeProperties[group];

            return groupTags.some((tag) => groupTagsForRecipe.indexOf(tag) > -1);

        });

    });

};

const getMatchingRecipeCountForTag =
    (recipeList, tagProperty, tag) => recipeList.reduce((total, recipe) => {

        const recipeProps = fieldsArrayToKeyValueHash(recipe.fields);
        const groupTags = recipeProps[tagProperty];

        if (groupTags.indexOf(tag) > -1) {

            return total + 1;

        }

        return total;

    }, 0);

export {getMatchingRecipeCountForTag};
