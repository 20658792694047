import {BlockingLink} from "global/utils/blocking-link";
import React from "react";
import RecipeCard from "../../molecules/recipe-card";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const renderRecipeCard = (card, index) => {

    const {
        CardTitle,
        CardImage,
        CardSummary,
        Url,
    } = fieldsArrayToKeyValueHash(card.fields);

    const imageProps = fieldsArrayToKeyValueHash(CardImage);

    return <li key={`recipe-${index}`} className="recipe-list__items-item" role="listitem">
        <RecipeCard
            index={index}
            heading={CardTitle}
            copy={CardSummary}
            image={imageProps.Url}
            url={Url}
        />
    </li>;

};

export default class ProductGrid extends React.Component {

    render () {

        if (!this.props.recipes) {

            return null;

        }

        return (
            <nav className="recipe-list">
                <ul className="recipe-list__items" role="list" aria-live="polite">
                    {
                        this.props.recipes
                            .map((recipe, index) => renderRecipeCard(recipe, index))
                    }
                </ul>
            </nav>
        );

    }

}
