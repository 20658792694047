import "./style.scss";
import React from "react";

import cloudsTL from 'global/images/ftc2024/clouds-tl.png'
import cloudsTR from 'global/images/ftc2024/clouds-tr.png'
import cloudsBL from 'global/images/ftc2024/clouds-bl.png'
import cloudsBR from 'global/images/ftc2024/clouds-br.png'

const CloudLayer = (props) => {
  const bg = props.background ? props.background : ''
  return (
    <div className="cloudlayer" style={{backgroundImage: `url(${bg})`}}>
      {/* <div className="clouds clouds--left clouds--top clouds--topleft" loading="lazy">
        <img
          aria-hidden="true"
          height="500px"
          loeading="lazy"
          src={cloudsTL}
          width="1200px"
        />
      </div>
      <div className="clouds clouds--right clouds--top clouds--topright" loading="lazy">
        <img
          aria-hidden="true"
          height="500px"
          loading="lazy"
          src={cloudsTR}
          width="1200"
        />

      </div> */}
      <div className="clouds clouds--left clouds--bottom clouds--bottomleft" loading="lazy">
        <img
          aria-hidden="true"
          height="840px"
          loading="lazy"
          src={cloudsBL}
          width="1200"
        />
      </div>
      <div className="clouds clouds--right clouds--bottom clouds--bottomright" loading="lazy">
        <img
          aria-hidden="true"
          height="540px"
          loading="lazy"
          src={cloudsBR}
          width="1200"
        />
      </div>
    </div>
  );
};

export default CloudLayer;
