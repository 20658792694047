import "./three-product-promo.scss";
import Cta from "../../../components/atoms/cta";
import React from "react";
import ThreeProductPromoProduct from "./three-product-promo__product/";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class ThreeProductPromo extends React.Component {

    state = {backgroundColor: null};

    setBackground (backgroundColor) {

        if (backgroundColor) {

            this.setState({backgroundColor: `#${backgroundColor}`});

        } else {

            this.setState({backgroundColor: null});

        }

    }

    handleOnHover = (backgroundColor) => {

        this.setBackground(backgroundColor);

    };

    isProductCard = (field) => field.title === "ProductCard";

    componentDidMount () {

        const firstPromo = this.props.fields.find(this.isProductCard);

        if (!firstPromo) {

            return;

        }

        const {BackgroundColour} = fieldsArrayToKeyValueHash(firstPromo.fields);

        this.setBackground(BackgroundColour);

    }


    render () {

        const productPromos = this.props.fields.filter(this.isProductCard);
        const {CtaCollection} = fieldsArrayToKeyValueHash(this.props.fields);
        const backgroundColor = {backgroundColor: this.state.backgroundColor};

        if (!productPromos) {

            return false;

        }

        return (

            <nav className="three-product-promo" style={backgroundColor}>
                <div className="three-product-promo__products">
                    <div className="three-product-promo__products-inner">
                        {
                            productPromos.map((product, index) => <ThreeProductPromoProduct
                                key={`product-${index}`}
                                productData={product}
                                onMouseOver={this.handleOnHover.bind(this)}
                            />)
                        }
                    </div>
                </div>
                {
                    CtaCollection &&
                        <div className="three-product-promo__cta">
                            <div className="three-product-promo__cta-inner">
                                {
                                    CtaCollection.map((cta, index) => <Cta
                                        key={`cta-${index}`}
                                        cta={cta}
                                    />)
                                }
                            </div>
                        </div>
                }
            </nav>

        );

    }

}
