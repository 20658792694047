import "./style.scss";
import React from "react";

import HeadingWithCopy from "../heading-with-copy/";

import logoNfl from 'global/images/ftc2024/partners/logo_nfl.png'

import logoJets from 'global/images/ftc2024/partners/logo_jets.png'
import logoLightning from 'global/images/ftc2024/partners/logo_lightning.png'
import logoCowboys from 'global/images/ftc2024/partners/logo_cowboys.png'
import logoCardinals from 'global/images/ftc2024/partners/logo_cardinals.png'
import logoRams from 'global/images/ftc2024/partners/logo_rams.png'
import logoGiants from 'global/images/ftc2024/partners/logo_giants.png'

import logoPenguins from 'global/images/ftc2024/partners/logo_penguins.png'
import logoWild from 'global/images/ftc2024/partners/logo_wild.png'
import logoBlackhawks from 'global/images/ftc2024/partners/logo_blackhawks.png'

import logoBucks from 'global/images/ftc2024/partners/logo_bucks.png'
import logoBulls from 'global/images/ftc2024/partners/logo_bulls.png'

const Partners = (props) => {
  const sectionTitle = props && props.title ? props.title : ''
  const copy = props && props.contentCopy ? props.contentCopy : ''

  return (
    <section className="section flex partners">
      <div className="container container--1440">
        <HeadingWithCopy
          contentCopy="Trace where the Captain has been."
          title="HAVE YOU FOLLOWED THE CAPTAIN?"
        />
        <div className="partners__blocks blocks flex">
          {/* <div className="blocks__group">
            <label className="blocks__title">&nbsp;</label>
            <div className="blocks__items flex">
              <div className="blocks__item  mobile-full w-full">
                <img src={logoNfl} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" className="size--medium" />
              </div>
            </div>
          </div> */}
          {/* NFL TEAMS */}
          <div className="blocks__group">
            <label className="blocks__title">NFL Teams</label>
            <div className="blocks__items flex">
              <div className="blocks__item w-third">
                <img src={logoJets} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item w-third">
                <img src={logoLightning} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item w-third">
                <img src={logoCowboys} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item w-third">
                <img src={logoCardinals} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item w-third">
                <img src={logoRams} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item w-third">
                <img src={logoGiants} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
            </div>
          </div>
          {/* NHL TEAMS */}
          <div className="blocks__group flex">
            <label className="blocks__title">NHL Teams</label>
            <div className="blocks__items flex">
              <div className="blocks__item w-full">
                <img src={logoPenguins} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item w-half">
                <img src={logoWild} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item w-half">
                <img src={logoBlackhawks} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
            </div>
          </div>
          {/* NBA TEAMS */}
          <div className="blocks__group">
            <label className="blocks__title">NBA Teams</label>
            <div className="blocks__items flex">
              <div className="blocks__item w-full">
                <img src={logoBucks} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
              <div className="blocks__item  w-full">
                <img src={logoBulls} aria-label="A golden treasure chest" width="443px" height="436px" loading="lazy" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Partners;
