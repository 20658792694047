import React from "react";
import Shopalyst from "./shopalyst";

class PurchaseIntegration extends React.Component {

    render () {

        const ShopalystData = this.props.fields.find((element) => element.title === "Shopalyst");

        if (ShopalystData) {

            return (
                <Shopalyst
                    {...ShopalystData}
                />
            );

        }

        return true;

    }

}

export default PurchaseIntegration;
