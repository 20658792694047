import * as yup from 'yup'
import * as moment from 'moment'

const signupSchema = yup.object({
  //firstName
  '00N1B00000B8U4J': yup.string().max(30).required('Please enter your first name'),
  //lastName
  '00N1B00000B8U4K': yup.string().max(30).required('Please enter your last name'),
  //dob
  '00N4v00000BqSVA': yup
    .string()
    .required('Please enter your date of birth')
    .test('age', 'You must be 21 or older', function (dob) {
      const birthday = moment(dob)
      return moment().diff(birthday, 'years', false) >= 21
    })
    .test('age', 'Please enter a valid date', function (dob) {
      const birthday = moment(dob)
      return moment().diff(birthday, 'years', false) < 121
    }),
  //state
  '00N4v00000C2nYa': yup
    .string()
    .min(2, 'Please enter your state')
    .max(5, 'Please enter your state')
    .required('Please enter your state'),
  //city
  '00N4v00000C2nYU': yup
    .string()
    .min(3, 'City name is too short')
    .max(35, 'City name is too long')
    .required('Please enter your city'),
  //email
  '00N4v00000C2nYW': yup
    .string()
    .email('Please enter a vaild email')
    .max(80, 'Please enter your email')
    .required('Please enter your email'),
  //verifyEmail
  '00N4v00000C2nYb': yup
    .string()
    .email('Please enter a vaild email')
    .max(80, 'Please verify your email')
    .oneOf([yup.ref('00N4v00000C2nYW')], 'Emails must match')
    .required('Please verify your email'),
  //inquiryType
  '00N4v00000C2h5k': yup
    .string()
    .matches('Consumer|Retailer|Merch|Other', 'Please select an inquiry type')
    .required('Please enter your inquiry type'),
  //contactReason
  '00N4v00000C2h5p': yup.string().when('00N4v00000C2h5k', {
    //inquiryType
    is: (val) => val === 'Consumer' || val === 'Retailer' || val === 'Other' || val === 'Merch',
    then: yup
      .string()
      .matches('Where Can I Buy Captain Morgan?|Ingredient/Allergen Inquiry|Nutritional Facts|Product concern|Merch Shop Order Inquiry|Give us some praise?|Distributor Inquiry|Media/Press Inquiry|Sponsorship|General Inquiry|Order Status|Cancel/Edit Order|Return Policy|Report a Concern',
        'Please select a reason for contacting',
      )
      .required('Please select a reason for contacting'),
  }),
  //inquiryDescription
  '00N4v00000C2nYX': yup.string().max(1000, 'Response is too long').required('Please enter your inquiry description'),
  //orderNumber
  '00N4v00000C2nYY': yup.string().when('00N4v00000C2h5p', {
    //contactReason
    is: 'Merch Shop Order Inquiry',
    then: yup.string().max(20, 'Order number is too long').required('Please enter your order number'),
  }),
  //mediaPublicatonName
  '00N4v00000BqS6K': yup.string().when('00N4v00000C2h5p', {
    //contactReason
    is: 'Media/Press Inquiry',
    then: yup
      .string()
      .max(1024, 'Media outlet/Publication name is too long')
      .required('Please enter your Media outlet/Publication name'),
  }),
  //productPicklist
  '00N1B00000BDRwq': yup.string().when('00N4v00000C2h5p', {
    //contactReason
    is: (val) => val === 'Ingredient/Allergen Inquiry' || val === 'Nutritional Facts' || val === 'Product concern',
    then: yup
      .string()
      .matches(
        /Captain Morgan Original Spiced Rum|Captain Morgan Silver Spiced Rum|Captain Morgan White Rum|Captain Morgan Private Stock|Captain Morgan Black Spiced Rum|Captain Morgan 100 Proof Rum|Captain Morgan Sliced Apple|Captain Morgan Cherry Vanilla|Captain Morgan Coconut Rum|Captain Morgan Pineapple Rum|Captain Morgan Mai Tai|Captain Morgan Long Island Ice Tea|Captain Morgan Tropical Punch|Vita Coco with Captain Morgan - Strawberry Daquiri|Vita Coco with Captain Morgan - Pina Colada|Vita Coco with Captain Morgan - Lime Mojito/,
        'Please select a product',
      )
      .required('Please select a product'),
  }),
  //phone
  '00N4v00000C2nYc': yup.string().when('00N4v00000C2h5p', {
    //contactReason
    is: (val) =>
      val === 'Product concern' ||
      val === 'Merch Shop Order Inquiry' ||
      val === 'Distributor Inquiry' ||
      val === 'Media/Press Inquiry',
    then: yup.string().length(13, 'Please enter your phone number').required('Please enter your phone number'),
  }),
  //storeName
  '00N3000000AY5Wa': yup.string().when('00N4v00000C2h5p', {
    //contactReason
    is: 'Distributor Inquiry',
    then: yup.string().max(255, 'Store name is too long').required('Please enter your store name'),
  }),
  //storeAddress
  '00N3000000AYgQ9': yup.string().when('00N4v00000C2h5p', {
    //contactReason
    is: 'Distributor Inquiry',
    then: yup.string().max(1024, 'store address is too long').required('Please enter your store address'),
  }),
  // futureField1: yup
  //   .string()
  //   .matches("none|Test", "Please select a future product 1")
  //   .required("Please enter a product"),
  // futureField2: yup
  //   .string()
  //   .max(50, "Future field 2 is too long")
  //   .required("Please enter your future field 2"),
  // futureField3: yup
  //   .string()
  //   .max(50, "Future field 3 is too long")
  //   .required("Please enter your future field 3"),
  //terms
  '00N1B00000BDRxP': yup
    .boolean()
    .oneOf([true], 'Please accept the conditions of use and acknowledge the privacy and cookie notice'),
})

export default signupSchema
