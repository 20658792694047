import {BlockingNavLink} from "global/utils/blocking-link";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default ({fields, index}) => {

    const {title, url, inactive} = fields;
    return (
        <li
            role="none"
            className={`navigation__nav-item navigation__nav-item--${index + 1}`}
        >
            <a
                href={url}
                className={`navigation__nav-link ${inactive && inactive === true ? 'navigation__nav-link--inactive' : ''}
                role="menuitem"`}
            >
                {title}
            </a>
        </li>
    );

};
