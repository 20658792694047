/* eslint-disable no-underscore-dangle */
import "react-app-polyfill/ie11";
import "./global/style/common/_fonts.scss";
import "./global/style/common/_age-gate.scss";
import "./index.scss";
import "intersection-observer";
import "core-js/es6/array";
import "core-js/es7/array";

import App from "./components/App";
import {BrowserRouter} from "react-router-dom";
import ConfigContext from "context/config";
import {Provider} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import createStore from "store";
import rootSaga from "sagas";

const initialState = JSON.parse(window.__PRELOADED_STATE__ || "{}");
const config = {
    apiUrl: window.__API_URL__ || process.env.APP_BACKEND_URI,
    appUrl: window.__APP_URL__ || process.env.APP_FRONTEND_URI,
    defaultMarket: window.__DEFAULT_MARKET__ || process.env.DEFAULT_MARKET,
};

const store = createStore(initialState);

store.runSaga(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <ConfigContext.Provider value={config}>
                <App />
            </ConfigContext.Provider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("app")
);
