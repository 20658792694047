/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import $ from "jquery";
import React from "react";
import fetch from "isomorphic-fetch";
import {load} from "recaptcha-v3";

import moment from "moment";
import * as routeList from "global/utils/rsvpcontent";

const cn = require("classnames");

class RsvpForm extends React.Component {

    constructor (props) {
      super(props);
      this.findLink = "";
      this.useProps = routeList.getSectionAssets(this.props.location.pathname)
      console.log('thisprops > ', this.props)
      console.log('thisprops > ', this.useProps)
      this.state = {
          dobErr: false,
          errorMessage: "",
          formError: false,
          fullTitle: this.useProps && this.useProps.rsvp && this.useProps.rsvp.fulltitle ? this.useProps.rsvp.fulltitle : 'RSVP FULL',
          fullCopy: this.useProps && this.useProps.rsvp && this.useProps.rsvp.fullcopy ? this.useProps.rsvp.fullcopy : 'RSVP FULL',
          thankYouTitle: this.useProps && this.useProps.rsvp && this.useProps.rsvp.thankyoutitle ? this.useProps.rsvp.thankyoutitle : 'Thank you!',
          thankYouCopy: this.useProps && this.useProps.rsvp && this.useProps.rsvp.thankyoucopy ? this.useProps.rsvp.thankyoucopy : 'Event details will be emailed to you!',
      };
    }

    updateErrorMessage (message) {

      const errorDiv = document.getElementById("errorMessage");

      errorDiv.innerHTML = message;
      this.state.errorMessage = message;
    }

    checkAge (dob) {

      /* [SEARCH-MINIMUM-AGE] */
      const birthday = moment(dob);

      if (moment().diff(birthday, "years", false) < 21) {
          this.state.dobErr = true;
          $("#CP_DOB").addClass("error");
          $("#dob__error").addClass("showError");
          this.state.formError = true;
      } else {
          this.state.dobErr = false;
      }
    }

    fetchPPVersionOnLoad () {
      try {
        const ppVer = window.dg_footer_configurations.hg_PP_Version;

        if (ppVer) {
          return ppVer
        } else {
          throw new Error("Cannot get Privacy Policy version");
          return 0
        }

      } catch (err) {
          console.warn(err);
      }
    }
    // eslint-disable-next-line complexity
    async registerUser (evnt) {
        $('#cidbSubmit').prop('disabled', true).addClass('disabled')
        // eslint-disable-next-line func-names
        $(".error").each(function () {
            // eslint-disable-next-line babel/no-invalid-this
            $(this).removeClass("error");
        });

        $("#dob__error").removeClass("showError");
        evnt.preventDefault();

        console.log("form submit >>> ", $("#CP_FirstName").val());
        const fname = $("#CP_FirstName").val();
        const lname = $("#CP_LastName").val();
        const email = $("#CP_EmailId").val();
        const country = $("#PR_4_92_1").val();
        const dob = $("#CP_DOB").val();
        const tc = $("#tc").prop("checked");
        const checkboxFields = $("#PR_1_64_1").prop("checked");
        const theBarFields = $("#PR_1_64_2").prop("checked");

        let captcha = "";

        this.updateErrorMessage("");
        this.state.formError = false;
        const data = new FormData(document.getElementById("formEnterCode"));
        // Console.log('IN REGISTER USER >>>> data ', data)

        if (!checkboxFields) {
          data.append("PR_1_64_1", false);
        }else{
          data.append("PR_1_64_1", true);
        }
        if (!theBarFields) {
          data.append("PR_1_64_2", false);
        }else{
          data.append("PR_1_64_2", true);
        }
        //Converts formdata to json
        const send = {};

        data.forEach((value, key) => {

          if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
              // eslint-disable-next-line radix
              send[key] = parseInt(value);
          } else if (key === "PR_4_92_1" || key === "tc") {
            if (value === "on") {
                send[key] = "1";
            }
          } else if (key === "g-recaptcha-response") {
            send['captcha'] = value
            send[key] = value
          } else if (key === "tc") {
            send[key] = value === 1 ? true : false
          } else if (key === "plusone") {
            send[key] = value === "true" || value === true ? true : false
          } else {
            send[key] = value;
          }

        });
        send['ppVersion'] = this.fetchPPVersionOnLoad()
        send.CP_WebsiteUrl = window.location.href;
        const json = JSON.stringify(send);

        captcha = send["g-recaptcha-response"];
        console.log("RSVP FORM SUBMIT >>>> captcha ", captcha)
        if (fname.trim() === "" || lname.trim() === "" || email.trim() === "" || country === null || dob.trim() === "" || tc === false || captcha === "") {

          if (fname.trim() === "") {
            $("#CP_FirstName").addClass("error");
          }

          if (lname.trim() === "") {
            $("#CP_LastName").addClass("error");
          }

          if (email.trim() === "") {
            $("#CP_EmailId").addClass("error");
          }

          if (country === null) {
            $("#PR_4_92_1").addClass("error");
          }

          if (dob.trim() === "") {
            $("#CP_DOB").addClass("error");
          }
          this.state.formError = true;
        }
        console.log("IN REGISTER USER >>>> send ", send);

        this.checkAge(dob);
        //Do post request to api here
        // eslint-disable-next-line no-negated-condition
        if (!this.state.formError) {
          try {
            const res = await fetch("/api/rsvp/add", {
                body: json,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",

                },
                method: "POST",
            // eslint-disable-next-line no-shadow
            }).then((res) => res.json());

            if (res.success) {

              console.log("SUCCESS Add ANS", res);
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')

              // In this case, just delete the form and show the success message
              $("#formEnterCode, #leftPanel").remove();$("#formWrapper").append(`<div id="thankyou"><h1 class="lead__title">${this.state.thankYouTitle}</h1><div class="thankyou__copy">${this.state.thankYouCopy}</div></div>`);

              console.log('window locatioin href >>> ', window.location.href)
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
              'event':'pageView',
              'pagePath': window.location.href +'/thank-you'
              });
                window.dataLayer.push({
                "event": "customEvent",
                "eventCategory": "Opt-in",
                "eventAction": "Competition",
                "eventLabel": "success_true",
                "eventInteraction": "true",
              })
            } else {
              const {message, errorType} = res;

              console.log("ERROR SUCCESS ANS", res);
              console.log("ERROR SUCCESS ANS", message);
              console.log("ERROR SUCCESS ANS", errorType);
              if(errorType === 'full') {
                // In this case, just delete the form and show the success message
                $("#formEnterCode, #leftPanel").remove();

                $("#formWrapper").append(`<div id="full"><h1 class="lead__title">${this.state.fullTitle}</h1><div class="thankyou__copy">${this.state.fullCopy}</div></div>`);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                'event':'pageView',
                'pagePath': window.location.href +'/thank-you'
                });
                  window.dataLayer.push({
                  "event": "customEvent",
                  "eventCategory": "Opt-in",
                  "eventAction": "Competition",
                  "eventLabel": "success_false",
                  "eventInteraction": "true",
                })
              }
              this.updateErrorMessage(`<div>${message}</div>`);
              this.state.formError = true;
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
              // Return res
            }
          } catch (error) {
            console.log("ERROR ERROR ANS", error);
            if($('#cidbSubmit')) {
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
            }
            return 0;
          }

        } else {

          let errorMsg = "";
          console.log('RQ FIELD >> fname ', fname)
          console.log('RQ FIELD >> lname ', lname)
          console.log('RQ FIELD >> email ', email)
          console.log('RQ FIELD >> country ', country)
          console.log('RQ FIELD >> dob ', dob)
          if (fname === "" || lname === "" || email === "" || country === "" || dob === "") {
            errorMsg = "<div>A required field is missing.</div>";
          }

          if (tc === false) {
            errorMsg += "<div>Please accept the terms and conditions and Privacy & Cookie notice.</div>";
          }

          if (captcha === "") {
            errorMsg += "<div>Please answer the Captcha.</div>";
          }
          $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          this.updateErrorMessage(errorMsg);
        }
    }

    captchaLoad = () => {
      load("6Lf5-30cAAAAAAhYAb4XHcLZFh-8sj_OHetPhcvt", {
          explicitRenderParameters: {
              container: "recaptcha",
          },
      });
    }

    componentDidMount () {
      //JS goes here
      window.addEventListener("load", this.captchaLoad());
      $(function () {
        // need to remove newsletter modal, it's conflicting with the form
        if($('#newsletterModal')) {
          $("#newsletterModal").remove()
        }
        $('#PR_4_466_1_freetext').val('')
      })
    }

    render () {
      const formAssets = routeList.getSectionAssets(this.props.location.pathname)
      //  console.log('IN RENDER RSVPFORM >>> ', formAssets)
      const formCopy = formAssets && formAssets.rsvp && formAssets.rsvp.formcopy ? formAssets.rsvp.formcopy : 'RSVP'
      const formTitle = formAssets && formAssets.rsvp && formAssets.rsvp.formtitle ? formAssets.rsvp.formtitle : 'EVENT RSVP'
      const formTermsCopy = formAssets && formAssets.rsvp && formAssets.rsvp.formterms ? formAssets.rsvp.formterms : "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *"
      const formTheBarCopy = formAssets && formAssets.rsvp && formAssets.rsvp.thebarcopy ? formAssets.rsvp.thebarcopy : 'Yes, I would like to receive promotions and special offers for gifting spirits, cocktail kits and barware from TheBar.com.'
      const formNewsCopy = formAssets && formAssets.rsvp && formAssets.rsvp.newslettercopy ? formAssets.rsvp.newslettercopy : 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time'
      const promoCode = formAssets && formAssets.rsvp && formAssets.rsvp.listid ? formAssets.rsvp.listid : 'DWE028SMI0721WC0005'
      const locationVal = formAssets && formAssets.rsvp && formAssets.rsvp.locationval ? formAssets.rsvp.locationval : 0

      const formDate = formAssets && formAssets.rsvp && formAssets.rsvp.formdate ? formAssets.rsvp.formdate : ''
      const formLineLoc = formAssets && formAssets.rsvp && formAssets.rsvp.formlineloc ? formAssets.rsvp.formlineloc : ''
      const formLoc = formAssets && formAssets.rsvp && formAssets.rsvp.formloc ? formAssets.rsvp.formloc : ''
      const formSubLoc = formAssets && formAssets.rsvp && formAssets.rsvp.formsubloc ? formAssets.rsvp.formsubloc : ''

      const formDisclaimer = formAssets && formAssets.rsvp && formAssets.rsvp.formdisclaimer ? formAssets.rsvp.formdisclaimer : ''
      return (
      	<>
      		<section className="rsvpForm" >

    		    <div className="leftPanel" id="leftPanel">
  		        <div className="inner">
                <div className="partner">
                  <div className="partner__block intro image">
                    <div className="intro__date">
                      {formDate}
                    </div>
                    <div className="intro__location">
                      <span>{formLineLoc}</span>
                    </div>
                    <h1>
                      {formLoc}<br/>
                      <span className="gold_font">{formSubLoc}</span>
                    </h1>
                  </div>
                </div>
                <div className="copy" dangerouslySetInnerHTML={{
                  __html: `${formCopy}`
                  }}
                />
		            <h2 className="heading" dangerouslySetInnerHTML={{
                  __html: `${formTitle}`
                  }}
                />
  		        </div>
    		    </div>

    		    <div className="rightPanel">
  		        <div className="inner">
  		            <div className="formWrapper" id="formWrapper">
  		                <form id="formEnterCode" runat="server" action="/api/CIDBapi_Form">
  		                    <input id="PR_4_367_1_freetext" name="PR_4_367_1_freetext" type="hidden" placeholder="" runat="server"value="" />
  		                    <input id="HD_PromoCode" name="HD_PromoCode" runat="server" type="hidden" value={promoCode} />

  		                    <input id="HD_Consumerid" name="HD_Consumerid" runat="server" type="hidden" value="" />

  		                  <div className="inputWrapper half">
  		                    <label htmlFor="CP_FirstName">FIRST NAME*</label>
  		                  	<input id="CP_FirstName" name="CP_FirstName" type="text" required />
  		                  </div>

  		                  <div className="inputWrapper half">
  		                    <label htmlFor="CP_LastName">LAST NAME*</label>
  		                  	<input id="CP_LastName" name="CP_LastName" type="text" required />
  		                  </div>

  		                  <div className="inputWrapper half">
  		                    <label htmlFor="CP_EmailId">EMAIL*</label>
  		                  	<input id="CP_EmailId" name="CP_EmailId" type="email" required />
  		                  </div>

  		                  <div className="inputWrapper half">
  		                  	<label htmlFor="CP_DOB">DATE OF BIRTH*</label>
                          <input id="CP_DOB" name="CP_DOB" type="date" required />
                              <div id="dob__error" className="dob__error">
                            Please enter a valid birthdate.
                              </div>
  		                  </div>
                        <div className="inputWrapper half">
                          <label htmlFor="PR_4_324_1_freetext">ZIP CODE*</label>
                          <input id="PR_4_324_1_freetext" name="PR_4_324_1_freetext" type="text" required />
                        </div>

  		                  <div className="inputWrapper half">
                          <label htmlFor="PR_4_466_1_freetext">STATE*</label>
                          <select name="PR_4_466_1_freetext" id="PR_4_466_1_freetext" className="input w-input" required>
                              <option selected disabled value="">State*</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                          </select>
                        </div>

                        <div className="inputWrapper half">
                          <label htmlFor="location">RSVP LOCATION*</label>
                          <select name="location" id="location" required>
                            <option disabled selected value="">Select Location *</option>
                            <option value="jamaicaplain">JAMAICA PLAIN FOR 9/14</option>
                          </select>
                        </div>

                        <div className="inputWrapper half">
                          <label htmlFor="plusone">WILL YOU BE BRINGING A PLUS 1</label>
                          <select name="plusone" id="plusone" required>
                            <option disabled selected value="">WILL YOU BE BRINGING A PLUS 1 *</option>
                            <option value="true">YES</option>
                            <option value="false">NO</option>
                          </select>
                        </div>

                        <div className="inputWrapper checkbox">
  		                  	<input id="tc" name="tc" type="checkbox" required />
  		                  	<label htmlFor="tc" dangerouslySetInnerHTML={{
                            __html: `${formTermsCopy}`
                            }}
                          />
  		                  </div>
                        
  		                  <div className="inputWrapper checkbox">
  		                  	<input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" required />
  		                  	<label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                            __html: `${formNewsCopy}`
                            }}
                          />
  		                  </div>

  		                    <div className="inputWrapper">
  		                        <div id="recaptcha"></div>
  		                    </div>

  		                    <div className="inputWrapper">
  		                        <div className="errorMessage" id="errorMessage"></div>
  		                    </div>

  		                  <div className="inputWrapper">
                          <input id="PR_4_92_1" name="PR_4_92_1" runat="server" type="hidden" value={locationVal} />
	                        <input id="HD_IsDefaultFlag" name="HD_IsDefaultFlag" runat="server" type="hidden" value="1" />
	                        <input id="HD_PR_1_64_1_Brand" name="HD_PR_1_64_1_Brand" runat="server" type="hidden" value="4" />
	                        <input id="HD_PR_1_64_1_Comm" name="HD_PR_1_64_1_Comm" runat="server" type="hidden" value="1" />
	                        <input id="HD_AddressType" name="HD_AddressType" runat="server" type="hidden" value="1" />

  		                  	<input className="submit" type="submit" value="SUBMIT" id='cidbSubmit' onClick={(evnt) => this.registerUser(evnt)} />
  		                  </div>
                        <div className="inputWrapper">
                          <a href="/en-us/a-taste-of-the-tropics" className="">
                            Cancel and go back
                          </a>
                        </div>
                        {formDisclaimer && formDisclaimer !== '' &&
                          <div className="inputWrapper copy disclaimer">
                            <small dangerouslySetInnerHTML={{
                            __html: `${formDisclaimer}`
                            }}
                          />
                          </div>
                        }
  		                </form>
  		            </div>

  		        </div>
    		    </div>

      		</section>
      	</>
      );

    }

}

export default RsvpForm;
