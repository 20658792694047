import "./product-list.scss";
import React, {Component} from "react";
import FilterComponent from "./filters";
import ProductGrid from "./product-grid";
import buildProductsJson from "./build-products-json";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const updateTags = (products) => {
    const location = window.location.pathname
    const locationArray = location.split('/')
    let locale = 'en-us'

    if(locationArray.length > 1) {
      locale = locationArray[1]
    }
    
    let updatedProducts = products.map((product)=> {
      const tagArray = product.Tags.map((tag) => {
        if(tag.replace(' ', '').toLowerCase() === 'allproducts') {
          tag = checkTag(locale)
        }
        return tag
      })
      
      product.Tags = tagArray
      return product
    })
    return updatedProducts
  }
const checkTag = (locale) => {
    let tagUpdated = ''
    switch(locale) {
      case 'cs-cz':
        tagUpdated = "Všechny produkty"
        break;
      case 'en-ca':
        tagUpdated = "All Products"
        break;
      case 'en-za':
        tagUpdated = "All Products"
        break;
      case 'en-gb':
        tagUpdated = "All Products."
        break;
      case 'en-row':
        tagUpdated = "All Products"
        break;
      case 'en-us':
        tagUpdated = "All Products"
        break;
      case 'fr-ca':
        tagUpdated = "Tous les produits"
        break;
      case 'de-de':
        tagUpdated = "Alle Produkte"
        break;
      case 'es-mx':
        tagUpdated = "Todos los productos"
        break;
      default:
        break;
    }
    return tagUpdated
  }
const getProducts = (fields) => {

    const products = fields
        .find((field) => field.title === "Products");

    const tmp = [];

    products.fields.forEach((el) => {

        const {
            CardTitle,
            Url,
        } = fieldsArrayToKeyValueHash(el.fields);

        if (!Url || !CardTitle) {

            return;

        }

        el.title = "ProductCard";
        tmp.push(el);

    });

    return buildProductsJson(tmp);

};

const getFilters = (fields) => {

    const {Filters} = fieldsArrayToKeyValueHash(fields);
    return Filters.split(",");

};

export default class ProductList extends Component {

    state = {
        allFilters: getFilters(this.props.fields),
        allProducts: typeof window !== 'undefined'? updateTags(getProducts(this.props.fields)) :getProducts(this.props.fields),
        currentFilter: null,
        filteredProducts:  typeof window !== 'undefined'? updateTags(getProducts(this.props.fields)) :getProducts(this.props.fields),
    };

    render () {
        const {...props} = fieldsArrayToKeyValueHash(this.props.fields);
        const filters = props.Filters.split(",");
        if (this.state.filteredProducts === null) {

            return null;

        }

        return (
            <section className="product-list">
                <FilterComponent
                    filters={filters}
                    updateFilter={this.updateFilters}
                />
                <header className="product-list__header">
                    {this.state && this.state.currentFilter &&
                      <h2 className="product-list__title">
                          {this.state.currentFilter}
                      </h2>
                    }
                    <p className="product-list__total">
                        {`${this.state.filteredProducts.length} ${this.getProductPluralizationText()}`}
                    </p>
                </header>
                <nav className="product-list__items">
                    <ProductGrid
                        products={this.state.filteredProducts}
                        selectedFilter={this.state.currentFilter}
                    />
                </nav>
            </section>
        );

    }

    updateFilters = (index) => {

        this.setState({currentFilter: this.state.allFilters[index]});
        this.filterProducts(this.state.allFilters[index]);

    };

    filterProducts = (filter) => {

        const {allProducts} = this.state;
        const productMatchesCurrentFilter = (product) => product.Tags.indexOf(filter) !== -1;

        const filtered = allProducts.filter(productMatchesCurrentFilter);
        this.setState({filteredProducts: filtered});

    };

    getProductPluralizationText () {

        const {
            MultipleResultFormat,
            SingleResultFormat,
        } = fieldsArrayToKeyValueHash(this.props.fields);

        if (this.state.filteredProducts.length === 1) {

            return SingleResultFormat;

        }

        return MultipleResultFormat;

    }

}

