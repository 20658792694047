/* eslint-disable class-methods-use-this */
import "./app-error.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import renderElement from "global/services/render-element";

export default class AppError extends React.Component {

    render () {

        const {message} = this.props;
        const localePattern = /([a-z]{2})\-?([a-z]{2})?/;
        let countryCode
        let isLocale = false
        if(window) {
         countryCode = window.location.pathname.split("/")[1] 
         isLocale = localePattern.test(countryCode);
        }

        let locale ="en" 
        if(isLocale) {
            locale = countryCode
        }
        let defaultMeta =[
        {
            "fieldType": "Plain Text",
            "title": "MetaPageTitle",
            "value": "This site is currently unavailable | Captain Morgan ",
            "fields": []
        },
        {
            "fieldType": "Plain Text",
            "title": "MetaDescription",
            "value": "THIS SITE IS CURRENTLY UNAVAILABLE - COME BACK SOON.",
            "fields": []
        },
        {
            "fieldType": "Plain Text",
            "title": "MetaKeywords",
            "value": "",
            "fields": []
        },
        {
            "fieldType": "Plain Text",
            "title": "MetaLanguage",
            "value": locale,
            "fields": []
        },
        {
            "fieldType": "Plain Text",
            "title": "MetaPageStatus",
            "value": "500",
            "fields": []
        }
    ]

        return (
            <div className="app-error">
              { renderElement('Meta', {fields: defaultMeta}, '0')}
                <div className="app-error__icon">
                    <FontAwesomeIcon
                        icon={[
                            "far",
                            "sad-tear",
                        ]}
                    />
                    <span className="app-error__icon-alt">Error</span>
                </div>
                {
                    message &&
                        <h1 className="app-error__title">{message}</h1>
                }
            </div>
        );

    }

}
