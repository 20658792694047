export const generateKey = (maxlength, charlist) => {
  console.log('GENERATE KEY')
  let useLength = 0
  let useList = ''
  if (maxlength) {
    useLength = maxlength
  }

  if (charlist) {
    useList = charlist
  }

  const characters = useList !== '' ? useList : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const length = useLength && useLength !== 0 ? useLength : 15
  let randomStr = ''

  for (let i = 0; i < length; i++) {
    const randomNum = Math.floor(Math.random() * characters.length)
    randomStr += characters[randomNum]
  }

  return randomStr
}

export const gaSubmit = (data) => {
  console.log('gaSubmit', data)
  const wndw = window
  
  wndw.dataLayer = wndw.dataLayer || []
    wndw.dataLayer.push(data)
}