export const initClaimed = () => {
  const localSetting = window.localStorage.getItem('claimed')
  if (localSetting) {
    setHasClaimed(false)
  }else{
    setHasClaimed(false)
  }
  console.log('IN GET HAS CLAIMED >>>> ', window.localStorage.getItem('claimed'))
};

export const getClaimed = () => {
  const localSetting = window.localStorage.getItem('claimed')
  if (localSetting) {
    return localSetting
  }else{
    return false
  }
}

export const setHasClaimed = (val) => {
  window.localStorage.setItem('claimed', val)
}
