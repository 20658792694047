import "./content-tiles.scss";
import ContentTile from "../../molecules/content-tile";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const getImageUrl = (image) => {

    const cardImage = fieldsArrayToKeyValueHash(image);

    if (cardImage.Url) {

        return cardImage.Url;

    }

    return "";

};

const renderTileElement = (card, position) => {
    const {
        CardImage,
        CardTitle,
        CardCtaText,
        Url,
        NewWindow,
    } = fieldsArrayToKeyValueHash(card.fields);

    let cardImageUrl = "";

    if (CardImage) {

        cardImageUrl = getImageUrl(CardImage);

    }

    if (
        !cardImageUrl ||
        !CardTitle ||
        !Url
    ) {

        return null;

    }

    return (
        <li
            key={`card-${position}`}
            className="content-tiles__list-item"
        >
            <ContentTile
                image = {cardImageUrl}
                heading = {CardTitle}
                ctaText = {CardCtaText}
                url = {Url}
                newWindow = {NewWindow}
            />
        </li>
    );

};

const ContentTiles = (props) => {

    const cards = props.fields;
    let showCards = false

    if (!cards || cards.length <= 0) {

        return null;

    }
    cards.forEach( (card, index) => {
      const cardField = card && card.fields ? card.fields : []

      if(cardField.length > 0) {
        const {
            CardImage,
            CardTitle,
            CardCtaText,
            Url,
            NewWindow,
        } = fieldsArrayToKeyValueHash(card.fields);
        let cardImageUrl = "";

        if (CardImage) {

            cardImageUrl = getImageUrl(CardImage);

        }
        if (
            !cardImageUrl ||
            !CardTitle ||
            !Url
        ) {
          showCards = false
        }else{
          showCards = true
        }
      }else{
        if(showCards === true){
          showCards = true
        }else{
          showCards = false
        }
      }
    })
    if(showCards === false) {
      return null
    }
    return (
        <nav className="content-tiles">
            <div className="content-tiles__inner">
                <ul className="content-tiles__list">
                    {
                        cards.map((card, index) => renderTileElement(card, index))
                    }
                </ul>
            </div>
        </nav>
    );

};

export default ContentTiles;
