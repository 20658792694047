import "./style.scss";
import React from "react";

import footerFBLogo from 'global/images/ftc2024/facebook.png'
import footerIGLogo from 'global/images/ftc2024/IG.png'
import footerTWLogo from 'global/images/ftc2024/TW.png'
import footerYTLogo from 'global/images/ftc2024/YT.png'

const Footer = (props) => {
  return (
    <footer className="page__footer" role="contentinfo" id="ftcFooter">
      <aside className="follow-us">
        <div className="follow-us__inner">
            <h2 className="follow-us__title">Follow Captain Morgan</h2>
            <div className="follow-us__strapline">Be the first to know about events, promotions and competitions</div>
            <ul className="follow-us__options">
              <li className="follow-us__option">
                  <a className="follow-us__option-link" href="https://www.facebook.com/CaptainMorganUSA/?brand_redir=138940200780" target="_blank" rel="noopener noreferrer">
                    <div className="styled-icon">
                      <img
                        aria-hidden
                        height="75px"
                        loading="lazy"
                        src={footerFBLogo}
                        width="75px"
                      />
                    </div>
                    <span className="follow-us__option-link-alt">Facebook</span>
                  </a>
              </li>
              <li className="follow-us__option">
                  <a className="follow-us__option-link" href="https://www.instagram.com/captainmorganusa/" target="_blank" rel="noopener noreferrer">
                    <div className="styled-icon">                         
                        <img
                          aria-hidden
                          height="75px"
                          loading="lazy"
                          src={footerIGLogo}
                          width="75px"
                        />
                    </div>
                    <span className="follow-us__option-link-alt">Instagram</span>
                  </a>
              </li>
              <li className="follow-us__option">
                  <a className="follow-us__option-link" href="https://twitter.com/CaptainMorganUS" target="_blank" rel="noopener noreferrer">
                    <div className="styled-icon">
                      <img
                        aria-hidden
                        height="75px"
                        loading="lazy"
                        src={footerTWLogo}
                        width="75px"
                      />
                    </div>
                    <span className="follow-us__option-link-alt">Twitter</span>
                  </a>
              </li>
              <li className="follow-us__option">
                  <a className="follow-us__option-link" href="https://www.youtube.com/user/CaptainMorgan" target="_blank" rel="noopener noreferrer">
                    <div className="styled-icon">
                      <img
                        aria-hidden
                        height="75px"
                        loading="lazy"
                        src={footerYTLogo}
                        width="75px"
                      />
                    </div>
                    <span className="follow-us__option-link-alt">YouTube</span>
                  </a>
              </li>
              
            </ul>
        </div>
      </aside>        
      <div className="footer__disclaimer">
        <div className="footer__rules">
          <a  href="/captain-morgan-nfl-rules.pdf">Official Rules</a>
        </div>
        <div className="copy">
          <strong>
              CAPTAIN MORGAN ORIGINAL SPICED RUM. Caribbean Rum Made with Vanilla, Spice, and Other Natural Flavors. 35% Alc./Vol. Captain Morgan Rum Co., New York, NY. Please Drink Responsibly. Do not forward to anyone under the age of 21.
          </strong>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
