import "./social-card.scss";
import {getIconName, getPrefix} from "global/utils/fontAwesomeLookup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Lazy from "global/utils/lazy";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const trimHeading = (heading) => {

    const length = 55;
    const ending = "...";

    if (heading && heading.length > length) {

        return heading.substring(0, length - ending.length) + ending;

    }

    return heading;

};

const getIcon = (icon) => {

    if (!icon) {

        return null;

    }

    const iconName = getIconName(icon);

    return <FontAwesomeIcon icon={{
        iconName,
        prefix: getPrefix(iconName),
    }} />;

};

const SocialCard = (props) => {

    const {
        ImageName,
        ImagePostLink,
        ImagePostCommentCount,
        ImagePostLikeCount,
        ImageSource,
    } = fieldsArrayToKeyValueHash(props.fields);
    const CardType = props.title;

    if (!ImagePostLink) {

        return null;

    }

    if (CardType === "ImageData") {

        return (

            <Lazy
                Tag="div"
                containerClassName="social-card"
            >
                <a
                    className="social-card__action"
                    href={ImagePostLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div
                        className="social-card__background"
                        style={{backgroundImage: `url(${ImageSource})`}}
                    />
                    {
                        ImageName &&
                            <h3
                                className="social-card__title"
                            >
                                <span className="social-card__title-copy">
                                    {trimHeading(ImageName)}
                                </span>
                            </h3>
                    }
                    {
                        ImagePostLikeCount && ImagePostCommentCount &&
                            <div className="social-card__details">
                                <div className="social-card__details-copy">
                                    {
                                        ImagePostLikeCount &&
                                            <div className="social-card__detail social-card__detail--likes">
                                                <span className="social-card__detail-icon">
                                                    {getIcon("thumbs-up")}
                                                </span>
                                                <span className="social-card__detail-count">
                                                    {ImagePostLikeCount}
                                                </span>
                                            </div>
                                    }
                                    {
                                        ImagePostCommentCount &&
                                            <div className="social-card__detail social-card__detail--comments">
                                                <span className="social-card__detail-icon">
                                                    {getIcon("comment")}
                                                </span>
                                                <span className="social-card__detail-count">
                                                    {ImagePostCommentCount}
                                                </span>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                    <div className="social-card__icon">
                        {getIcon("facebook")}
                    </div>
                </a>
            </Lazy>

        );

    }

    return null;

};

export default SocialCard;
