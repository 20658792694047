/* eslint-disable no-case-declarations */
import * as Actions from "actions/content";
import {handleActions} from "redux-actions";

export default handleActions({
    [Actions.fetchContent]: (state, action) => ({
        ...state,
        pages: {
            ...state.pages,
            [action.payload.page.name]: {isFetching: true},
        },
    }),
    [Actions.contentResponse]: (state, action) => ({
        ...state,
        pages: {
            ...state.pages,
            [action.payload.page.name]: {
                contents: action.payload.data.contents,
                error: false,
                fetchedData: true,
                isFetching: false,
            },
        },
    }),
    [Actions.contentError]: (state, action) => ({
        ...state,
        pages: {
            ...state.pages,
            [action.payload.page.name]: {
                error: true,
                fetchedData: false,
                isFetching: false,
            },
        },
    }),
    [Actions.setShopalystStatus]: (state, action) => ({
        ...state,
        isShopalystActive: action.payload.active,
    }),
}, {});

