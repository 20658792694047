import "./content-tile.scss";
import {BlockingLink} from "global/utils/blocking-link";
import {CtaComponent} from "../../atoms/cta";
import Lazy from "global/utils/lazy";
import React from "react";

const ContentTile = (props) => {

    const {
        ctaText,
        heading,
        image,
        newWindow,
        url,
    } = props;

    if (
        !image ||
        !heading ||
        !url
    ) {

        return null;

    }

    const linkTarget = newWindow
        ? "_blank"
        : "_self";
    const altText = props && props.alt ? props.alt : 'Image of ' + heading
    return (
        <div className="content-tile">
            <BlockingLink
                target={linkTarget}
                to={url}
                className="content-tile__link"
            >
                <Lazy
                    Tag="div"
                    containerClassName="content-tile__background"
                >
                    <div
                        className="content-tile__background-img"
                        style={{backgroundImage: `url(${image})`}}
                        role="img"
                        aria-label={altText}
                    />
                </Lazy>
                <div className="content-tile__content">
                    <h2 className="content-tile__heading">
                        {heading}
                    </h2>
                    <CtaComponent Caption={ctaText} />
                </div>
                <div className="content-tile__aspect-ratio-shim" />
            </BlockingLink>
        </div>
    );

};

export default ContentTile;
