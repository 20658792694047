import "./page-default.scss";
import React, {Component} from "react";
import AppError from "../../organisms/app-error";
import buildPage from "../page-builder";

export default class PageContainer extends Component {

    state = {
        "isLoaded": false,
        "setRoutes": false,
    };

    constructor (props) {

        super(props);
        this.fetchData();

    }

    getPageName () {

        const path = this.props.location.pathname
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'>/g, "&#x27;");

        if (path.endsWith("/")) {

            return path;

        }

        return `${path}/`;

    }

    fetchData () {

        const pageName = this.getPageName();

        if (!this.props.data[pageName]) {

            // No! Lots wrong here:
            // - whatabout fetching data?
            // - Transition should be blocked before
            //   We have the data to avoid rendering noop
            this.props.fetchData(
                {name: pageName},
                false
            );

        }

    }

    componentDidUpdate (prevProps) {

        const pageName = this.getPageName();
        const newLocation = this.props.location.pathname;
        const {data} = this.props;

        if (prevProps.location.pathname !== newLocation && !data[pageName]) {

            this.props.fetchData(
                {name: newLocation},
                false
            );

        }

    }

    render () {

        const {data} = this.props;
        const pageName = this.getPageName();

        if (!data[pageName] || data[pageName].isFetching) {

            // In future, avoid this!
            return null;

        }


        const page = buildPage(data, pageName);
       
        // console.log('page >>> ', page.headerComponents)
        // IF the API is unreachable
        // OR the page response is not as expected
        // BuildPage will return null
        // Without this the App will error server side and bring down the node server
        if (page === null) {

            return (
                <div className="page">
                    <AppError
                        message="This site is currently unavailable - Come back soon."
                    />
                </div>
            );


        }
        return (
            <div className={`page ${pageName.substring(1, pageName.length - 1)
                .replace(/\\|\//g, "-")}`}>
                {page.headerComponents}

                <main className="page__content" id="main" role="main">
                    {page.bodyComponents}
                </main>

                <footer className="page__footer" role="contentinfo">
                    {page.footerComponents}
                </footer>
            </div>
        );

    }

}
