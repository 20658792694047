/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import React from "react";
import {load} from "recaptcha-v3";
import {Helmet} from 'react-helmet'
import $ from 'jquery'
import * as storage from 'global/utils/storage'
import * as routeList from "global/utils/routing";
import HeaderBgMobile from 'global/images/de_de_comp_game/header_bg_mobile.jpg'
const cn = require('classnames');

class GameHeader extends React.Component {

    constructor (props) {

        super(props);
        this.findLink = "";
        this.state = {
          errorMessage: '',
          formError: false,
          gameAssets: routeList.getSectionAssets(this.props.location.pathname),
          headerImage: '',
          footerImage: ''
        }
        this.state.headerImage = this.state.gameAssets && this.state.gameAssets.gameheader && this.state.gameAssets.gameheader.headerbg ? this.state.gameAssets.gameheader.headerbg : ''
    }

    async submitCode (e) {
      e.preventDefault()
      console.log('form submit')
      const code = document.getElementById('formCode')
      const terms = document.getElementById('formCheck').checked
      const formData = new FormData(document.getElementById('formEnterCode'))
      
      //converts formdata to json
      const send = {}
      formData.forEach((value, key) => send[key] = value)

      this.updateErrorMessage('')
      this.state.formError = true
      const redirectTo = this.state && this.state.gameAssets && this.state.gameAssets.gamecode && this.state.gameAssets.gamecode.redirectUrl ? this.state.gameAssets.gamecode.redirectUrl : ''

      const data = {
        code: '',
        terms: false,
        location: this.props.location.pathname,
        captcha: send['g-recaptcha-response']
      }

      if(this.props.location && this.props.location.pathname) {
        const locSplit = this.props.location.pathname.split('/')
        let locSend = ''

        if(locSplit.length > 1) {
          locSend = locSplit[1]          
          data.location = locSend
        }
      }

      if(code) {
        data.code = code.value
        data.terms = terms
      }

      if(data.code !== '' && data.terms === true && data.captcha !== '' && redirectTo !== '') {
      // if(data.code !== '' && data.terms === true && redirectTo !== '') {
        this.state.formError = false
        this.state.errorMessage = ''
      }

      //do post request to api here
      if(!this.state.formError){        
        
        try {
          const res = await fetch('/api/claim/find', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify(data)
          })
          
          const resData = await res.json()
          const status = res.status

          if (status && status === 200) {
            console.log('SUCCESS Add ANS', res)
            storage.setHasClaimed(true)
           
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event':'customEvent',
              'eventCategory': 'DigitCode',
              'eventAction': 'Enter',
              'eventLabel': code.value,
              'eventInteraction': 'true'
            });
            window.location.href = redirectTo
          } else {
            const message = resData.message
            this.updateErrorMessage(`<div>${message}</div>`)
            this.state.formError = true
            // return res
          }
        } catch (error) {
          console.log('ERROR ERROR ANS', error)
          return 0
        }
      }else{
        let errorMsg = ''
        if(data.code === '') {
          errorMsg = '<div>Please enter a code.</div>'
        }

        if(data.terms === false) {
          errorMsg += '<div>Please accept the terms and conditions and Privacy & Cookie notice.</div>'
        }

        if(redirectTo === '') {
          errorMsg = '<div>Missing URL. Please contact an administrator.</div>'
        }

        if(data.captcha === '') {
          errorMsg += '<div>Please answer the Captcha.</div>'
        }

        this.updateErrorMessage(errorMsg)
      }
    }

    updateErrorMessage (message) {
      const errorDiv = document.getElementById('errorMessage')
      errorDiv.innerHTML = message
      this.state.errorMessage = message
    }

    captchaLoad = () => {
      load('6Lf5-30cAAAAAAhYAb4XHcLZFh-8sj_OHetPhcvt', {
        explicitRenderParameters: {
          container: 'recaptcha'
        }
      })
    }

    componentDidMount () {
      const headerImage = this.state.headerImage
      $(function() {
        $(window).on('resize', function(){
          var winWidth = $(this).innerWidth()

          if(winWidth < 768) {
            $('#headerSection').css({backgroundImage: `url(${HeaderBgMobile})`})
          }else{
            $('#headerSection').css({backgroundImage: `url(${headerImage})`})
          }
        })
      })
      //JS goes here
      // window.addEventListener('load', this.captchaLoad())
    }

    render () {
      const addClass = this.props.loc ? this.props.loc : ''
      const gameAssets = routeList.getSectionAssets(this.props.location.pathname)
      // const gameHero = gameAssets && gameAssets.gameheader && gameAssets.gameheader.gamehero ? gameAssets.gameheader.gamehero : ''
      // const gameVideo = gameAssets && gameAssets.gameheader && gameAssets.gameheader.gameherovid ? gameAssets.gameheader.gameherovid : ''
      const enterHeaderBg = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headerbg ? gameAssets.gameheader.headerbg : ''
      const enterHeaderTime = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headertime ? gameAssets.gameheader.headertime : ''
      const enterHeaderHand = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headerhand ? gameAssets.gameheader.headerhand : ''
      const enterHeaderCopy = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headercopy ? gameAssets.gameheader.headercopy : "can you beat the captain's record?"
      const enterHeaderSubCopy = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headersubcopy ? gameAssets.gameheader.headersubcopy : ''
      const enterHeaderEnterCopy = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headerentercopy ? gameAssets.gameheader.headerentercopy : "enter your code here:"
      const enterHeaderPlaceholderText = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headercodeplaceholdertext ? gameAssets.gameheader.headercodeplaceholdertext : "8-DIGIT CODE *"
      const enterHeaderSubmitText = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headerentersubmittext ? gameAssets.gameheader.headerentersubmittext : "Submit"
      const enterNextHand = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headernextimage ? gameAssets.gameheader.headernextimage : ''
      const enterHeaderNextCopy = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headernextcopy ? gameAssets.gameheader.headernextcopy : "didn't get your code yet matey?"
      const enterHeaderFaqUrl = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headerfaqurl && gameAssets.gameheader.headerfaqurl !== '' ? gameAssets.gameheader.headerfaqurl : "#"
      const enterHeaderFaqText = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headertnccopy ? gameAssets.gameheader.headertnccopy : "FAQ & TNCS"
      const termsCopy = gameAssets && gameAssets.gameheader && gameAssets.gameheader.formterms ? gameAssets.gameheader.formterms : '* I accept the terms and conditions and Privacy &amp; Cookie notice.'
      const enterHeaderAnimation = gameAssets && gameAssets.gameheader && gameAssets.gameheader.headeranimation ? gameAssets.gameheader.headeranimation : ""
      let addSectionClass = ''

      let addStyle = {
        backgroundImage: `url(${enterHeaderBg})`
      }

      if(addClass !== 'start') {
        addStyle = {}
      }

      if(addClass == 'start') {
        addSectionClass = 'enterHeader'
      }

      return (
          <section className={cn('HeadlineSection', addClass, addSectionClass)} style={addStyle} id='headerSection'>
            <Helmet>
              <meta charset="UTF-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              
              <title>Captian Morgan Digital Escape Room | Captain Morgan</title>
              <meta name="description" content="" />
              <meta name="keywords" content="" />
              
              <meta property="og:title" content="" />
              <meta property="og:description" content="" />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="" />

              <meta property="og:image" content="" />

              <meta name="twitter:title" content="" />
              <meta name="twitter:description" content="" />
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:url" content="" />

              <meta name="twitter:image" content="" />
            </Helmet>
            {/*addClass === 'start' &&
              <div className="headlineHero">
                <video className="headlineHero__video" muted autoPlay>
                    <source  src={gameHero}
                            type="video/webm"/>
                    <source src={gameVideo}
                            type="video/mp4"/>
                    Sorry, your browser doesn't support embedded videos.
                </video>
                  {/* <img src={gameHero} alt='Captian Morgan Competition hero image' /> }
              </div>
            */}
            {this.props.location.pathname === "/escapegamebelgiumdt/enter-code/" &&     
              <div className="lang-selector">
                <a href="/escapegamebelgiumfr/enter-code/">Français</a>
              </div>
            }
             {this.props.location.pathname === "/escapegamebelgiumfr/enter-code/" &&     
              <div className="lang-selector">
                <a href="/escapegamebelgiumdt/enter-code/">Dutch</a>
              </div>
            }
            {this.props.location.pathname === "/escapegamegermany/eintreten/" &&     
              <div className="lang-selector">
                <a href="/escapegameslovakia/enter-code/">Prepni na slovenskú verziu</a>
              </div>
            }
            {addClass === 'start' &&
              <div className="container">
                <div className="inner">
                  {enterHeaderAnimation && enterHeaderAnimation !== '' &&
                    <div className='enterHeader__animation enterHeader__block'>
                      <img src={enterHeaderAnimation} alt='Game Animation' />
                    </div>
                  }
                  {enterHeaderAnimation === '' &&
                    <div className='enterHeader__time enterHeader__block'>
                      {enterHeaderTime !== '' &&
                        <div className='enterHeader__image enterHeader__block'>
                          <img src={enterHeaderTime} alt='Game Time Image' />
                        </div>
                      }
                      <div className='enterHeader__copy enterHeader__block'>
                        <h1 className='hero__title gold_font'>
                          {enterHeaderCopy}
                        </h1>
                      </div>
                    </div>
                  }
                  <div className='enterHeader__code enterHeader__block'>
                    <h2 className='enterHeader__title'>
                      {enterHeaderEnterCopy}
                    </h2>
                    <div className="formWrapper">
                      <form id="formEnterCode" runat="server" action="/api/CIDBapi_Form.php" method="post">

                        <div className="inputWrapper formCode">
                          <div className='formCode__wrap'>
                            <input id="formCode" name='formCode' placeholder={enterHeaderPlaceholderText} type="text"  maxLength="8" required />
                            <input className="cta" type="submit" value={enterHeaderSubmitText} onClick={(e)=>this.submitCode(e)} />
                          </div>
                        </div>
                        {enterHeaderSubCopy && enterHeaderSubCopy != '' &&
                          <div className='inputWrapper formCode__subcopy'>
                            <h3 className='enterHeader__subcopy'>
                              {enterHeaderSubCopy}
                            </h3>
                          </div>
                        }
                        <div className="inputWrapper checkbox">
                          <input id="formCheck" name='formCheck' type="checkbox" required />
                          <label htmlFor="formCheck" dangerouslySetInnerHTML={{
                            __html: `${termsCopy}`
                            }}
                          />
                        </div>
                        <div className='inputWrapper formCode__subcopy'>
                            <a href={enterHeaderFaqUrl} target='_blank'>
                              <h3 className='enterHeader__subcopy'>
                                {enterHeaderFaqText}
                              </h3>
                            </a>
                          </div>

                        <div className='inputWrapper'>
                          <div id="recaptcha"></div>
                        </div>

                        <div className='inputWrapper'>
                          <div className='errorMessage' id='errorMessage'></div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='enterHeader__next enterHeader__block'>
                    {enterNextHand !== '' &&
                      <a href="#gameRules" className='enterHeader__nextlink animateUpDown scrollTo'>
                        <img src={enterNextHand} alt='Header Click Link' />
                      </a>
                    }
                    <h3 className='enterHeader__title'>
                      <a href="#gameRules" className='enterHeader__nextlink scrollTo'>
                        {enterHeaderNextCopy}
                      </a>
                    </h3>
                    
                  </div>
                </div>
              </div>
            }
            {addClass === 'play' &&
              <div className="container">
                  {/*<h1 className="hero__title gold_font">HEADLINE TEXT</h1>*/}
              </div>
            }
          </section>
      );

    }

}

export default GameHeader;
