import "./product-hero.scss";
import React, {Component} from "react";
import Cta from "../../../components/atoms/cta";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

import barLogo from 'global/images/the-bar-logo-black.svg'

export default class ProductHero extends Component {

    render () {

        const {
            CtaCollection,
            HeroBackgroundColour,
            HeroImage,
            HeroTitle,
            HeroSummary,
            DrinkResponsiblyCtaTitle,
            DrinkResponsiblyCtaUrl,
            DrinkResponsiblyCtaTarget,
            DrinkResponsiblyCtaText,
        } = fieldsArrayToKeyValueHash(this.props.fields);

        if (!HeroTitle) {

            return null;

        }
        console.log(CtaCollection)
        const heroImage = fieldsArrayToKeyValueHash(HeroImage);
        const altText = heroImage && heroImage.Alt && heroImage.Alt !== '' ? heroImage.Alt : 'Image of ' + HeroTitle
        return (
            <header
                className="product-hero"
                style={{backgroundColor: `#${HeroBackgroundColour}`}}
            >
                {/*<img
                    className="hero__accent"
                    src={require("../../../global/images/backgrounds/gold-1.png")}
                />*/}
                <div className="product-hero__inner">

                    { heroImage.Url &&
                        <div className="product-hero__visual">
                            <img
                                className="product-hero__img"
                                src={`${heroImage.Url}`}
                                alt={altText}
                            />
                        </div>
                    }

                    <div className="product-hero__header" >
                        <h1 className="product-hero__title">
                            {HeroTitle}
                        </h1>
                    </div>

                    <div className="product-hero__copy">

                        { HeroSummary &&
                            <div
                                className="product-hero__summary"
                                dangerouslySetInnerHTML={{__html: sanitise(HeroSummary)}}
                            />
                        }

                        { CtaCollection &&
                            <nav className="product-hero__actions" style={{display:"flex"}}>
                                {
                                    CtaCollection.map((cta, index) =>{ 
                                        const barLink = cta.fields.find((element) => element.value ==="Buy at The Bar")
                                        if(barLink) {
                                             const barLink = cta.fields.find((element) => element.title ==="Link")
                                            return (
                                               <a key={`cta-${index}`} className="cta bar-cta" target="_blank" href={barLink.value}>Buy at <img src={barLogo} alt="The Bar" /></a>
                                            )
                                        } else {
                                            return (
                                                <Cta
                                                    key={`cta-${index}`}
                                                    cta={cta}
                                                    version="product-promo"
                                                    varient={index && "ghost"}
                                                />)
                                        }
                                       
                                })
                                }
                            </nav>
                        }

                    </div>

                    <aside className="product-hero__legal">
                        { DrinkResponsiblyCtaTitle &&
                            <strong className="product-hero__legal-text">
                                {DrinkResponsiblyCtaTitle}
                            </strong>
                        }
                        { DrinkResponsiblyCtaUrl && DrinkResponsiblyCtaText &&
                            <a className="product-hero__legal-link"
                                href={DrinkResponsiblyCtaUrl}
                                target={DrinkResponsiblyCtaTarget}
                            >
                                {DrinkResponsiblyCtaText}
                            </a>
                        }
                    </aside>

                </div>
            </header>
        );

    }

}
