import React, { useState } from 'react'

const State = (props) => {
  const { errorMsg,
  register,
  labelStyle,
  half,
  setValue, } = props
  
  const [select, setSelect] = useState('')
  const [focus, setFocus] = useState(false)

  return (
    <div className="fieldwrap w-full">
      <div className="inputWrapper labeled relative w-full">
        <label
          htmlFor="00N4v00000C2nYa"
          className={`inputlabel inputlabel--empty pointer-events-none absolute ml-3 mt-4 cursor-text uppercase transition-all duration-500 md:mt-0 ${
            focus
              ? `${
                  labelStyle === 'inside'
                    ? '-left-1 -top-3 md:left-0 md:top-0'
                    : 'bg-form -top-5 left-1 md:-top-1 md:left-3'
                } text-[.656rem] text-black md:text-[.7878rem]`
              : `${labelStyle === 'inside' ? 'top-0 md:top-3' : 'top-0 md:top-5'} left-2 text-2xl  text-black/40  ${
                  select !== '' && 'opacity-0'
                }`
          }`}
        >
          State*
        </label>
        <select
          id="00N4v00000C2nYa"
          className={`formInput input webkit-none font-futura w-full border-2 border-black py-3 px-5 text-2xl uppercase text-black outline-[#F7941D] ${
            labelStyle === 'inside' ? 'bg-formInput py-3 md:py-4' : 'bg-form py-2 md:py-3'
          } w-full rounded-none`}
          onFocus={() => {
            setFocus(true)
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          {...register}
          onChange={(e) => {
            setSelect(e.target.value)
            setValue('00N4v00000C2nYa', e.target.value)
          }}
        >
          <option defaultValue="State"></option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>
      <span id="00N4v00000C2nYaError" role="alert" className="mx-auto w-full text-center text-sm text-red-600">
        {errorMsg}
      </span>
    </div>
  )
}

export default State
