const fas = [
    "comment",
    "envelope",
    "thumbs-up",
    "map-marker-alt",
];

const getPrefix = (iconName) => {

    if (fas.includes(iconName)) {

        return "fas";

    }

    return "fab";

};

const iconNameLookup = {
    email: "envelope",
    facebook: "facebook-f",
    pinterest: "pinterest-p",
    twitter: "twitter",
};

const getIconName = (icon) => {

    const cleanedIcon = icon
        .trim()
        .toLowerCase()
        .replace(" ", "-");

    return iconNameLookup[cleanedIcon] || cleanedIcon;

};

export {getIconName, getPrefix};
