import "./modal-nfl.scss";
import "../../organisms/RsvpForm/style.scss";
import React, { useEffect,useState } from "react";
import fetch from "isomorphic-fetch";
import moment from "moment";
import $ from 'jquery'
import {load} from "recaptcha-v3";

import ContentGallery from "./content-gallery";

const cn = require('classnames')
import prizeJersey from 'global/images/comp2023/prize_jersey_modal.jpg'
import hintImage from 'global/images/comp2023/clue_image_3-5-mobile.png'

const ModalNfl = (props) => {
  const modalType = props && props.type ? props.type : ''
  const apiUrl = props && props.url ? props.url : ''
  const successPrize = props && props.data && props.data.prize ? props.data.prize : ''
  const galleryType = props && props.data && props.data.type ? props.data.type : ''
  let prize = ""
  const [date, setDate] = useState("");
  function checkAge (dob) {

    /* [SEARCH-MINIMUM-AGE] */
    const birthday = moment(dob);

    if (moment().diff(birthday, "years", false) < 21) {
      return true
    } else {
      return false
    }
  }
 
  // eslint-disable-next-line complexity
  async function formSubmit(event) {
    let formError = false
    let errorMessage = ''
    let ageError = false
   
    $('#cidbSubmit').prop('disabled', true).addClass('disabled')
    $('#errorMessage').html('')
    // eslint-disable-next-line func-names
    $(".error").each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        $(this).removeClass("error");
    });

    event.preventDefault()

    const fname = $("#CP_FirstName").val();
    const lname = $("#CP_LastName").val();
    const email = $("#CP_EmailId").val();
    const dob =  moment($("#CP_DOB").val()).format("YYYY-MM-DD")
    const city = $("#CP_City").val();
    const zip = $("#CP_ZipCode").val();
    const phone = $("#CP_PhoneNumber").val();
    const state = $("#PR_4_466_1_freetext").find(":selected").val();
    const address1 = $("#CP_Address1").val();
    const address2 = $("#CP_Address2").val();
    const tc = $("#tc").prop("checked");
     const rules = $("#rules").prop("checked");
    const checkboxFields = $("#PR_1_64_1").prop("checked");
    let captcha = "";
    // console.log("form submit >>> ", fname);
    // console.log("form submit >>> PR_4_466_1_freetext ", PR_4_466_1_freetext);

    //check required fields are present
    if (fname.trim() === "" 
        || lname.trim() === "" 
        || email.trim() === "" 
        || city.trim() === "" 
        || state.trim() === "" 
        || phone.trim() === "" 
        || zip.trim() === "" 
        || address1.trim() === ""
        || dob.trim() === "" 
        || tc === false || rules === false) {

      if (fname.trim() === "") {
        $("#CP_FirstName").addClass("error");
      }

      if (lname.trim() === "") {
        $("#CP_LastName").addClass("error");
      }

      if (email.trim() === "") {
        $("#CP_EmailId").addClass("error");
      }
      if (city.trim() === "") {
        $("#CP_City").addClass("error");
      }
      if (phone.trim() === "") {
        $("#CP_PhoneNumber").addClass("error");
      }
      if (state.trim() === "") {
        $("#PR_4_466_1_freetext").addClass("error");
      }
      if (zip.trim() === "") {
        $("#CP_ZipCode").addClass("error");
      }
      if (address1.trim() === "") {
        $("#CP_Address1").addClass("error");
      }

      if (dob.trim() === "") {
        $("#CP_DOB").addClass("error");
      }
      formError = true
    }
    //check age
    ageError = checkAge(dob)
    // console.log('formError >>>> ', formError)
    //Converts formdata to json
    const send = {};
    const data = new FormData(document.getElementById('formNflClaimPrize'));
    window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
   
   
      data.forEach((value, key) => {
        if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
            // eslint-disable-next-line radix
            send[key] = parseInt(value);
        } else if (key === "PR_4_92_1" || key === "tc") {
          if (value === "on") {
              send[key] = "1";
          }
        } else if (key === "g-recaptcha-response") {
          send['captcha'] = token
          send[key] = token
        } else if (key === "CP_DOB") {
          send[key] = moment($("#CP_DOB").val()).format("YYYY-MM-DD")
        } else if (key === "tc") {
          send[key] = value === 1 ? true : false
        } else if (key === "plusone") {
          send[key] = value === "true" || value === true ? true : false
        } else {
          send[key] = value;
        }
      });

      // send to api
      send["captcha"] = token
      const json = JSON.stringify(send);
      captcha = send["g-recaptcha-response"];
      if(!formError && !ageError && captcha !== "") {
        try {
          const res = await fetch(`${apiUrl}`, {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());

          // console.log('RES >>>> ', res)
          if(res && res.success) {
             window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event':'pageView',
              'pagePath': window.location.href +'/thank-you'
              });
                window.dataLayer.push({
                  "event": "sign_up",
                  "step":"success",
                  "sign_up_type": "competition",
                  "optin_brands": "Captain Morgan",
                  "form_name":"Follow The Captain Winner Form",
                  "form_location":'modal',
                  "detail": checkboxFields? "newsletter opt-in" : "newsletter opt-out"
              }
              )
            if(res.data.reward) {
            $('#claimForm').addClass('hidden')
            $('#claimThanks').removeClass('hidden')
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
            $('#prize').text(res.data.reward.name)
            }
            else {
            $('#claimForm').addClass('hidden')
            $('#claimLose').removeClass('hidden')
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')    
            }
          
          }else{
            $('#errorMessage').html('<div>Something went wrong. Please try again.</div>')
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          if($('#cidbSubmit')) {
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          }
          return 0;
        }
      }else{
        // console.log('form errors')
        let errorMsg = "";
        // console.log('RQ FIELD >> fname ', fname)
        // console.log('RQ FIELD >> lname ', lname)
        // console.log('RQ FIELD >> email ', email)
        // console.log('RQ FIELD >> dob ', dob)
        if (fname.trim() === ""
          || lname.trim() === ""
          || email.trim() === ""
          || city.trim() === ""
          || state.trim() === ""
          || phone.trim() === ""
          || zip.trim() === ""
          || address1.trim() === ""
          || dob.trim() === "") {
          errorMsg = "<div>A required field is missing.</div>";
        }

        if(ageError) {
          errorMsg = "<div>You must be 21 and over to enter.</div>";
        }

        if (tc === false) {
          errorMsg += "<div>Please accept the terms and conditions and Privacy & Cookie notice.</div>";
        }
        if (rules === false) {
          errorMsg += "<div>Please accept the official rules.</div>";
        }

        if (captcha === "") {
          errorMsg += "<div>Please answer the Captcha.</div>";
        }
        $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
        $('#errorMessage').html(errorMsg)
        errorMessage = errorMsg
        formError = false
      }
    })
  }

  function shareHandler(event) {
    event.preventDefault()

    let newTab
    switch (event.currentTarget.dataset.platform) {
      case 'facebook':
        newTab = window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location}`, '_blank')
        newTab && newTab.focus()
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event':'socialEvent',
          'socialNetwork' : 'Facebook',
          'socialAction' : 'Share',
          'socialUrl': 'https://www.facebook.com/sharer/sharer.php?u=' + window.location
        });
        break

      case 'twitter':
        newTab = window.open(`https://twitter.com/intent/tweet?text=Follow The Captain  https://www.captainmorgan.com/followthecaptain`, '_blank')
        newTab && newTab.focus()
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event':'socialEvent',
          'socialNetwork' : 'Twitter',
          'socialAction' : 'Share',
          'socialUrl': `https://www.captainmorgan.com/followthecaptain`
        });
        break
    }
  }

  const handleDateChange = (e) => {
		let value = e.target.value;
		let backsapce = date.length > value.length;

		if (!backsapce && (date.length == 1 || date.length == 4)) {
			value += "/";
		}
		setDate(value);
	};

  

  useEffect(() => {
  
    $(function () {
      $('#CP_PhoneNumber').on('keydown keyup', function(e) {
        if(isNaN(e.key)){
          if(e.key.toString().toLowerCase() !== 'backspace' && e.key.toString().toLowerCase() !== 'tab') {
            e.preventDefault()
          }
        }else{
        }
      })
    })
    $("#CP_DOB").on("keydown keyup", function (e) {
				if (isNaN(e.key)) {
					if (
						(e.key).toLowerCase() !== "backspace" &&
						(e.key).toLowerCase() !== "tab"
					) {
						e.preventDefault();
					}
				} else {
				}
			});
    $("body").on("click", "#age_confirm_btn", function () {

			const month = document.getElementById(
				"age_select_month_of_birth"
			) 
			const day = document.getElementById(
				"age_select_day_of_birth"
			) 
			const year = document.getElementById(
				"age_select_year_of_birth"
			) 

			const birthday = month.value + "/" + day.value + "/" + year.value;
        setDate(birthday)
			// $("#CP_DOB").val(birthday);
		});


  })
  // useEffect(() => {
  //   console.log("modaltypechange")
  //   // window.grecaptcha.reset();
  //   if(document.getElementById("recaptcha-claim")) {
  //     console.log("{load the freaking captcha}")
  //     console.log(document.getElementById("recaptcha-claim"))
  //     load("6Lf5-30cAAAAAAhYAb4XHcLZFh-8sj_OHetPhcvt", {
  //             explicitRenderParameters: {
  //                 container: "recaptcha-claim",
  //             },
  //         });
  //   }
   
  // },[modalType])

  return (
    <div className={cn('modalcontent', `modalcontent--${modalType}`, `${modalType}`)}>
      {modalType === 'hint' &&
        <div className="modalcontent__wrap">
          <div className="hint__title">
            Hint:
          </div>    
          <div className="hint__copy">
            <span className="font__red">Go for it</span> and call the number.
          </div>
          {/*
          <div className="hint__image">
            <img src={hintImage} alt="Go for it and call the number" />
          </div>*/}
          {/*<div className="hint__video">
            <iframe src="https://www.youtube.com/embed/NDQoOdu8VyA?si=WPSw4bOSpDo84CGn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>*/}
        </div>
      }
      {modalType === 'incorrect' &&
        <div className="modalcontent__wrap">
          <div className="incorrect__title">
            incorrect answer
          </div>
          <div className="incorrect__cta">
            <button className="btn btn--black btn--bgwhite btn--textred btn--decorated" onClick={() => props.closemodal()}>
              Try again
            </button>
          </div>
        </div>
      }
      {(modalType === 'gallery' && galleryType !== '') &&
        <ContentGallery pagetype={galleryType}/>
      }
      {modalType === 'correct' && apiUrl !== '' &&  successPrize !== '' &&
        <div className="modalcontent__wrap rsvpForm hidden">
          <div className="correct__form modalform formWrapper hidden" id="claimThanks">
            <div className="correct__heading">
              <div className="correct__title">
                congrats! You've won
              </div>
              <div className="image__title" >
                  A <span id="prize">Signed NFL Jersey</span>
                </div>
            </div>
            <div className="correct__subcopy">
              <p>Check your email for more details.</p>
            </div>
            <div className="correct__social modalsocial">
              <button className="btn btn__social btn__social-fb" data-platform="facebook" onClick={(event) => shareHandler(event)}>
                Facebook  
              </button>
              <button className="btn btn__social btn__social-tw" data-platform="twitter" onClick={(event) => shareHandler(event)}>
                Twitter  
              </button>
            </div>
          </div>
          <div className="correct__form modalform formWrapper hidden" id="claimLose">
            <div className="correct__formtitle">
             Sorry, not a winner this time.
            </div>
            <div className="correct__cta">
              <button className="btn btn--black btn--bgwhite btn--textred btn--decorated" onClick={() => props.closemodal()}>
                Try again
              </button>
            </div>
          </div>
          <div className="correct__form modalform formWrapper " id="claimForm">
            <div className="correct__formtitle">
              You correctly guessed the password! <br/> Enter your information for a chance to win a prize.
            </div>
            <form id="formNflClaimPrize" runat="server" action="/api/CIDBapi_Form">
              <div className="inputWrapper half">
                <label htmlFor="CP_FirstName">FIRST NAME*</label>
                <input id="CP_FirstName" name="CP_FirstName" type="text" className="claimInput" required />
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_LastName">LAST NAME*</label>
                <input id="CP_LastName" name="CP_LastName" type="text" className="claimInput" required />
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_EmailId">EMAIL*</label>
                <input id="CP_EmailId" name="CP_EmailId" type="email" className="claimInput" required />
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_DOB">DATE OF BIRTH*</label>
                <input id="CP_DOB" name="CP_DOB" type="text" className="claimInput" required  value={date}
											maxLength={10}
											onChange={handleDateChange}
											placeholder="mm/dd/yyyy"/>
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_Address1">ADDRESS *</label>
                <input id="CP_Address1" name="CP_Address1" className="claimInput" type="text" required />
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_Address2">ADDRESS 2</label>
                <input id="CP_Address2" name="CP_Address2" className="claimInput" type="text" />
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_City">CITY *</label>
                <input id="CP_City" name="CP_City" className="claimInput" type="text" required />
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_ZipCode">ZIP CODE*</label>
                <input id="CP_ZipCode" name="CP_ZipCode" type="text" maxLength={10} className="claimInput" required />
              </div>

              <div className="inputWrapper half">
                <label htmlFor="PR_4_466_1_freetext">STATE*</label>
                <select name="PR_4_466_1_freetext" id="PR_4_466_1_freetext" className="claimInput input w-input" defaultValue="" required>
                    <option disabled value="">State*</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                </select>
              </div>

              <div className="inputWrapper half">
                <label htmlFor="CP_PhoneNumber">PHONE NUMBER *</label>
                <input id="CP_PhoneNumber" name="CP_PhoneNumber" maxLength={10} className="claimInput" type="tel" required />
              </div>

              <div className="inputWrapper checkbox">
                <input id="tc" name="tc" type="checkbox" className="claimInput cliamInput--checkbox" required />
                <label htmlFor="tc" dangerouslySetInnerHTML={{
                  __html: `I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank' style='text-decoration:underline;'">Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' style='text-decoration:underline;' target='_blank'>Privacy and Cookie Notice</a>. *`
                  }}
                />
              </div>

               <div className="inputWrapper checkbox">
                <input id="rules" name="rules" type="checkbox" className="claimInput cliamInput--checkbox" required />
                <label htmlFor="rules" dangerouslySetInnerHTML={{
                  __html: `I accept the <a href='/captain-morgan-nfl-rules.pdf' target='_blank' style='text-decoration:underline;'">Official Rules</a> for this Sweepstakes.*`
                  }}
                />
              </div>
              
              <div className="inputWrapper checkbox">
                <input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" className="claimInput cliamInput--checkbox" required />
                <label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                  __html: `Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time`
                  }}
                />
              </div>

                <div className="inputWrapper">
                    <div id="recaptcha-claim"></div>
                </div>

                <div className="inputWrapper">
                    <div className="errorMessage" id="errorMessage"></div>
                </div>

              <div className="inputWrapper">
                <input id="PR_4_92_1" name="PR_4_92_1" runat="server" type="hidden" value='locationval' />

                <input className="submit btn btn--decorated btn--bgwhite btn--textred" type="submit" value="SUBMIT" id='cidbSubmit' onClick={(event) => formSubmit(event)} />
              </div>
             
            </form>
          </div>
        </div>
      }
    </div>
  );
};

export default ModalNfl;
