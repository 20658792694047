/* eslint-disable require-jsdoc */
/* eslint-disable func-style */
/* eslint-disable max-lines */
/* eslint-disable file */
/* eslint-disable max-lines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-inline-comments */
/* eslint-disable no-negated-condition */
/* eslint-disable consistent-this */
/* eslint-disable no-shadow */
/* eslint-disable sort-keys */
/* eslint-disable id-length */
/* eslint-disable multiline-ternary */
/* eslint-disable sort-imports */
/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import React from "react";
import $ from "jquery";

import Newsletter from "../Newsletter";
import "./style.scss";

export default class NewsletterModal extends React.Component {

    getParameterByName = (name) => {
        const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }

    isPreventShowExpired = () => {
        var today = new Date()
        var storageDate = new Date(localStorage["newsletterModal"])
        return (today.getTime() - storageDate.getTime()) / (1000 * 3600 * 24) > 30
    }

    openModal = () => {
        const wndw = window;
        wndw.dataLayer = wndw.dataLayer || [];
        // wndw.dataLayer.push({
        //     event: "customEvent",
        //     eventCategory: "Opt-in",
        //     eventAction: "pop-up",
        //     eventLabel: "shown",
        //     eventInteraction: "false",
        // });
        wndw.dataLayer.push({
            event: 'sign_up_step',
            step: 'form shown',
            sign_up_type: 'newsletter',
            form_location:'modal',
            form_name: 'Newsletter Sign Up Modal',
        })
        $("#newsletterModal").addClass("active");
        localStorage.newsletterModal = new Date();
    }

    executeModal = () => {
        setTimeout(this.openModal, 7000)
    }


    componentDidMount() {
        const noModal = this.getParameterByName("nomodal");
        const search = this.getParameterByName("newsletter")
        const visited = localStorage["newsletterModal"] || false

        if(!this.location?.pathname.includes("/en-us/newsletter") && !noModal){
            if (search) {
                window.addEventListener('ageGatePass', this.openModal)
                this.openModal()
            } else if ((visited && this.isPreventShowExpired()) || !visited) {
                window.addEventListener('ageGatePass', this.executeModal)
            }
        }
        $(".modalClose").click(function () {
            $("#newsletterModal").removeClass("active")
        })

    }

    componentWillUnmount() {
        window.removeEventListener('ageGatePass', this.executeModal)
        window.removeEventListener('ageGatePass', this.openModal)
    }

   render () {

      return (

          <div id="newsletterModal" className="newsletterModal">
              <div className="modalOverlay modalClose"></div>

              <div className="modalContent">
                  <button className="modalClose">&#10006;</button>
                  <div className="scroll">
                      <div className="image-container">
                          <img src="https://media.captainmorgan.com/media/1543/red-logo.png" className="logo" alt="Captain Morgan Logo" />
                      </div>

                      <Newsletter modal />

                  </div>
              </div>
          </div>
      );

  }

}
