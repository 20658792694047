import "./market-switcher.scss";
import {BlockingLink} from "global/utils/blocking-link";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class MarketSwitcher extends React.Component {

    render () {

        if (!this.props) {

            return false;

        }
        if(this.props.fields.length < 1) {
          return false
        }
        return (
            <nav className="market-switcher">
                <div className="market-switcher__inner">
                    <ul className="market-switcher__options">
                        {this.props.fields.map((navNode, index) => {

                            const {
                                Title,
                                Url,
                                Target,
                            } = fieldsArrayToKeyValueHash(navNode.fields);

                            return (
                                <li
                                    key={`nav-node-${index}`}
                                    className="market-switcher__options-item"
                                >
                                    <BlockingLink
                                        target={Target}
                                        to={Url}
                                        className={"market-switcher__options-link"}
                                    >
                                        {Title}
                                    </BlockingLink>
                                </li>
                            );

                        })}
                    </ul>
                </div>
            </nav>
        );

    }

}
