import './content-drinksworks.scss'
import React from 'react'

import * as utils from 'global/utils/utils'

import imageMojito from 'global/images/drinksworks/mojito.png'
import imageTikiLemonade from 'global/images/drinksworks/captaintiki.png'
import imageAppleLemonade from 'global/images/drinksworks/applelemonade.png'
import imageCaptainExotic from 'global/images/drinksworks/Pineapple.png'
import imageCocaCola from 'global/images/drinksworks/cocacola.png'
import imageLemona from 'global/images/drinksworks/lemona.png'

const DWContentRecipe = (props) => {
  const pageLocale = props && props.pagelocale ? props.pagelocale : ''
  const pageRecipe = props && props.recipe ? props.recipe : ''
  const contentData = getData(pageLocale)

  function goToNextPage(e, item) {
    const { target } = e

    let label

    let answer

    if (target && target.dataset && target.dataset.title) {
      answer = target.dataset.title
    }
    if (target && target.dataset && target.dataset.title) {
      label = target.dataset.question
    }
    const ga = {
      event: 'in_page_button',
      click_text: label,
      detail: answer
    }

    utils.gaSubmit(ga)
    props.nextpagecallback(item)
  }
  function getData(pageLocale) {
    const data = [
      {
        locale: 'en-us',
        data: [
          {
            recipe: 'mojito',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">You like a Classic?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">Why not make an</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">easy mojito</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">our answer to a mojito</span>`,
            buttontext: 'Show me the step by step',
            image: imageMojito,
            imagealt: 'A glass of a Mojito'
          },
          {
            recipe: 'tikilemonade',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">Love tropical flavours?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">Look no further,</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">try a</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">Captain Tiki & Lemonade!</span>`,
            buttontext: 'Show me the step by step',
            image: imageTikiLemonade,
            imagealt: 'A glass of Captain Tiki & Lemonade'
          },
          {
            recipe: 'applelemonade',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--small">Love new things?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">What are you waiting for,</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">Try a captain</span>
            <span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--large">Sliced apple & Lemonade</span>`,
            buttontext: 'Show me the step by step',
            image: imageAppleLemonade,
            imagealt: 'A glass of Captain Sliced Apple & Lemonade'
          },
          {
            recipe: 'captainexotic',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">You love pineapple things?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">Why not</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">Try a</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">Captain Exotic?</span>`,
            buttontext: 'Show me the step by step',
            image: imageCaptainExotic,
            imagealt: 'A glass of a Captain Exotic'
          }
        ]
      },
      {
        locale: 'de-de',
        data: [
          {
            recipe: 'de_recipe_1',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">DU MAGST DIE KLASSIKER?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">WARUM MACHEN WIR NICHT EINEN</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">EINFACHEN CAPTAIN & COLA</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">#MACH DEIN DING</span>`,
            buttontext: 'ZEIG MIR DEN SCHRITT FÜR SCHRITT ANLEITUNG',
            image: imageCocaCola,
            imagealt: 'A glass of a CAPTAIN & COLA',
            recipename: 'Captain & Cola'
          },
          {
            recipe: 'de_recipe_2',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">DU LIEBST TROPISCHE AROMEN?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">SUCHE NICHT WEITER,</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">PROBIERE EINEN</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">CAPTAIN TIKI & LIMO</span>`,
            buttontext: 'ZEIG MIR DEN SCHRITT FÜR SCHRITT ANLEITUNG',
            image: imageTikiLemonade,
            imagealt: 'A glass of Captain Tiki & Lemonade',
            recipename: 'Tiki & Lemonade'
          },
          {
            recipe: 'de_recipe_3',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--small">DU LIEBST NEUE DINGE?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">WORAUF WARTEST DU NOCH?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">PROBIERE EINEN</span>
            <span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--large">CAPTAIN & LIMO</span>`,
            buttontext: 'ZEIG MIR DEN SCHRITT FÜR SCHRITT ANLEITUNG',
            image: imageLemona,
            imagealt: 'A glass of Captain & Limo',
            recipename: 'Captain & Limo'
          },
          {
            recipe: 'de_recipe_4',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">DU MAGST DIE KLASSIKER?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">WARUM NICHT MAL ALKOHOLFREI</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">EINFACHEN CAPTAIN 0.0% & COLA</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">#MACH DEIN DING</span>`,
            buttontext: 'ZEIG MIR DEN SCHRITT FÜR SCHRITT ANLEITUNG',
            image: imageCocaCola,
            imagealt: 'A glass of a CAPTAIN 0.0% & COLA',
            recipename: 'Captain 0.0% & Cola'
          }
        ]
      }
    ]

    if (pageLocale) {
      const contentData = data.filter((x) => x.locale === pageLocale)[0]
      const contentItems = contentData.data.filter((x) => x.recipe === pageRecipe)

      if (contentItems) {
        return contentItems[0]
      }

      return null
    }

    return null
  }

  return (
    <div className="dwcontent">
      <h1
        className="dwcontent__title dwtitle"
        dangerouslySetInnerHTML={{
          __html: `${contentData.headertitle}
        `
        }}
      />
      <div className="dwcontent__recipeimage">
        <img src={contentData.image} alt={contentData.imagealt} />
      </div>
      <button
        className="btn btn--goldbggradient"
        data-question={contentData.buttontext}
        data-title={contentData.recipename}
        onClick={(e) => goToNextPage(e, pageRecipe)}
      >
        {contentData.buttontext}
      </button>
    </div>
  )
}

export default DWContentRecipe
