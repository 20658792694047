import "./content-drinksworks.scss";
import React, {useEffect} from "react";

import * as utils from 'global/utils/utils'

const DWContentSteps = (props) => {
  const pageLocale = props && props.pagelocale ? props.pagelocale : ""
  const pageRecipe = props && props.recipe ? props.recipe : ""
  const contentData = getData(pageLocale)
  
  function goToNextPage () {
    props.resetcallback()
  }
  function getData (pageLocale) {
    const data = [
      {
        locale: 'en-us',
        data: [
          {
            recipe: 'mojito',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">You like a Classic?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">Why not make an</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">easy mojito</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">our answer to a mojito</span>`,
            buttontext: 'Go to homepage',
            steps: [
              'CHOOSE A GLASS, ANY GLASS WILL DO, …',
              'GET SOME ICE IN THAT GLASS',
              'ADD NICE TO YOUR ICE WITH CAPTAIN MORGAN ORIGINAL SPICED GOLD',
              'CHOOSE YOUR FAVOURITE LEMONADE',
              'PUSH THE BUTTONS ON THE DOOR OF TASTY FINISHING TOUCHES AND GET SOME MINT & LIME, AND HEY… WHY NOT FINISHING WITH ANOTHER CRAZY EXTRA?',
              'SHARE YOUR SPICE WITH THE TASTIEST SELFIE EVER',
              'CHILL, ENJOY AND PARTY!'
            ]
          },
          {
            recipe: 'tikilemonade',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">Love tropical flavours?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">Look no further,</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">try a</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">Captain Tiki & Lemonade!</span>`,
            buttontext: 'Go to homepage',
            steps: [
              'CHOOSE A GLASS, ANY GLASS WILL DO, …',
              'GET SOME ICE IN THAT GLASS',
              'ADD NICE TO YOUR ICE WITH CAPTAIN MORGAN TIKI',
              'CHOOSE YOUR FAVOURITE LEMONADE',
              'PUSH THE BUTTONS ON THE DOOR OF TASTY FINISHING TOUCHES AND GET SOME LEMON, AND HEY… WHY NOT FINISHING WITH ANOTHER CRAZY EXTRA?',
              'SHARE YOUR SPICE WITH THE TASTIEST SELFIE EVER',
              'CHILL, ENJOY AND PARTY!',
            ]
          },
          {
            recipe: 'applelemonade',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--small">Love new things?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">What are you waiting for,</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">Try a captain</span>
            <span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--large">Sliced apple & Lemonade</span>`,
            buttontext: 'Go to homepage',
            steps: [
              'CHOOSE A GLASS, ANY GLASS WILL DO, …',
              'GET SOME ICE IN THAT GLASS',
              'ADD NICE TO YOUR ICE WITH CAPTAIN MORGAN SLICED APPLE',
              'CHOOSE YOUR FAVOURITE LEMONADE',
              'PUSH THE BUTTONS ON THE DOOR OF TASTY FINISHING TOUCHES AND GET SOME LIME, AND HEY… WHY NOT FINISHING WITH ANOTHER CRAZY EXTRA?',
              'SHARE YOUR SPICE WITH THE TASTIEST SELFIE EVER',
              'CHILL, ENJOY AND PARTY!',
            ]
          },
          {
            recipe: 'captainexotic',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">You love pineapple things?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">Why not</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">Try a</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">Captain Exotic?</span>`,
            buttontext: 'Go to homepage',
            steps: [
              'CHOOSE A GLASS, ANY GLASS WILL DO, …',
              'GET SOME ICE IN THAT GLASS',
              'ADD NICE TO YOUR ICE WITH CAPTAIN MORGAN WHITE RUM',
              'GET SOME TROPICAL JUICE',
              'PUSH THE BUTTONS ON THE DOOR OF TASTY FINISHING TOUCHES AND GET SOME PINEAPPLE & LIME, AND HEY… WHY NOT FINISHING WITH ANOTHER CRAZY EXTRA?',
              'SHARE YOUR SPICE WITH THE TASTIEST SELFIE EVER',
              'CHILL, ENJOY AND PARTY!',
            ]
          }
        ]
      },
      {
        locale: 'de-de',
        data: [
          {
            recipe: 'de_recipe_1',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">DU MAGST DIE KLASSIKER?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">WARUM MACHEN WIR NICHT EINEN</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">EINFACHEN CAPTAIN & COLA</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">#MACH DEIN DING</span>`,
            buttontext: 'ZURÜCK ZUR STARTSEITE',
            steps: [
              'WÄHLE EIN GLAS, EGAL WELCHES',
              'FÜLLE EIS INS GLAS',
              'MACH DEINEN DRINK MIT CAPTAIN MORGAN ORIGINAL SPICED GOLD ',
              'WÄHLE COLA',
              'DRÜCKE DIE KNÖPFE AN DER LETZTEN STATION UM DEM DRINK DEN LETZTEN SCHLIFF ZU GEBEN UND HOL DIR EINE LIMETTEN SCHEIBE - UND HEY... WARUM NICHT EIN WEITERES VERRÜCKTES EXTRA TOPPING?',
              'TEILE DEINEN MIX MIT EINEM COOLEN SELFIE',
              'MACH DEIN DING, SPICE ON!',
            ],
            recipename: 'Captain & Cola'
          },
          {
            recipe: 'de_recipe_2',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--small">DU LIEBST TROPISCHE AROMEN?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">SUCHE NICHT WEITER,</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">PROBIERE EINEN</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">CAPTAIN TIKI & LIMO</span>`,
            buttontext: 'ZURÜCK ZUR STARTSEITE',
            steps: [
              'WÄHLE EIN GLAS, EGAL WELCHES',
              'FÜLLE EIS INS GLAS',
              'MACH DEINEN DRINK MIT CAPTAIN MORGAN TIKI',
              'WÄHLE DEINE LIEBLINGSLIMONADE',
              'DRÜCKE DIE KNÖPFE AN DER LETZTEN STATION UM DEM DRINK DEN LETZTEN SCHLIFF ZU GEBEN UND HOL DIR EINE SCHEIBE ZITRONE - UND HEY... WARUM NICHT EIN WEITERES VERRÜCKTES EXTRA TOPPING?',
              'TEILE DEINEN MIX MIT EINEM COOLEN SELFIE',
              'MACH DEIN DING, SPICE ON!',
            ],
            recipename: 'Tiki & Lemonade'
          },
          {
            recipe: 'de_recipe_3',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--small">DU LIEBST NEUE DINGE?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">WORAUF WARTEST DU NOCH?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--small">PROBIERE EINEN</span>
            <span class="text--white dwtitle--span dwtitle--span-mr30 dwtitle--large">CAPTAIN & LIMO</span>`,
            buttontext: 'ZURÜCK ZUR STARTSEITE',
            steps: [
              'WÄHLE EIN GLAS, EGAL WELCHES',
              'FÜLLE EIS INS GLAS',
              'MACH DEINEN DRINK MIT CAPTAIN MORGAN ORIGINAL SPICED GOLD ',
              'WÄHLE DEINE LIEBLINGSLIMONADE',
              'DRÜCKE DIE KNÖPFE AN DER LETZTEN STATION UM DEM DRINK DEN LETZTEN SCHLIFF ZU GEBEN UND HOL DIR EINE SCHEIBE ZITRONE - UND HEY... WARUM NICHT EIN WEITERES VERRÜCKTES EXTRA TOPPING?',
              'TEILE DEINEN MIX MIT EINEM COOLEN SELFIE',
              'MACH DEIN DING, SPICE ON!',
            ],
            recipename: 'Captain & Limo'
          },
          {
            recipe: 'de_recipe_4',
            headertitle: `<span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">DU MAGST DIE KLASSIKER?</span>
            <span class="text--white dwtitle--span dwtitle--span-mr40 dwtitle--large">WARUM NICHT MAL ALKOHOLFREI</span>
            <span class="text--white dwtitle--span dwtitle--span-mr10 dwtitle--small">EINFACHEN CAPTAIN & COLA 0.0%</span>
            <span class="text--white dwtitle--span dwtitle--span-mr20 dwtitle--large">#MACH DEIN DING</span>`,
            buttontext: 'ZURÜCK ZUR STARTSEITE',
            steps: [
              'WÄHLE EIN GLAS, EGAL WELCHES',
              'FÜLLE EIS INS GLAS',
              'MACH DEINEN DRINK MIT UNSEREM NEUEN CAPTAIN MORGAN 0.0%',
              'FÜGE COLA HINZU',
              'DRÜCKE DIE KNÖPFE AN DER LETZTEN STATION UM DEM DRINK DEN LETZTEN SCHLIFF ZU GEBEN UND HOL DIR EINE SCHEIBE LIMETTE - UND HEY... WARUM NICHT EIN WEITERES VERRÜCKTES EXTRA TOPPING?',
              'TEILE DEINEN MIX MIT EINEM COOLEN SELFIE',
              'MACH DEIN DING, SPICE ON!',
            ],
            recipename: 'Captain 0.0% & Cola'
          }
        ]
      }
    ]

    if(pageLocale) {
      const contentData = data.filter(x => x.locale === pageLocale)[0]
      const contentItems = contentData.data.filter(x => x.recipe === pageRecipe)
      if(contentItems) {
        return contentItems[0]
      }else{
        return null
      }
    }else{
      return null
    }
  }

  function gaOnLoad () {
    const label = contentData.recipename
    const ga = {
      'event':'form',
      'form_name': `Drinksworks_${pageLocale}`,  
      'form_field': 'steps',
      'detail': label
    }
    utils.gaSubmit(ga)
  }

  useEffect(() => {
    window.addEventListener("load", gaOnLoad());

    return () =>{
      window.removeEventListener("load", gaOnLoad());
    } 
    
  })
  return (
    <div className="dwcontent">
      <h1 className="dwcontent__title dwtitle" dangerouslySetInnerHTML={{
        __html: `${contentData.headertitle}
        `
        }}
      />
      {contentData && contentData.steps && contentData.steps.length > 0 &&
        <div className="dwcontent__recipesteps">
          <ol>
            { contentData.steps.map((step, key) => {
                return (
                  <li key={`recipe_${pageRecipe}_step_${key}`}>
                    <span>{key + 1}.</span>{step}
                  </li>
                )
              })
            }
          </ol>
        </div>
      }
      
      <button className="btn btn--goldbggradient" onClick={() => goToNextPage()}>
        {contentData.buttontext}
      </button>
    </div>
  );
};

export default DWContentSteps;
