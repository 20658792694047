import "./product-story.scss";
import Lazy from "global/utils/lazy";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

const ProductStory = (props) => {

    const TastingBlock = props.fields.find((field) => field.title === "TastingNotes");
    const StoryBlock = props.fields.find((field) => field.title === "Story");

    if (!TastingBlock && !StoryBlock) {

        return false;

    }

    const {
        TastingNotesTitle,
        TastingNotes,
        TastingNotesImage,
    } = fieldsArrayToKeyValueHash(TastingBlock.fields);

    const {
        StoryTitle,
        Story,
        StoryImage,
    } = fieldsArrayToKeyValueHash(StoryBlock.fields);

    const storyImage = fieldsArrayToKeyValueHash(StoryImage),
        tastingNotesImage = fieldsArrayToKeyValueHash(TastingNotesImage);

    const storyImageStyle = {backgroundImage: `url(${storyImage.Url})`},
        tastingNotesStyle = {backgroundImage: `url(${tastingNotesImage.Url})`};

    return (

        <section className="product-story">
            <div className="product-story__inner">
                {
                    TastingBlock && TastingNotesTitle &&
                        <div className="product-story__item product-story__item--tasting-notes">
                            <div className="product-story__content">
                                <h2 className="product-story__heading">
                                    {TastingNotesTitle}
                                </h2>
                                {
                                    TastingNotes &&
                                        <div
                                            className="product-story__copy"
                                            dangerouslySetInnerHTML={{__html: sanitise(TastingNotes)}}
                                        />
                                }
                            </div>
                            {
                                TastingNotesImage &&
                                    <Lazy
                                        Tag="div"
                                        containerClassName="product-story__visual"
                                    >
                                        <div
                                            className="product-story__image"
                                            style={tastingNotesStyle}
                                        />
                                    </Lazy>
                            }
                        </div>
                }
                {
                    StoryBlock && StoryTitle &&
                        <div className="product-story__item product-story__item--story">
                            <div className="product-story__content">
                                <h2 className="product-story__heading">{StoryTitle}</h2>
                                {
                                    Story &&
                                        <div
                                            className="product-story__copy"
                                            dangerouslySetInnerHTML={{__html: sanitise(Story)}}
                                        />
                                }
                            </div>
                            {
                                StoryImage &&
                                    <Lazy
                                        Tag="div"
                                        containerClassName="product-story__visual"
                                    >
                                        <div
                                            className="product-story__image"
                                            style={storyImageStyle}
                                        />
                                    </Lazy>
                            }
                        </div>
                }
            </div>
        </section>
    );

};

export default ProductStory;
